._hasBorderBottom_1ycflw8_14{
  border-bottom:1px solid;
}
._main_1ycflw8_18{
  position:relative;
}
._main_1ycflw8_18._themeLight_1ycflw8_21{
    border-color:rgba(25,25,28,0.2);

    color:#19191C;

    color:var(--rs-typography-color-hard, #19191C);
  }
._main_1ycflw8_18._themeDark_1ycflw8_27{
    border-color:rgba(255,255,255,0.2);

    color:#FFFFFF;

    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._opening_1ycflw8_33,
  ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._opened_1ycflw8_34,
  ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._closing_1ycflw8_35{
    padding-bottom:1px;

    border-bottom:none;
  }
@media (hover: hover){
    ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33:hover{
      padding-bottom:1px;

      border-bottom:none;
    }
  }
._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._opening_1ycflw8_33,
    ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._opened_1ycflw8_34,
    ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._closing_1ycflw8_35{
      background:rgba(25,25,28,0.05);
    }
@media (hover: hover){
      ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49:hover{
        background:rgba(25,25,28,0.05);
      }
    }
._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._opening_1ycflw8_33,
    ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._opened_1ycflw8_34,
    ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._closing_1ycflw8_35{
      background:rgba(255,255,255,0.05);
    }
@media (hover: hover){
      ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49:hover{
        background:rgba(255,255,255,0.05);
      }
    }
._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._opening_1ycflw8_33,
    ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._opened_1ycflw8_34,
    ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._closing_1ycflw8_35{
      --rs-typography-color-hard:#FFFFFF;
      --rs-typography-color-average:rgba(255,255,255,0.7);
      --rs-typography-color-pale:rgba(255,255,255,0.5);
      --rs-typography-pre-color:rgba(255,255,255,0.7);
      --rs-typography-pre-bg-color:rgba(255,255,255,0.1);
      --rs-typography-classic-link-underline-color:rgba(255,255,255,0.4);
      --rs-typography-rock-link-hover-color:#19191C;
      background:#19191C;
    }
@media (hover: hover){
      ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33:hover{
        --rs-typography-color-hard:#FFFFFF;
        --rs-typography-color-average:rgba(255,255,255,0.7);
        --rs-typography-color-pale:rgba(255,255,255,0.5);
        --rs-typography-pre-color:rgba(255,255,255,0.7);
        --rs-typography-pre-bg-color:rgba(255,255,255,0.1);
        --rs-typography-classic-link-underline-color:rgba(255,255,255,0.4);
        --rs-typography-rock-link-hover-color:#19191C;
        background:#19191C;
      }
    }
._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._opening_1ycflw8_33,
    ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._opened_1ycflw8_34,
    ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._closing_1ycflw8_35{
      --rs-typography-color-hard:#19191C;
      --rs-typography-color-average:rgba(25,25,28,0.7);
      --rs-typography-color-pale:rgba(25,25,28,0.5);
      --rs-typography-pre-color:#19191C;
      --rs-typography-pre-bg-color:rgba(25,25,28,0.1);
      --rs-typography-classic-link-underline-color:rgba(25,25,28,0.4);
      --rs-typography-rock-link-hover-color:#FFFFFF;
      background:#FFFFFF;
    }
@media (hover: hover){
      ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33:hover{
        --rs-typography-color-hard:#19191C;
        --rs-typography-color-average:rgba(25,25,28,0.7);
        --rs-typography-color-pale:rgba(25,25,28,0.5);
        --rs-typography-pre-color:#19191C;
        --rs-typography-pre-bg-color:rgba(25,25,28,0.1);
        --rs-typography-classic-link-underline-color:rgba(25,25,28,0.4);
        --rs-typography-rock-link-hover-color:#FFFFFF;
        background:#FFFFFF;
      }
    }
._title_1ycflw8_106{
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;

  color:inherit;
}
._sizeS_1ycflw8_111 > ._button_1ycflw8_111 > ._title_1ycflw8_106{
    letter-spacing:normal;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:13px;
    font-size:var(--rs-h5-font-size, 13px);
    font-weight:530;
    font-weight:var(--rs-font-weight-semi-bold, 530);
    line-height:20px;
    line-height:var(--rs-h5-line-height, 20px);
  }
._sizeM_1ycflw8_114 > ._button_1ycflw8_111 > ._title_1ycflw8_106{
    letter-spacing:normal;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:16px;
    font-size:var(--rs-h4-font-size, 16px);
    font-weight:530;
    font-weight:var(--rs-font-weight-semi-bold, 530);
    line-height:24px;
    line-height:var(--rs-h4-line-height, 24px);
  }
._sizeL_1ycflw8_117 > ._button_1ycflw8_111 > ._title_1ycflw8_106{
    letter-spacing:normal;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:20px;
    font-size:var(--rs-h3-font-size, 20px);
    font-weight:530;
    font-weight:var(--rs-font-weight-semi-bold, 530);
    line-height:28px;
    line-height:var(--rs-h3-line-height, 28px);
  }
._content_1ycflw8_122{
  will-change:height;

  height:0;

  color:inherit;
}
._closed_1ycflw8_129 > ._content_1ycflw8_122{
    display:none;
  }
._opened_1ycflw8_34 > ._content_1ycflw8_122{
    height:initial;
  }
._opening_1ycflw8_33 > ._content_1ycflw8_122,
  ._closingTransition_1ycflw8_138 > ._content_1ycflw8_122{
    overflow:hidden;

    -webkit-transition:height 300ms;

    transition:height 300ms;
  }
._sizeS_1ycflw8_111 > ._content_1ycflw8_122{
    letter-spacing:0.0045em;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:13px;
    font-size:var(--rs-text-3-font-size, 13px);
    line-height:20px;
    line-height:var(--rs-text-3-line-height, 20px);
  }
._rs-reset-letter-spacing_1ycflw8_1 ._sizeS_1ycflw8_111 > ._content_1ycflw8_122{
    letter-spacing:normal;
  }
._sizeM_1ycflw8_114 > ._content_1ycflw8_122{
    letter-spacing:0.0015em;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:16px;
    font-size:var(--rs-text-2-font-size, 16px);
    font-weight:400;
    line-height:24px;
    line-height:var(--rs-text-2-line-height, 24px);
  }
._rs-reset-letter-spacing_1ycflw8_1 ._sizeM_1ycflw8_114 > ._content_1ycflw8_122{
    letter-spacing:normal;
  }
._sizeL_1ycflw8_117 > ._content_1ycflw8_122{
    letter-spacing:normal;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:20px;
    font-size:var(--rs-text-1-font-size, 20px);
    font-weight:normal;
    line-height:28px;
    line-height:var(--rs-text-1-line-height, 28px);
  }
._sizeS_1ycflw8_111._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 8px 8px 36px;
  }
._sizeS_1ycflw8_111._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155,
  ._sizeS_1ycflw8_111._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 8px 8px 8px;
  }
._sizeM_1ycflw8_114._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 8px 8px 40px;
  }
._sizeM_1ycflw8_114._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155,
  ._sizeM_1ycflw8_114._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 8px 8px 8px;
  }
._sizeL_1ycflw8_117._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 16px 16px 60px;
  }
._sizeL_1ycflw8_117._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155,
  ._sizeL_1ycflw8_117._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155{
    padding:0 16px 16px 16px;
  }
._button_1ycflw8_111{
  position:relative;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;

  overflow:visible;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;

  width:100%;
  margin:0;

  border:none;

  cursor:pointer;
  text-align:left;

  color:inherit;

  background:transparent;

  font:inherit;
  line-height:normal;
  -webkit-font-smoothing:inherit;
  -moz-osx-font-smoothing:inherit;
  -webkit-appearance:none;
}
._button_1ycflw8_111::-moz-focus-inner{
    padding:0;

    border:0;
  }
._sizeS_1ycflw8_111 > ._button_1ycflw8_111{
    padding:6px 8px;
  }
._sizeM_1ycflw8_114 > ._button_1ycflw8_111{
    padding:8px 8px;
  }
._sizeL_1ycflw8_117 > ._button_1ycflw8_111{
    padding:12px 16px;
  }
._button_1ycflw8_111:focus{
    outline:none;
  }
._clear_1ycflw8_233 > ._button_1ycflw8_111{
    -webkit-transition:color 100ms;
    transition:color 100ms;
  }
._classic_1ycflw8_49 > ._button_1ycflw8_111,
  ._rock_1ycflw8_33 > ._button_1ycflw8_111{
    -webkit-transition:background-color 100ms;
    transition:background-color 100ms;
  }
._themeLight_1ycflw8_21 ._button_1ycflw8_111{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
._themeDark_1ycflw8_27 ._button_1ycflw8_111{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._themeLight_1ycflw8_21 > ._button_1ycflw8_111:focus[data-focus-method='key']{
    -webkit-box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 4px;
            box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 4px;
            box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 4px;
  }
._themeDark_1ycflw8_27 > ._button_1ycflw8_111:focus[data-focus-method='key']{
    -webkit-box-shadow:rgba(132, 115, 255,0.8) 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-t-dim-dark-theme, rgba(132, 115, 255,0.8)) 0 0 0 4px;
            box-shadow:rgba(132, 115, 255,0.8) 0 0 0 4px;
            box-shadow:var(--rs-color-primary-t-dim-dark-theme, rgba(132, 115, 255,0.8)) 0 0 0 4px;
  }
@media (hover: hover){
    ._themeLight_1ycflw8_21._classic_1ycflw8_49 > ._button_1ycflw8_111:hover{
      background:rgba(25,25,28,0.05);
    }
    ._themeDark_1ycflw8_27._classic_1ycflw8_49 > ._button_1ycflw8_111:hover{
      background:rgba(255,255,255,0.05);
    }
    ._themeLight_1ycflw8_21._rock_1ycflw8_33 > ._button_1ycflw8_111:hover{
      --rs-typography-color-hard:#FFFFFF;
      --rs-typography-color-average:rgba(255,255,255,0.7);
      --rs-typography-color-pale:rgba(255,255,255,0.5);
      --rs-typography-pre-color:rgba(255,255,255,0.7);
      --rs-typography-pre-bg-color:rgba(255,255,255,0.1);
      --rs-typography-classic-link-underline-color:rgba(255,255,255,0.4);
      --rs-typography-rock-link-hover-color:#19191C;
      background:rgba(255,255,255,0.1);
    }
    ._themeDark_1ycflw8_27._rock_1ycflw8_33 > ._button_1ycflw8_111:hover{
      --rs-typography-color-hard:#19191C;
      --rs-typography-color-average:rgba(25,25,28,0.7);
      --rs-typography-color-pale:rgba(25,25,28,0.5);
      --rs-typography-pre-color:#19191C;
      --rs-typography-pre-bg-color:rgba(25,25,28,0.1);
      --rs-typography-classic-link-underline-color:rgba(25,25,28,0.4);
      --rs-typography-rock-link-hover-color:#FFFFFF;
      background:rgba(25,25,28,0.1);
    }
    ._themeLight_1ycflw8_21._clear_1ycflw8_233 > ._button_1ycflw8_111:hover{
      color:rgba(25,25,28,0.7);
      color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
    }
    ._themeDark_1ycflw8_27._clear_1ycflw8_233 > ._button_1ycflw8_111:hover{
      color:rgba(255,255,255,0.7);
      color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
    }
  }
._icon_1ycflw8_281{
  position:relative;
  top:0;
  left:0;

  -webkit-box-flex:0;

      -ms-flex:0 0 auto;

          flex:0 0 auto;

  -webkit-transition:-webkit-transform 300ms;

  transition:-webkit-transform 300ms;

  transition:transform 300ms;

  transition:transform 300ms, -webkit-transform 300ms;

  will-change:transform;
  -webkit-transform-origin:center center;
          transform-origin:center center;
}
._sizeS_1ycflw8_111 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    width:20px;
    height:20px;
  }
._sizeM_1ycflw8_114 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    width:24px;
    height:24px;
  }
._sizeL_1ycflw8_117 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    width:28px;
    height:28px;
  }
._sizeS_1ycflw8_111._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-right:8px;
  }
._sizeS_1ycflw8_111._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-left:8px;
  }
._sizeM_1ycflw8_114._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-right:8px;
  }
._sizeM_1ycflw8_114._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-left:8px;
  }
._sizeL_1ycflw8_117._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-right:16px;
  }
._sizeL_1ycflw8_117._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    margin-left:16px;
  }
._opening_1ycflw8_33._iconPlus_1ycflw8_327 > ._button_1ycflw8_111 > ._icon_1ycflw8_281,
  ._opened_1ycflw8_34._iconPlus_1ycflw8_327 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    -webkit-transform:rotate(45deg);
            transform:rotate(45deg);
  }
._opening_1ycflw8_33._iconArrow_1ycflw8_332 > ._button_1ycflw8_111 > ._icon_1ycflw8_281,
  ._opened_1ycflw8_34._iconArrow_1ycflw8_332 > ._button_1ycflw8_111 > ._icon_1ycflw8_281{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
._accordion_1ycflw8_338{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}
._accordionGap0_1ycflw8_342{
  row-gap:0;
}
._accordionGap8_1ycflw8_345{
  row-gap:8px;
}
._accordionGap12_1ycflw8_348{
  row-gap:12px;
}
._accordionGap16_1ycflw8_351{
  row-gap:16px;
}
._accordionGap24_1ycflw8_354{
  row-gap:24px;
}
._accordionGap32_1ycflw8_357{
  row-gap:32px;
}
