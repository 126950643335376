._sizeS_1b6ux3g_8{
  min-width:316px;
  max-width:389px;
}

._sizeL_1b6ux3g_13,
._sizeM_1b6ux3g_14{
  min-width:316px;
  max-width:494px;
}

._menu_1b6ux3g_19{
  margin:0;
  padding:0;
  list-style:none;
}
