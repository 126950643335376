
.tablet_page {
    background: #27282c;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.tablet_header {
    display: flex;
}
.tablet_date {
    text-align: right;
    flex-grow: 1;
    white-space: nowrap;
}

.fitness_list {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    margin: 0;
    height: 100%;
}

.fitness_list li {
    list-style-type: none;
}

.tablet_help_message {
    flex-shrink: 0;
}

.content {
    display: flex;
    flex-direction: row;
    padding: 10px;

    background: #27282c;
}

.search {
    flex-grow: 1;
}

.employee_entry {
    display: inline-block;
    padding: 5px;
}

.focused {
    border: 3px dashed ivory;
    border-radius: 4px;
}

.marked {
    background-color: darkgreen;
    color: black;
}

.focused.marked {
    border: 3px dashed black;
}

.ineligible {
    opacity: 0.6;
}

.ineligible.marked {
    opacity: 1;
    color: rgba(0, 0, 0, 0.2)
}
