.timesheet_table {
    border-collapse: collapse;
}

.timesheet_table td {
    padding-left: 10px;
    padding-right: 10px;
}

.timesheet_table th {
    padding-left: 10px;
    padding-right: 10px;
}

.inspection_error {
    text-align: center;
    font-weight: bold;
    color: red;
}
