.book-entry {
  padding: 24px 0;
  display: flex;
  position: relative;
}

.book-entry .cover-image {
  width: 96px;
}

.book-entry .cover {
  float: left;
  padding-bottom: 1em;
  padding-right: 1em;
}

.book-entry .copy-list {
  flex: 1;
}

.book-entry .view {
  flex: 3;
}

.book-entry .view .title {
  margin-bottom: 8px;
}

.book-entry .view .description {
  color: var(--rs-color-grey-60);
  line-height: 1.6;
}

.book-entry .view .link {
  margin-left: 12px;
}

.edit-book-details textarea {
  width: 100%;
}

.edit-book-details input {
  margin-bottom: 8px;
}

.edit-book-details .links-and-loading {
  display: flex;
}

.edit-book-details .links-input {
  flex-grow: 1;
}

.book-entry .edit-copy input {
  margin-bottom: 8px;
}

.book-entry .collapsed {
  max-height: 15em;
  overflow: hidden;
}

.book-list .jetid-logo {
  width: 72px;
  height: 72px;
}

.book-list .tab {
  margin-top: 20px;
  margin-bottom: 20px;
}

.book-list .tab2-header {
  width: 256px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.book-list .tab2-header > * {
  vertical-align: middle;
}

.book-list .section .there-is-more {
  text-align: center;
  margin: 20px;
  font-size: 150%;
}

.book-list .no-results {
  text-align: center;
  margin: 20px;
  font-size: 30px;
}

.book-list .filtering-section {
  display: flex;
}

.book-list .filtering-section > * {
  margin: 12px 0;
}

._main_xxajeh_17 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_xxajeh_1 ._main_xxajeh_17 {
  letter-spacing: normal;
}

._main_xxajeh_17:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #6b57ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6b57ffcc;
  box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
}

._main_xxajeh_17 {
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  text-decoration: none;
  transition: color .1s, background-color .1s, border-color .1s;
  display: inline-block;
  position: relative;
}

._main_xxajeh_17:hover, ._main_xxajeh_17:focus, ._main_xxajeh_17:active {
  outline: none;
  text-decoration: none;
}

._main_xxajeh_17._busy_xxajeh_49 {
  pointer-events: none;
}

._main_xxajeh_17._busyOverlay_xxajeh_53 {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

._main_xxajeh_17._busyOverlay_xxajeh_53._light_xxajeh_59, ._main_xxajeh_17._busyOverlay_xxajeh_53._light_xxajeh_59:hover, ._main_xxajeh_17._busyOverlay_xxajeh_53._light_xxajeh_59._disabled_xxajeh_61, ._main_xxajeh_17._busyOverlay_xxajeh_53._dark_xxajeh_62._disabled_xxajeh_61, ._main_xxajeh_17._busyOverlay_xxajeh_53._dark_xxajeh_62, ._main_xxajeh_17._busyOverlay_xxajeh_53._dark_xxajeh_62:hover {
  color: #0000;
}

._dark_xxajeh_62:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #8473ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #8473ffcc;
  box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
}

._disabled_xxajeh_61 {
  pointer-events: none;
}

._alignIconLeft_xxajeh_77 {
  flex-direction: row;
}

._alignIconRight_xxajeh_81 {
  flex-direction: row-reverse;
}

._sizeXs_xxajeh_85 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_xxajeh_1 ._sizeXs_xxajeh_85 {
  letter-spacing: normal;
}

._sizeXs_xxajeh_85 {
  border-radius: 4px;
  border-radius: var(--rs-button-xs-border-radius, var(--rs-button-border-radius, 4px));
}

._sizeS_xxajeh_92 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_xxajeh_1 ._sizeS_xxajeh_92 {
  letter-spacing: normal;
}

._sizeS_xxajeh_92 {
  border-radius: 24px;
  border-radius: var(--rs-button-s-border-radius, var(--rs-button-border-radius, 24px));
}

._sizeM_xxajeh_99 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_xxajeh_1 ._sizeM_xxajeh_99 {
  letter-spacing: normal;
}

._sizeM_xxajeh_99 {
  border-radius: 24px;
  border-radius: var(--rs-button-m-border-radius, var(--rs-button-border-radius, 24px));
}

._sizeL_xxajeh_106 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  border-radius: 36px;
  border-radius: var(--rs-button-l-border-radius, var(--rs-button-border-radius, 36px));
}

._allowMultiline_xxajeh_114 {
  white-space: normal;
}

._multiline_xxajeh_118._sizeXs_xxajeh_85 {
  border-radius: 4px;
  border-radius: var(--rs-button-xs-border-radius, var(--rs-button-border-radius, 4px));
}

._multiline_xxajeh_118._sizeS_xxajeh_92 {
  border-radius: 4px;
  border-radius: var(--rs-button-s-border-radius, var(--rs-button-border-radius, 4px));
}

._multiline_xxajeh_118._sizeM_xxajeh_99 {
  border-radius: 8px;
  border-radius: var(--rs-button-m-border-radius, var(--rs-button-border-radius, 8px));
}

._multiline_xxajeh_118._sizeL_xxajeh_106 {
  border-radius: 12px;
  border-radius: var(--rs-button-l-border-radius, var(--rs-button-border-radius, 12px));
}

._withoutText_xxajeh_138, ._withIcon_xxajeh_144 {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

._busyIcon_xxajeh_150 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._flatRight_xxajeh_158 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._flatLeft_xxajeh_163 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._modeClassic_xxajeh_168 {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClassic_xxajeh_168 ._busyIcon_xxajeh_150 {
  color: #fff;
}

._modeClassic_xxajeh_168:focus {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClassic_xxajeh_168:hover {
    color: #fff;
    background: #6b57ffcc;
    background: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
  }
}

._modeClassic_xxajeh_168:active {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClassic_xxajeh_168:active:focus:not(:hover)[data-focus-method="key"] {
  background: #6b57ffcc;
  background: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
}

._modeClassic_xxajeh_168._disabled_xxajeh_61 {
  color: #19191c80;
  background: #19191c33;
}

._modeClassic_xxajeh_168._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #19191c80;
}

._modeClassic_xxajeh_168._dark_xxajeh_62 {
  color: #fff;
  background: #8473ff;
  background: var(--rs-color-primary-dark-theme, #8473ff);
}

._modeClassic_xxajeh_168._dark_xxajeh_62 ._busyIcon_xxajeh_150 {
  color: #fff;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClassic_xxajeh_168._dark_xxajeh_62:hover {
    color: #fff;
    background: #8473ffcc;
    background: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc);
  }
}

._modeClassic_xxajeh_168._dark_xxajeh_62:active {
  color: #fff;
  background: #8473ff;
  background: var(--rs-color-primary-dark-theme, #8473ff);
}

._modeClassic_xxajeh_168._dark_xxajeh_62:active:focus:not(:hover)[data-focus-method="key"] {
  background: #8473ffcc;
  background: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc);
}

._modeClassic_xxajeh_168._dark_xxajeh_62._disabled_xxajeh_61 {
  color: #ffffff80;
  background: #fff3;
}

._modeClassic_xxajeh_168._dark_xxajeh_62._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #ffffff80;
}

._modeRock_xxajeh_241 {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

._modeRock_xxajeh_241 ._busyIcon_xxajeh_150 {
  color: var(--rs-rock-button-text-color, #fff);
}

._modeRock_xxajeh_241:focus {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeRock_xxajeh_241:hover {
    color: var(--rs-rock-button-text-color, #fff);
    background-color: #19191ccc;
    background-color: var(--rs-rock-button-hover-bg-color, #19191ccc);
  }
}

._modeRock_xxajeh_241:active {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

._modeRock_xxajeh_241:active:focus:not(:hover)[data-focus-method="key"] {
  background-color: #19191ccc;
  background-color: var(--rs-rock-button-hover-bg-color, #19191ccc);
}

._modeRock_xxajeh_241._disabled_xxajeh_61 {
  color: var(--rs-rock-button-disabled-text-color, #19191c80);
  background-color: #19191c33;
  background-color: var(--rs-rock-button-disabled-bg-color, #19191c33);
}

._modeRock_xxajeh_241._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: var(--rs-rock-button-disabled-text-color, #19191c80);
}

._modeRock_xxajeh_241._dark_xxajeh_62 {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

._modeRock_xxajeh_241._dark_xxajeh_62 ._busyIcon_xxajeh_150 {
  color: var(--rs-rock-button-text-color, #19191c);
}

._modeRock_xxajeh_241._dark_xxajeh_62:focus {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeRock_xxajeh_241._dark_xxajeh_62:hover {
    color: var(--rs-rock-button-text-color, #19191c);
    background-color: #fffc;
    background-color: var(--rs-rock-button-hover-bg-color, #fffc);
  }
}

._modeRock_xxajeh_241._dark_xxajeh_62:active {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

._modeRock_xxajeh_241._dark_xxajeh_62:active:focus:not(:hover)[data-focus-method="key"] {
  background-color: #fffc;
  background-color: var(--rs-rock-button-hover-bg-color, #fffc);
}

._modeRock_xxajeh_241._dark_xxajeh_62._disabled_xxajeh_61 {
  color: var(--rs-rock-button-disabled-text-color, #ffffff80);
  background-color: #fff3;
  background-color: var(--rs-rock-button-disabled-bg-color, #fff3);
}

._modeRock_xxajeh_241._dark_xxajeh_62._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: var(--rs-rock-button-disabled-text-color, #ffffff80);
}

._modeTransparent_xxajeh_319 {
  color: #fff;
  background-color: #19191c80;
}

._modeTransparent_xxajeh_319 ._busyIcon_xxajeh_150 {
  color: #fff;
}

._modeTransparent_xxajeh_319:focus {
  color: #fff;
  background-color: #19191c80;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeTransparent_xxajeh_319:hover {
    color: #fff;
    background: #19191ccc;
  }
}

._modeTransparent_xxajeh_319:active {
  color: #fff;
  background: #19191c;
}

._modeTransparent_xxajeh_319._disabled_xxajeh_61 {
  color: #ffffff80;
  background: #19191c66;
}

._modeTransparent_xxajeh_319._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #ffffff80;
}

._modeTransparent_xxajeh_319._dark_xxajeh_62 {
  color: #19191c;
  background: #ffffff80;
}

._modeTransparent_xxajeh_319._dark_xxajeh_62 ._busyIcon_xxajeh_150 {
  color: #19191c;
}

._modeTransparent_xxajeh_319._dark_xxajeh_62:focus {
  color: #19191c;
  background: #ffffff80;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeTransparent_xxajeh_319._dark_xxajeh_62:hover {
    color: #19191c;
    background: #fffc;
  }
}

._modeTransparent_xxajeh_319._dark_xxajeh_62:active {
  color: #19191c;
  background: #fff;
}

._modeTransparent_xxajeh_319._dark_xxajeh_62._disabled_xxajeh_61 {
  color: #19191c80;
  background: #fff6;
}

._modeTransparent_xxajeh_319._dark_xxajeh_62._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #19191c80;
}

._modeOutline_xxajeh_389 {
  color: #19191c;
  border-style: solid;
  border-color: #19191c4d;
  position: relative;
}

._modeOutline_xxajeh_389 ._busyIcon_xxajeh_150 {
  color: #19191c;
}

._modeOutline_xxajeh_389:focus {
  color: #19191c;
  border-color: #19191c4d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeOutline_xxajeh_389:hover {
    color: #19191c;
    background: #19191c1a;
    border-color: #19191c4d;
  }
}

._modeOutline_xxajeh_389:active {
  color: #19191c;
  background: #19191c1a;
  border-color: #19191c;
}

._modeOutline_xxajeh_389._disabled_xxajeh_61 {
  color: #19191c80;
  background: none;
  border-color: #19191c33;
}

._modeOutline_xxajeh_389._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #19191c80;
}

._modeOutline_xxajeh_389._dark_xxajeh_62 {
  color: #fff;
  border-color: #ffffff4d;
}

._modeOutline_xxajeh_389._dark_xxajeh_62 ._busyIcon_xxajeh_150 {
  color: #fff;
}

._modeOutline_xxajeh_389._dark_xxajeh_62:focus {
  color: #fff;
  border-color: #fff6;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeOutline_xxajeh_389._dark_xxajeh_62:hover {
    color: #fff;
    background: #ffffff1a;
    border-color: #fff6;
  }
}

._modeOutline_xxajeh_389._dark_xxajeh_62:active {
  color: #fff;
  background: #ffffff1a;
  border-color: #fff;
}

._modeOutline_xxajeh_389._dark_xxajeh_62._disabled_xxajeh_61 {
  color: #ffffff80;
  background: none;
  border-color: #fff3;
}

._modeOutline_xxajeh_389._dark_xxajeh_62._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #ffffff80;
}

._modeClear_xxajeh_478 {
  color: #19191c;
  background: none;
}

._modeClear_xxajeh_478 ._busyIcon_xxajeh_150 {
  color: #19191c;
}

._modeClear_xxajeh_478:focus {
  color: #19191c;
  background: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClear_xxajeh_478:hover {
    color: #19191c;
    background: #19191c1a;
  }
}

._modeClear_xxajeh_478:active {
  color: #19191c;
  background: #19191c33;
}

._modeClear_xxajeh_478._disabled_xxajeh_61 {
  color: #19191c80;
  background: none;
}

._modeClear_xxajeh_478._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #19191c80;
}

._modeClear_xxajeh_478._highlighted_xxajeh_513 {
  color: #19191c;
  background: #19191c1a;
}

._modeClear_xxajeh_478._dark_xxajeh_62 {
  color: #fff;
  background: none;
}

._modeClear_xxajeh_478._dark_xxajeh_62 ._busyIcon_xxajeh_150 {
  color: #fff;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClear_xxajeh_478._dark_xxajeh_62:hover {
    color: #fff;
    background: #ffffff1a;
  }
}

._modeClear_xxajeh_478._dark_xxajeh_62:active {
  color: #fff;
  background: #fff3;
}

._modeClear_xxajeh_478._dark_xxajeh_62._disabled_xxajeh_61, ._modeClear_xxajeh_478._dark_xxajeh_62._disabled_xxajeh_61 ._busyIcon_xxajeh_150 {
  color: #ffffff80;
}

._modeClear_xxajeh_478._dark_xxajeh_62._highlighted_xxajeh_513 {
  color: #fff;
  background: #ffffff1a;
}

._sizeXs_xxajeh_85 {
  border-width: 0;
  padding: 2px 16px;
}

._sizeS_xxajeh_92 {
  border-width: 0;
  padding: 6px 16px;
}

._sizeM_xxajeh_99 {
  border-width: 0;
  padding: 8px 24px;
}

._sizeL_xxajeh_106 {
  border-width: 0;
  padding: 12px 32px;
}

._sizeXs_xxajeh_85._withoutText_xxajeh_138 {
  border-width: 0;
  padding: 2px;
}

._sizeS_xxajeh_92._withoutText_xxajeh_138 {
  border-width: 0;
  padding: 6px;
}

._sizeM_xxajeh_99._withoutText_xxajeh_138 {
  border-width: 0;
  padding: 8px;
}

._sizeL_xxajeh_106._withoutText_xxajeh_138 {
  border-width: 0;
  padding: 12px;
}

._sizeXs_xxajeh_85._modeOutline_xxajeh_389 {
  border-width: 1px;
  padding: 1px 15px;
}

._sizeS_xxajeh_92._modeOutline_xxajeh_389 {
  border-width: 1px;
  padding: 5px 15px;
}

._sizeM_xxajeh_99._modeOutline_xxajeh_389 {
  border-width: 1px;
  padding: 7px 23px;
}

._sizeL_xxajeh_106._modeOutline_xxajeh_389 {
  border-width: 1px;
  padding: 11px 31px;
}

._sizeXs_xxajeh_85._modeOutline_xxajeh_389._withoutText_xxajeh_138 {
  border-width: 1px;
  padding: 1px;
}

._sizeS_xxajeh_92._modeOutline_xxajeh_389._withoutText_xxajeh_138 {
  border-width: 1px;
  padding: 5px;
}

._sizeM_xxajeh_99._modeOutline_xxajeh_389._withoutText_xxajeh_138 {
  border-width: 1px;
  padding: 7px;
}

._sizeL_xxajeh_106._modeOutline_xxajeh_389._withoutText_xxajeh_138 {
  border-width: 1px;
  padding: 11px;
}

._sizeXs_xxajeh_85._modeOutline_xxajeh_389:active {
  border-width: 2px;
  padding: 0 14px;
}

._sizeS_xxajeh_92._modeOutline_xxajeh_389:active {
  border-width: 2px;
  padding: 4px 14px;
}

._sizeM_xxajeh_99._modeOutline_xxajeh_389:active {
  border-width: 2px;
  padding: 6px 22px;
}

._sizeL_xxajeh_106._modeOutline_xxajeh_389:active {
  border-width: 2px;
  padding: 10px 30px;
}

._sizeXs_xxajeh_85._modeOutline_xxajeh_389._withoutText_xxajeh_138:active {
  border-width: 2px;
  padding: 0;
}

._sizeS_xxajeh_92._modeOutline_xxajeh_389._withoutText_xxajeh_138:active {
  border-width: 2px;
  padding: 4px;
}

._sizeM_xxajeh_99._modeOutline_xxajeh_389._withoutText_xxajeh_138:active {
  border-width: 2px;
  padding: 6px;
}

._sizeL_xxajeh_106._modeOutline_xxajeh_389._withoutText_xxajeh_138:active {
  border-width: 2px;
  padding: 10px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeXs_xxajeh_85 {
  margin-left: -16px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeXs_xxajeh_85 {
  margin-right: -16px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeXs_xxajeh_85 {
  margin-top: -2px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeXs_xxajeh_85 {
  margin-bottom: -2px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeS_xxajeh_92 {
  margin-left: -16px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeS_xxajeh_92 {
  margin-right: -16px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeS_xxajeh_92 {
  margin-top: -6px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeS_xxajeh_92 {
  margin-bottom: -6px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeM_xxajeh_99 {
  margin-left: -24px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeM_xxajeh_99 {
  margin-right: -24px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeM_xxajeh_99 {
  margin-top: -8px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeM_xxajeh_99 {
  margin-bottom: -8px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeL_xxajeh_106 {
  margin-left: -32px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeL_xxajeh_106 {
  margin-right: -32px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeL_xxajeh_106 {
  margin-top: -12px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeL_xxajeh_106 {
  margin-bottom: -12px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeXs_xxajeh_85._withoutText_xxajeh_138 {
  margin-left: -2px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeXs_xxajeh_85._withoutText_xxajeh_138 {
  margin-right: -2px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeXs_xxajeh_85._withoutText_xxajeh_138 {
  margin-top: -2px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeXs_xxajeh_85._withoutText_xxajeh_138 {
  margin-bottom: -2px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeS_xxajeh_92._withoutText_xxajeh_138 {
  margin-left: -6px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeS_xxajeh_92._withoutText_xxajeh_138 {
  margin-right: -6px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeS_xxajeh_92._withoutText_xxajeh_138 {
  margin-top: -6px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeS_xxajeh_92._withoutText_xxajeh_138 {
  margin-bottom: -6px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeM_xxajeh_99._withoutText_xxajeh_138 {
  margin-left: -8px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeM_xxajeh_99._withoutText_xxajeh_138 {
  margin-right: -8px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeM_xxajeh_99._withoutText_xxajeh_138 {
  margin-top: -8px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeM_xxajeh_99._withoutText_xxajeh_138 {
  margin-bottom: -8px;
}

._modeClear_xxajeh_478._shiftedLeft_xxajeh_564._sizeL_xxajeh_106._withoutText_xxajeh_138 {
  margin-left: -12px;
}

._modeClear_xxajeh_478._shiftedRight_xxajeh_565._sizeL_xxajeh_106._withoutText_xxajeh_138 {
  margin-right: -12px;
}

._modeClear_xxajeh_478._shiftedTop_xxajeh_566._sizeL_xxajeh_106._withoutText_xxajeh_138 {
  margin-top: -12px;
}

._modeClear_xxajeh_478._shiftedBottom_xxajeh_567._sizeL_xxajeh_106._withoutText_xxajeh_138 {
  margin-bottom: -12px;
}

._icon_xxajeh_569 {
  fill: currentColor;
}

._sizeXs_xxajeh_85 ._icon_xxajeh_569 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeS_xxajeh_92 ._icon_xxajeh_569 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeM_xxajeh_99 ._icon_xxajeh_569 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeL_xxajeh_106 ._icon_xxajeh_569 {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-left: -2px;
  margin-right: 16px;
}

._sizeXs_xxajeh_85._alignIconRight_xxajeh_81 ._icon_xxajeh_569 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeS_xxajeh_92._alignIconRight_xxajeh_81 ._icon_xxajeh_569 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeM_xxajeh_99._alignIconRight_xxajeh_81 ._icon_xxajeh_569 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeL_xxajeh_106._alignIconRight_xxajeh_81 ._icon_xxajeh_569 {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-left: 16px;
  margin-right: -2px;
}

._withoutText_xxajeh_138 ._icon_xxajeh_569, ._withoutText_xxajeh_138._alignIconRight_xxajeh_81 ._icon_xxajeh_569 {
  margin: 0;
}

._sizeXs_xxajeh_85 ._icon_xxajeh_569 {
  margin-top: 2px;
}

._sizeXs_xxajeh_85._withoutText_xxajeh_138 ._icon_xxajeh_569 {
  margin-top: 0;
}

._icon_1lgbkjk_3 {
  fill: currentColor;
}

._sizeXS_1lgbkjk_7 {
  width: 16px;
  height: 16px;
}

._sizeS_1lgbkjk_12 {
  width: 20px;
  height: 20px;
}

._sizeM_1lgbkjk_17 {
  width: 24px;
  height: 24px;
}

._sizeL_1lgbkjk_22 {
  width: 28px;
  height: 28px;
}

._light_1lgbkjk_27 {
  fill: #19191c;
}

._dark_1lgbkjk_31 {
  fill: #fff;
}

@keyframes rs-icon-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

._sizeS_1b6ux3g_8 {
  min-width: 316px;
  max-width: 389px;
}

._sizeL_1b6ux3g_13, ._sizeM_1b6ux3g_14 {
  min-width: 316px;
  max-width: 494px;
}

._menu_1b6ux3g_19 {
  margin: 0;
  padding: 0;
  list-style: none;
}

@keyframes _fadeInTopToBottom_1bu2l6k_1 {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes _fadeInBottomToTop_1bu2l6k_1 {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes _fadeInLeftToRight_1bu2l6k_1 {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes _fadeInRightToLeft_1bu2l6k_1 {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

._dropdown_1bu2l6k_50 {
  flex-direction: column;
  display: flex;
}

._triggerWrapper_1bu2l6k_55 {
  display: inline-block;
}

._contentWrapper_1bu2l6k_59 {
  box-sizing: border-box;
  background-clip: padding-box;
  margin: 1px;
  transition: opacity .2s linear, transform .2s linear, -webkit-transform .2s linear;
  display: block;
  position: relative;
  overflow-y: auto;
}

[data-popper-placement^="top"] ._contentWrapper_1bu2l6k_59 {
  animation: .2s _fadeInBottomToTop_1bu2l6k_1;
}

[data-popper-placement^="bottom"] ._contentWrapper_1bu2l6k_59 {
  animation: .2s _fadeInTopToBottom_1bu2l6k_1;
}

[data-popper-placement^="right"] ._contentWrapper_1bu2l6k_59 {
  animation: .2s _fadeInLeftToRight_1bu2l6k_1;
}

[data-popper-placement^="left"] ._contentWrapper_1bu2l6k_59 {
  animation: .2s _fadeInRightToLeft_1bu2l6k_1;
}

._wrapperHidden_1bu2l6k_85 {
  opacity: 0;
}

[data-popper-placement^="top"] ._wrapperHidden_1bu2l6k_85 {
  transform: translateY(10px);
}

[data-popper-placement^="bottom"] ._wrapperHidden_1bu2l6k_85 {
  transform: translateY(-10px);
}

[data-popper-placement^="right"] ._wrapperHidden_1bu2l6k_85 {
  transform: translateX(-10px);
}

[data-popper-placement^="left"] ._wrapperHidden_1bu2l6k_85 {
  transform: translateX(10px);
}

._themeLight_1bu2l6k_105 ._contentWrapper_1bu2l6k_59 {
  background-color: #fff;
  box-shadow: 0 0 0 1px #d1d1d2;
}

._themeLight_1bu2l6k_105._modeRock_1bu2l6k_110 ._contentWrapper_1bu2l6k_59 {
  background-color: #19191c;
}

._themeDark_1bu2l6k_114 ._contentWrapper_1bu2l6k_59 {
  background-color: #303033;
  box-shadow: 0 0 0 1px #757577;
}

._themeDark_1bu2l6k_114._modeRock_1bu2l6k_110 ._contentWrapper_1bu2l6k_59 {
  background-color: #fff;
}

._modeRock_1bu2l6k_110 {
  --rs-parent-color-primary-light-theme: var(--rs-color-primary-light-theme);
  --rs-parent-color-primary-dim-light-theme: var(--rs-color-primary-dim-light-theme);
  --rs-parent-color-primary-fog-light-theme: var(--rs-color-primary-fog-light-theme);
  --rs-parent-color-primary-t-dim-light-theme: var(--rs-color-primary-t-dim-light-theme);
  --rs-parent-color-primary-t-fog-light-theme: var(--rs-color-primary-t-fog-light-theme);
  --rs-parent-color-primary-dark-theme: var(--rs-color-primary-dark-theme);
  --rs-parent-color-primary-dim-dark-theme: var(--rs-color-primary-dim-dark-theme);
  --rs-parent-color-primary-fog-dark-theme: var(--rs-color-primary-fog-dark-theme);
  --rs-parent-color-primary-t-dim-dark-theme: var(--rs-color-primary-t-dim-dark-theme);
  --rs-parent-color-primary-t-fog-dark-theme: var(--rs-color-primary-t-fog-dark-theme);
}

._modeRock_1bu2l6k_110 > * {
  --rs-color-primary-dark-theme: var(--rs-parent-color-primary-light-theme);
  --rs-color-primary-dim-dark-theme: var(--rs-parent-color-primary-dim-light-theme);
  --rs-color-primary-fog-dark-theme: var(--rs-parent-color-primary-fog-light-theme);
  --rs-color-primary-t-dim-dark-theme: var(--rs-parent-color-primary-t-dim-light-theme);
  --rs-color-primary-t-fog-dark-theme: var(--rs-parent-color-primary-t-fog-light-theme);
  --rs-color-primary-light-theme: var(--rs-parent-color-primary-dark-theme);
  --rs-color-primary-dim-light-theme: var(--rs-parent-color-primary-dim-dark-theme);
  --rs-color-primary-fog-light-theme: var(--rs-parent-color-primary-fog-dark-theme);
  --rs-color-primary-t-dim-light-theme: var(--rs-parent-color-primary-t-dim-dark-theme);
  --rs-color-primary-t-fog-light-theme: var(--rs-parent-color-primary-t-fog-dark-theme);
}

._wrapper_imuce_8 {
  display: flex;
}

._menuItem_imuce_12 {
  cursor: pointer;
  text-align: start;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  flex-grow: 1;
  width: auto;
  margin: 0;
  text-decoration: none;
  display: flex;
  overflow: auto;
}

._menuItem_imuce_12:focus {
  outline: none;
}

._content_imuce_40 {
  font-size: inherit;
  flex-grow: 1;
}

._placementRight_imuce_46 {
  justify-content: space-between;
}

._sizeS_imuce_51 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
  padding: 6px 8px;
}

._rs-reset-letter-spacing_imuce_1 ._sizeS_imuce_51 {
  letter-spacing: normal;
}

._sizeM_imuce_56 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
  padding: 8px 16px;
}

._rs-reset-letter-spacing_imuce_1 ._sizeM_imuce_56 {
  letter-spacing: normal;
}

._sizeL_imuce_61 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  padding: 12px 16px;
}

._modeClear_imuce_66._sizeS_imuce_51, ._modeClear_imuce_66._sizeM_imuce_56, ._modeClear_imuce_66._sizeL_imuce_61 {
  padding-left: 0;
  padding-right: 0;
}

._themeLight_imuce_73, ._themeLight_imuce_73:focus {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_imuce_78, ._themeDark_imuce_78:focus {
  color: var(--rs-typography-color-hard, #fff);
}

._keyboardNavigationDisabled_imuce_83 ._themeLight_imuce_73:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 2px #8979ff, inset 0 0 0 2px #8979ff;
  -webkit-box-shadow: 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff), inset 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff);
  box-shadow: 0 0 0 2px #8979ff, inset 0 0 0 2px #8979ff;
  box-shadow: 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff), inset 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff);
}

._keyboardNavigationDisabled_imuce_83 ._themeDark_imuce_78:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 2px #6f61d2, inset 0 0 0 2px #6f61d2;
  -webkit-box-shadow: 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2), inset 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  box-shadow: 0 0 0 2px #6f61d2, inset 0 0 0 2px #6f61d2;
  box-shadow: 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2), inset 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2);
}

._modeClassic_imuce_99._themeLight_imuce_73:active {
  color: var(--rs-typography-color-hard, #19191c);
  background-color: #6b57ff33;
  background-color: var(--rs-color-primary-t-fog-light-theme, #6b57ff33);
}

._modeClassic_imuce_99._themeDark_imuce_78:active {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #8473ff4d;
  background-color: var(--rs-color-primary-t-fog-dark-theme, #8473ff4d);
}

._modeClassic_imuce_99._themeLight_imuce_73._selected_imuce_111 {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #19191c;
}

._modeClassic_imuce_99._themeDark_imuce_78._selected_imuce_111 {
  color: var(--rs-typography-color-hard, #19191c);
  background-color: #fff;
}

._modeRock_imuce_121._themeLight_imuce_73._selected_imuce_111 {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #19191c;
}

._modeRock_imuce_121._themeDark_imuce_78._selected_imuce_111 {
  color: var(--rs-typography-color-hard, #19191c);
  background-color: #fff;
}

._modeClear_imuce_66._themeLight_imuce_73._selected_imuce_111 {
  color: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClear_imuce_66._themeDark_imuce_78._selected_imuce_111 {
  color: var(--rs-color-primary-dark-theme, #8473ff);
}

._disableCursor_imuce_141 {
  pointer-events: none;
}

._modeClassic_imuce_99._themeLight_imuce_73._highlighted_imuce_146, ._modeClassic_imuce_99._themeLight_imuce_73:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #19191c);
  background-color: #19191c1a;
}

._modeRock_imuce_121._themeLight_imuce_73._highlighted_imuce_146, ._modeRock_imuce_121._themeLight_imuce_73:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #6b57ff;
  background-color: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClassic_imuce_99._themeDark_imuce_78._highlighted_imuce_146, ._modeClassic_imuce_99._themeDark_imuce_78:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #ffffff1a;
}

._modeRock_imuce_121._themeDark_imuce_78._highlighted_imuce_146, ._modeRock_imuce_121._themeDark_imuce_78:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #8473ff;
  background-color: var(--rs-color-primary-dark-theme, #8473ff);
}

._modeClear_imuce_66._themeLight_imuce_73._highlighted_imuce_146, ._modeClear_imuce_66._themeLight_imuce_73:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #19191c);
}

._modeClear_imuce_66._themeDark_imuce_78._highlighted_imuce_146, ._modeClear_imuce_66._themeDark_imuce_78:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #fff);
}

._modeClassic_imuce_99._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111, ._modeClassic_imuce_99._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover, ._modeRock_imuce_121._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111, ._modeRock_imuce_121._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #fff);
  background-color: #303033;
}

._modeClassic_imuce_99._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111, ._modeClassic_imuce_99._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover, ._modeRock_imuce_121._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111, ._modeRock_imuce_121._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover {
  color: var(--rs-typography-color-hard, #19191c);
  background-color: #e8e8e8;
}

._modeClear_imuce_66._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111, ._modeClear_imuce_66._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover {
  background-color: #19191c1a;
}

._modeClear_imuce_66._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111, ._modeClear_imuce_66._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover {
  background-color: #ffffff1a;
}

._menuItem_imuce_12._themeLight_imuce_73._disabled_imuce_229 {
  pointer-events: none;
  color: var(--rs-typography-color-pale, #19191c80);
}

._menuItem_imuce_12._themeDark_imuce_78._disabled_imuce_229 {
  pointer-events: none;
  color: var(--rs-typography-color-pale, #ffffff80);
}

._icon_imuce_240 {
  flex: none;
}

._sizeS_imuce_51._placementLeft_imuce_244 ._icon_imuce_240 {
  margin-right: 8px;
}

._sizeS_imuce_51._placementRight_imuce_46 ._icon_imuce_240 {
  margin-left: 8px;
}

._sizeM_imuce_56._placementLeft_imuce_244 ._icon_imuce_240 {
  margin-right: 8px;
}

._sizeM_imuce_56._placementRight_imuce_46 ._icon_imuce_240 {
  margin-left: 8px;
}

._sizeL_imuce_61._placementLeft_imuce_244 ._icon_imuce_240 {
  margin-right: 16px;
}

._sizeL_imuce_61._placementRight_imuce_46 ._icon_imuce_240 {
  margin-left: 16px;
}

._divider_imuce_270 {
  border: none;
  flex-shrink: 0;
  width: 100%;
  height: 1px;
  margin: 0;
}

._divider_imuce_270._themeLight_imuce_73 {
  background-color: #19191c33;
}

._divider_imuce_270._themeDark_imuce_78 {
  background-color: #fff3;
}

._groupHeader_imuce_288 {
  border: none;
}

._groupHeaderSizeL_imuce_292 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-h3-font-size, 20px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 28px;
  line-height: var(--rs-h3-line-height, 28px);
  padding: 12px 16px;
}

._groupHeaderSizeM_imuce_297 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-h4-font-size, 16px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 24px;
  line-height: var(--rs-h4-line-height, 24px);
  padding: 8px 16px;
}

._groupHeaderSizeS_imuce_302 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-h5-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 20px;
  line-height: var(--rs-h5-line-height, 20px);
  padding: 6px 8px;
}

._groupHeaderThemeLight_imuce_307 {
  color: var(--rs-typography-color-hard, #19191c);
}

._groupHeaderThemeDark_imuce_311 {
  color: var(--rs-typography-color-hard, #fff);
}

._wrapper_134p28p_9 {
  position: relative;
}

._tabsContainer_134p28p_13 {
  display: flex;
  position: relative;
}

._indicator_134p28p_19 {
  z-index: 3;
  will-change: left, width;
  width: 100%;
  height: 2px;
  transition: left .3s cubic-bezier(.4, 0, .2, 1), width .3s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 0;
}

._light_134p28p_31._classic_134p28p_31 ._indicator_134p28p_19 {
  background-color: #6b57ff;
  background-color: var(--rs-color-primary-light-theme, #6b57ff);
}

._dark_134p28p_35._classic_134p28p_31 ._indicator_134p28p_19 {
  background-color: #8473ff;
  background-color: var(--rs-color-primary-dark-theme, #8473ff);
}

._light_134p28p_31._rock_134p28p_39 ._indicator_134p28p_19 {
  background-color: #19191c;
}

._dark_134p28p_35._rock_134p28p_39 ._indicator_134p28p_19 {
  background-color: #fff;
}

._tab_134p28p_13 {
  flex-grow: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: initial;
  flex-basis: initial;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  align-items: center;
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

._tab_134p28p_13::-moz-focus-inner {
  border: none;
  padding: 0;
}

._tab_134p28p_13:hover, ._tab_134p28p_13:active, ._tab_134p28p_13:focus {
  outline: none;
  text-decoration: none;
}

._tab_134p28p_13:after {
  content: "";
  background-color: #0000;
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

._tab_134p28p_13:focus[data-focus-method="key"]:after {
  opacity: 0;
}

._tab_134p28p_13:hover:after {
  height: 1px;
}

._sizeM_134p28p_125 ._tab_134p28p_13 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_134p28p_1 ._sizeM_134p28p_125 ._tab_134p28p_13 {
  letter-spacing: normal;
}

._sizeM_134p28p_125 ._tab_134p28p_13 {
  padding: 8px 0;
}

._sizeL_134p28p_130 ._tab_134p28p_13 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  padding: 12px 0;
}

._innerOffsetM_134p28p_135 ._tab_134p28p_13 {
  margin-left: 24px;
}

@media screen and (width <= 640px) {
  ._innerOffsetM_134p28p_135 ._tab_134p28p_13 {
    margin-left: 16px;
  }
}

._innerOffsetL_134p28p_143 ._tab_134p28p_13 {
  margin-left: 32px;
}

@media screen and (width <= 640px) {
  ._innerOffsetL_134p28p_143 ._tab_134p28p_13 {
    margin-left: 24px;
  }
}

._light_134p28p_31 ._tab_134p28p_13 {
  color: #19191cb3;
}

._dark_134p28p_35 ._tab_134p28p_13 {
  color: #ffffffb3;
}

._light_134p28p_31 ._tab_134p28p_13._short_134p28p_159 {
  box-shadow: inset 0 -1px #19191c4d;
}

._dark_134p28p_35 ._tab_134p28p_13._short_134p28p_159 {
  box-shadow: inset 0 -1px #fff6;
}

._tab_134p28p_13._selected_134p28p_167 {
  z-index: 1;
  cursor: default;
  transition: color .2s cubic-bezier(.4, 0, .2, 1);
}

._tab_134p28p_13._selected_134p28p_167:after {
  height: 2px;
  transition: background-color 0s .3s, height 0s .3s;
}

._light_134p28p_31 ._tab_134p28p_13:hover {
  color: #19191c;
}

._light_134p28p_31 ._tab_134p28p_13:hover:after {
  background-color: #19191c;
}

._dark_134p28p_35 ._tab_134p28p_13:hover {
  color: #fff;
}

._dark_134p28p_35 ._tab_134p28p_13:hover:after {
  background-color: #fff;
}

._light_134p28p_31._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167 {
  color: #19191c;
}

._light_134p28p_31._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167:after {
  background-color: #19191c;
}

._dark_134p28p_35._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167 {
  color: #fff;
}

._dark_134p28p_35._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167:after {
  background-color: #fff;
}

._light_134p28p_31._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167 {
  color: var(--rs-color-primary-light-theme, #6b57ff);
}

._light_134p28p_31._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167:after {
  background-color: #6b57ff;
  background-color: var(--rs-color-primary-light-theme, #6b57ff);
}

._dark_134p28p_35._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167 {
  color: var(--rs-color-primary-dark-theme, #8473ff);
}

._dark_134p28p_35._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167:after {
  background-color: #8473ff;
  background-color: var(--rs-color-primary-dark-theme, #8473ff);
}

._light_134p28p_31 ._tab_134p28p_13:focus[data-focus-method="key"] {
  -webkit-box-shadow: inset 0 -4px #8979ff, inset 0 4px #8979ff, -4px 0 #8979ff, 4px 0 #8979ff;
  -webkit-box-shadow: inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 -4px 0 0, inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 4px 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) -4px 0 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) 4px 0 0 0;
  box-shadow: inset 0 -4px #8979ff, inset 0 4px #8979ff, -4px 0 #8979ff, 4px 0 #8979ff;
  box-shadow: inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 -4px 0 0, inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 4px 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) -4px 0 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) 4px 0 0 0;
}

._light_134p28p_31 ._tab_134p28p_13:focus[data-focus-method="key"]:after {
  opacity: 0;
}

._dark_134p28p_35 ._tab_134p28p_13:focus[data-focus-method="key"] {
  -webkit-box-shadow: inset 0 -4px #6f61d2, inset 0 4px #6f61d2, -4px 0 #6f61d2, 4px 0 #6f61d2;
  -webkit-box-shadow: inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 -4px 0 0, inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 4px 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) -4px 0 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) 4px 0 0 0;
  box-shadow: inset 0 -4px #6f61d2, inset 0 4px #6f61d2, -4px 0 #6f61d2, 4px 0 #6f61d2;
  box-shadow: inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 -4px 0 0, inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 4px 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) -4px 0 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) 4px 0 0 0;
}

._dark_134p28p_35 ._tab_134p28p_13:focus[data-focus-method="key"]:after {
  opacity: 0;
}

._innerOffsetL_134p28p_143 ._tab_134p28p_13:first-child, ._innerOffsetM_134p28p_135 ._tab_134p28p_13:first-child {
  margin-left: 0;
}

@media screen and (width <= 640px) {
  ._innerOffsetL_134p28p_143 ._tab_134p28p_13:first-child, ._innerOffsetM_134p28p_135 ._tab_134p28p_13:first-child {
    margin-left: 0;
  }
}

._icon_134p28p_269 {
  flex: none;
}

._sizeM_134p28p_125 ._icon_134p28p_269 {
  width: 24px;
  height: 24px;
}

._sizeL_134p28p_130 ._icon_134p28p_269 {
  width: 28px;
  height: 28px;
}

._sizeM_134p28p_125 ._icon_134p28p_269._iconLeft_134p28p_283 {
  margin-right: 4px;
}

._sizeL_134p28p_130 ._icon_134p28p_269._iconLeft_134p28p_283 {
  margin-right: 8px;
}

._sizeM_134p28p_125 ._icon_134p28p_269._iconRight_134p28p_291 {
  margin-left: 4px;
}

._sizeL_134p28p_130 ._icon_134p28p_269._iconRight_134p28p_291 {
  margin-left: 8px;
}

._wrapper_vrgn9m_4 {
  display: flex;
  position: relative;
}

._scrollable_vrgn9m_10 {
  margin: -4px;
  padding: 4px;
  display: flex;
  overflow-x: scroll;
}

._scrollable_vrgn9m_10:after {
  content: "";
  border-left: 4px solid #0000;
  height: 100%;
  display: block;
}

._scrollable_vrgn9m_10 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._scrollable_vrgn9m_10::-webkit-scrollbar {
  display: none;
}

._arrow_vrgn9m_39 {
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  background-color: #0000;
  align-items: center;
  height: 100%;
  transition: opacity .15s ease-out;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

._light_vrgn9m_58 ._arrow_vrgn9m_39 {
  color: #fff;
}

._dark_vrgn9m_61 ._arrow_vrgn9m_39 {
  color: #19191c;
}

._arrow_vrgn9m_39._shown_vrgn9m_65 {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .15s ease-in;
}

._arrow_vrgn9m_39._arrowLeft_vrgn9m_73 {
  left: -5px;
}

._arrow_vrgn9m_39._arrowRight_vrgn9m_78 {
  right: -5px;
}

._withLeftArrow_vrgn9m_84 {
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), to(black));
  -webkit-mask-image: linear-gradient(to right, #0000 0% 32px, #000 56px);
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), to(black));
  mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), to(black));
  mask-image: linear-gradient(to right, #0000 0% 32px, #000 56px);
}

._withRightArrow_vrgn9m_93 {
  -webkit-mask-image: -webkit-gradient(linear, right top, left top, from(#0000), color-stop(32px, #0000), to(black));
  -webkit-mask-image: linear-gradient(to left, #0000 0% 32px, #000 56px);
  -webkit-mask-image: -webkit-gradient(linear, right top, left top, from(#0000), color-stop(32px, #0000), to(black));
  mask-image: -webkit-gradient(linear, right top, left top, from(#0000), color-stop(32px, #0000), to(black));
  mask-image: linear-gradient(to left, #0000 0% 32px, #000 56px);
}

._withLeftArrow_vrgn9m_84._withRightArrow_vrgn9m_93 {
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), color-stop(black), color-stop(black), color-stop(#0000), to(#0000));
  -webkit-mask-image: linear-gradient(to right, #0000 0% 32px, #000 56px calc(100% - 56px), #0000 calc(100% - 32px) 100%);
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), color-stop(black), color-stop(black), color-stop(#0000), to(#0000));
  mask-image: -webkit-gradient(linear, left top, right top, from(#0000), color-stop(32px, #0000), color-stop(black), color-stop(black), color-stop(#0000), to(#0000));
  mask-image: linear-gradient(to right, #0000 0% 32px, #000 56px calc(100% - 56px), #0000 calc(100% - 32px) 100%);
}

._arrowButton_vrgn9m_114 {
  cursor: pointer;
}

._separator_1dayppn_3 {
  z-index: 2;
  width: 100%;
  position: relative;
}

._separator_1dayppn_3:after {
  content: "";
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

._light_1dayppn_22:after {
  background-color: #19191c4d;
}

._dark_1dayppn_28:after {
  background-color: #fff6;
}

._main_3w8gmt_17 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_3w8gmt_1 ._main_3w8gmt_17 {
  letter-spacing: normal;
}

._main_3w8gmt_17:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #6b57ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6b57ffcc;
  box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
}

._main_3w8gmt_17 {
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  text-decoration: none;
  transition: color .1s, background-color .1s, border-color .1s;
  display: inline-block;
  position: relative;
}

._main_3w8gmt_17:hover, ._main_3w8gmt_17:focus, ._main_3w8gmt_17:active {
  outline: none;
  text-decoration: none;
}

._main_3w8gmt_17._busy_3w8gmt_49 {
  pointer-events: none;
}

._main_3w8gmt_17._busyOverlay_3w8gmt_53 {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

._main_3w8gmt_17._busyOverlay_3w8gmt_53._light_3w8gmt_59, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._light_3w8gmt_59:hover, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._light_3w8gmt_59._disabled_3w8gmt_61, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._dark_3w8gmt_62._disabled_3w8gmt_61, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._dark_3w8gmt_62, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._dark_3w8gmt_62:focus, ._main_3w8gmt_17._busyOverlay_3w8gmt_53._dark_3w8gmt_62:hover {
  color: #0000;
}

._dark_3w8gmt_62:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #8473ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #8473ffcc;
  box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
}

._disabled_3w8gmt_61 {
  pointer-events: none;
}

._alignIconLeft_3w8gmt_78 {
  flex-direction: row;
}

._alignIconRight_3w8gmt_82 {
  flex-direction: row-reverse;
}

._sizeXs_3w8gmt_86 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_3w8gmt_1 ._sizeXs_3w8gmt_86 {
  letter-spacing: normal;
}

._sizeXs_3w8gmt_86 {
  border-radius: 4px;
  border-radius: var(--rs-button-xs-border-radius, var(--rs-button-border-radius, 4px));
}

._sizeS_3w8gmt_93 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_3w8gmt_1 ._sizeS_3w8gmt_93 {
  letter-spacing: normal;
}

._sizeS_3w8gmt_93 {
  border-radius: 24px;
  border-radius: var(--rs-button-s-border-radius, var(--rs-button-border-radius, 24px));
}

._sizeM_3w8gmt_100 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_3w8gmt_1 ._sizeM_3w8gmt_100 {
  letter-spacing: normal;
}

._sizeM_3w8gmt_100 {
  border-radius: 24px;
  border-radius: var(--rs-button-m-border-radius, var(--rs-button-border-radius, 24px));
}

._sizeL_3w8gmt_107 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  border-radius: 36px;
  border-radius: var(--rs-button-l-border-radius, var(--rs-button-border-radius, 36px));
}

._allowMultiline_3w8gmt_115 {
  white-space: normal;
}

._multiline_3w8gmt_119._sizeXs_3w8gmt_86 {
  border-radius: 4px;
  border-radius: var(--rs-button-xs-border-radius, var(--rs-button-border-radius, 4px));
}

._multiline_3w8gmt_119._sizeS_3w8gmt_93 {
  border-radius: 4px;
  border-radius: var(--rs-button-s-border-radius, var(--rs-button-border-radius, 4px));
}

._multiline_3w8gmt_119._sizeM_3w8gmt_100 {
  border-radius: 8px;
  border-radius: var(--rs-button-m-border-radius, var(--rs-button-border-radius, 8px));
}

._multiline_3w8gmt_119._sizeL_3w8gmt_107 {
  border-radius: 12px;
  border-radius: var(--rs-button-l-border-radius, var(--rs-button-border-radius, 12px));
}

._withoutText_3w8gmt_139, ._withIcon_3w8gmt_145 {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

._busyIcon_3w8gmt_151 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._flatRight_3w8gmt_159 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

._flatLeft_3w8gmt_164 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._modeClassic_3w8gmt_169 {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClassic_3w8gmt_169 ._busyIcon_3w8gmt_151 {
  color: #fff;
}

._modeClassic_3w8gmt_169:focus {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClassic_3w8gmt_169:hover {
    color: #fff;
    background: #6b57ffcc;
    background: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
  }
}

._modeClassic_3w8gmt_169:active {
  color: #fff;
  background: #6b57ff;
  background: var(--rs-color-primary-light-theme, #6b57ff);
}

._modeClassic_3w8gmt_169:active:focus:not(:hover)[data-focus-method="key"] {
  background: #6b57ffcc;
  background: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
}

._modeClassic_3w8gmt_169._disabled_3w8gmt_61 {
  color: #19191c80;
  background: #19191c33;
}

._modeClassic_3w8gmt_169._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #19191c80;
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62 {
  color: #fff;
  background: #8473ff;
  background: var(--rs-color-primary-dark-theme, #8473ff);
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62 ._busyIcon_3w8gmt_151 {
  color: #fff;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClassic_3w8gmt_169._dark_3w8gmt_62:hover {
    color: #fff;
    background: #8473ffcc;
    background: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc);
  }
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62:active {
  color: #fff;
  background: #8473ff;
  background: var(--rs-color-primary-dark-theme, #8473ff);
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62:active:focus:not(:hover)[data-focus-method="key"] {
  background: #8473ffcc;
  background: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc);
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62._disabled_3w8gmt_61 {
  color: #ffffff80;
  background: #fff3;
}

._modeClassic_3w8gmt_169._dark_3w8gmt_62._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #ffffff80;
}

._modeRock_3w8gmt_242 {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

._modeRock_3w8gmt_242 ._busyIcon_3w8gmt_151 {
  color: var(--rs-rock-button-text-color, #fff);
}

._modeRock_3w8gmt_242:focus {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeRock_3w8gmt_242:hover {
    color: var(--rs-rock-button-text-color, #fff);
    background-color: #19191ccc;
    background-color: var(--rs-rock-button-hover-bg-color, #19191ccc);
  }
}

._modeRock_3w8gmt_242:active {
  color: var(--rs-rock-button-text-color, #fff);
  background-color: #19191c;
  background-color: var(--rs-rock-button-bg-color, #19191c);
}

._modeRock_3w8gmt_242:active:focus:not(:hover)[data-focus-method="key"] {
  background-color: #19191ccc;
  background-color: var(--rs-rock-button-hover-bg-color, #19191ccc);
}

._modeRock_3w8gmt_242._disabled_3w8gmt_61 {
  color: var(--rs-rock-button-disabled-text-color, #19191c80);
  background-color: #19191c33;
  background-color: var(--rs-rock-button-disabled-bg-color, #19191c33);
}

._modeRock_3w8gmt_242._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: var(--rs-rock-button-disabled-text-color, #19191c80);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62 {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62 ._busyIcon_3w8gmt_151 {
  color: var(--rs-rock-button-text-color, #19191c);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62:focus {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeRock_3w8gmt_242._dark_3w8gmt_62:hover {
    color: var(--rs-rock-button-text-color, #19191c);
    background-color: #fffc;
    background-color: var(--rs-rock-button-hover-bg-color, #fffc);
  }
}

._modeRock_3w8gmt_242._dark_3w8gmt_62:active {
  color: var(--rs-rock-button-text-color, #19191c);
  background-color: #fff;
  background-color: var(--rs-rock-button-bg-color, #fff);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62:active:focus:not(:hover)[data-focus-method="key"] {
  background-color: #fffc;
  background-color: var(--rs-rock-button-hover-bg-color, #fffc);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62._disabled_3w8gmt_61 {
  color: var(--rs-rock-button-disabled-text-color, #ffffff80);
  background-color: #fff3;
  background-color: var(--rs-rock-button-disabled-bg-color, #fff3);
}

._modeRock_3w8gmt_242._dark_3w8gmt_62._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: var(--rs-rock-button-disabled-text-color, #ffffff80);
}

._modeTransparent_3w8gmt_320 {
  color: #fff;
  background-color: #19191c80;
}

._modeTransparent_3w8gmt_320 ._busyIcon_3w8gmt_151 {
  color: #fff;
}

._modeTransparent_3w8gmt_320:focus {
  color: #fff;
  background-color: #19191c80;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeTransparent_3w8gmt_320:hover {
    color: #fff;
    background: #19191ccc;
  }
}

._modeTransparent_3w8gmt_320:active {
  color: #fff;
  background: #19191c;
}

._modeTransparent_3w8gmt_320._disabled_3w8gmt_61 {
  color: #ffffff80;
  background: #19191c66;
}

._modeTransparent_3w8gmt_320._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #ffffff80;
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62 {
  color: #19191c;
  background: #ffffff80;
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62 ._busyIcon_3w8gmt_151 {
  color: #19191c;
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62:focus {
  color: #19191c;
  background: #ffffff80;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeTransparent_3w8gmt_320._dark_3w8gmt_62:hover {
    color: #19191c;
    background: #fffc;
  }
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62:active {
  color: #19191c;
  background: #fff;
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62._disabled_3w8gmt_61 {
  color: #19191c80;
  background: #fff6;
}

._modeTransparent_3w8gmt_320._dark_3w8gmt_62._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #19191c80;
}

._modeOutline_3w8gmt_390 {
  color: #19191c;
  border-style: solid;
  border-color: #19191c4d;
  position: relative;
}

._modeOutline_3w8gmt_390 ._busyIcon_3w8gmt_151 {
  color: #19191c;
}

._modeOutline_3w8gmt_390:focus {
  color: #19191c;
  border-color: #19191c4d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeOutline_3w8gmt_390:hover {
    color: #19191c;
    background: #19191c1a;
    border-color: #19191c4d;
  }
}

._modeOutline_3w8gmt_390:active {
  color: #19191c;
  background: #19191c1a;
  border-color: #19191c;
}

._modeOutline_3w8gmt_390._disabled_3w8gmt_61 {
  color: #19191c80;
  background: none;
  border-color: #19191c33;
}

._modeOutline_3w8gmt_390._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #19191c80;
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62 {
  color: #fff;
  border-color: #ffffff4d;
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62 ._busyIcon_3w8gmt_151 {
  color: #fff;
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62:focus {
  color: #fff;
  border-color: #fff6;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeOutline_3w8gmt_390._dark_3w8gmt_62:hover {
    color: #fff;
    background: #ffffff1a;
    border-color: #fff6;
  }
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62:active {
  color: #fff;
  background: #ffffff1a;
  border-color: #fff;
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62._disabled_3w8gmt_61 {
  color: #ffffff80;
  background: none;
  border-color: #fff3;
}

._modeOutline_3w8gmt_390._dark_3w8gmt_62._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #ffffff80;
}

._modeClear_3w8gmt_479 {
  color: #19191c;
  background: none;
}

._modeClear_3w8gmt_479 ._busyIcon_3w8gmt_151 {
  color: #19191c;
}

._modeClear_3w8gmt_479:focus {
  color: #19191c;
  background: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClear_3w8gmt_479:hover {
    color: #19191c;
    background: #19191c1a;
  }
}

._modeClear_3w8gmt_479:active {
  color: #19191c;
  background: #19191c33;
}

._modeClear_3w8gmt_479._disabled_3w8gmt_61 {
  color: #19191c80;
  background: none;
}

._modeClear_3w8gmt_479._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #19191c80;
}

._modeClear_3w8gmt_479._highlighted_3w8gmt_514 {
  color: #19191c;
  background: #19191c1a;
}

._modeClear_3w8gmt_479._dark_3w8gmt_62 {
  color: #fff;
  background: none;
}

._modeClear_3w8gmt_479._dark_3w8gmt_62 ._busyIcon_3w8gmt_151 {
  color: #fff;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._modeClear_3w8gmt_479._dark_3w8gmt_62:hover {
    color: #fff;
    background: #ffffff1a;
  }
}

._modeClear_3w8gmt_479._dark_3w8gmt_62:active {
  color: #fff;
  background: #fff3;
}

._modeClear_3w8gmt_479._dark_3w8gmt_62._disabled_3w8gmt_61, ._modeClear_3w8gmt_479._dark_3w8gmt_62._disabled_3w8gmt_61 ._busyIcon_3w8gmt_151 {
  color: #ffffff80;
}

._modeClear_3w8gmt_479._dark_3w8gmt_62._highlighted_3w8gmt_514 {
  color: #fff;
  background: #ffffff1a;
}

._sizeXs_3w8gmt_86 {
  border-width: 0;
  padding: 2px 16px;
}

._sizeS_3w8gmt_93 {
  border-width: 0;
  padding: 6px 16px;
}

._sizeM_3w8gmt_100 {
  border-width: 0;
  padding: 8px 24px;
}

._sizeL_3w8gmt_107 {
  border-width: 0;
  padding: 12px 32px;
}

._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 {
  border-width: 0;
  padding: 2px;
}

._sizeS_3w8gmt_93._withoutText_3w8gmt_139 {
  border-width: 0;
  padding: 6px;
}

._sizeM_3w8gmt_100._withoutText_3w8gmt_139 {
  border-width: 0;
  padding: 8px;
}

._sizeL_3w8gmt_107._withoutText_3w8gmt_139 {
  border-width: 0;
  padding: 12px;
}

._sizeXs_3w8gmt_86._modeOutline_3w8gmt_390 {
  border-width: 1px;
  padding: 1px 15px;
}

._sizeS_3w8gmt_93._modeOutline_3w8gmt_390 {
  border-width: 1px;
  padding: 5px 15px;
}

._sizeM_3w8gmt_100._modeOutline_3w8gmt_390 {
  border-width: 1px;
  padding: 7px 23px;
}

._sizeL_3w8gmt_107._modeOutline_3w8gmt_390 {
  border-width: 1px;
  padding: 11px 31px;
}

._sizeXs_3w8gmt_86._modeOutline_3w8gmt_390._withoutText_3w8gmt_139 {
  border-width: 1px;
  padding: 1px;
}

._sizeS_3w8gmt_93._modeOutline_3w8gmt_390._withoutText_3w8gmt_139 {
  border-width: 1px;
  padding: 5px;
}

._sizeM_3w8gmt_100._modeOutline_3w8gmt_390._withoutText_3w8gmt_139 {
  border-width: 1px;
  padding: 7px;
}

._sizeL_3w8gmt_107._modeOutline_3w8gmt_390._withoutText_3w8gmt_139 {
  border-width: 1px;
  padding: 11px;
}

._sizeXs_3w8gmt_86._modeOutline_3w8gmt_390:active {
  border-width: 2px;
  padding: 0 14px;
}

._sizeS_3w8gmt_93._modeOutline_3w8gmt_390:active {
  border-width: 2px;
  padding: 4px 14px;
}

._sizeM_3w8gmt_100._modeOutline_3w8gmt_390:active {
  border-width: 2px;
  padding: 6px 22px;
}

._sizeL_3w8gmt_107._modeOutline_3w8gmt_390:active {
  border-width: 2px;
  padding: 10px 30px;
}

._sizeXs_3w8gmt_86._modeOutline_3w8gmt_390._withoutText_3w8gmt_139:active {
  border-width: 2px;
  padding: 0;
}

._sizeS_3w8gmt_93._modeOutline_3w8gmt_390._withoutText_3w8gmt_139:active {
  border-width: 2px;
  padding: 4px;
}

._sizeM_3w8gmt_100._modeOutline_3w8gmt_390._withoutText_3w8gmt_139:active {
  border-width: 2px;
  padding: 6px;
}

._sizeL_3w8gmt_107._modeOutline_3w8gmt_390._withoutText_3w8gmt_139:active {
  border-width: 2px;
  padding: 10px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeXs_3w8gmt_86 {
  margin-left: -16px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeXs_3w8gmt_86 {
  margin-right: -16px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeXs_3w8gmt_86 {
  margin-top: -2px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeXs_3w8gmt_86 {
  margin-bottom: -2px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeS_3w8gmt_93 {
  margin-left: -16px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeS_3w8gmt_93 {
  margin-right: -16px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeS_3w8gmt_93 {
  margin-top: -6px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeS_3w8gmt_93 {
  margin-bottom: -6px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeM_3w8gmt_100 {
  margin-left: -24px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeM_3w8gmt_100 {
  margin-right: -24px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeM_3w8gmt_100 {
  margin-top: -8px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeM_3w8gmt_100 {
  margin-bottom: -8px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeL_3w8gmt_107 {
  margin-left: -32px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeL_3w8gmt_107 {
  margin-right: -32px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeL_3w8gmt_107 {
  margin-top: -12px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeL_3w8gmt_107 {
  margin-bottom: -12px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 {
  margin-left: -2px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 {
  margin-right: -2px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 {
  margin-top: -2px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 {
  margin-bottom: -2px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeS_3w8gmt_93._withoutText_3w8gmt_139 {
  margin-left: -6px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeS_3w8gmt_93._withoutText_3w8gmt_139 {
  margin-right: -6px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeS_3w8gmt_93._withoutText_3w8gmt_139 {
  margin-top: -6px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeS_3w8gmt_93._withoutText_3w8gmt_139 {
  margin-bottom: -6px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeM_3w8gmt_100._withoutText_3w8gmt_139 {
  margin-left: -8px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeM_3w8gmt_100._withoutText_3w8gmt_139 {
  margin-right: -8px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeM_3w8gmt_100._withoutText_3w8gmt_139 {
  margin-top: -8px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeM_3w8gmt_100._withoutText_3w8gmt_139 {
  margin-bottom: -8px;
}

._modeClear_3w8gmt_479._shiftedLeft_3w8gmt_565._sizeL_3w8gmt_107._withoutText_3w8gmt_139 {
  margin-left: -12px;
}

._modeClear_3w8gmt_479._shiftedRight_3w8gmt_566._sizeL_3w8gmt_107._withoutText_3w8gmt_139 {
  margin-right: -12px;
}

._modeClear_3w8gmt_479._shiftedTop_3w8gmt_567._sizeL_3w8gmt_107._withoutText_3w8gmt_139 {
  margin-top: -12px;
}

._modeClear_3w8gmt_479._shiftedBottom_3w8gmt_568._sizeL_3w8gmt_107._withoutText_3w8gmt_139 {
  margin-bottom: -12px;
}

._icon_3w8gmt_570 {
  fill: currentColor;
}

._sizeXs_3w8gmt_86 ._icon_3w8gmt_570 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeS_3w8gmt_93 ._icon_3w8gmt_570 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeM_3w8gmt_100 ._icon_3w8gmt_570 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  margin-left: -2px;
  margin-right: 8px;
}

._sizeL_3w8gmt_107 ._icon_3w8gmt_570 {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-left: -2px;
  margin-right: 16px;
}

._sizeXs_3w8gmt_86._alignIconRight_3w8gmt_82 ._icon_3w8gmt_570 {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeS_3w8gmt_93._alignIconRight_3w8gmt_82 ._icon_3w8gmt_570 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeM_3w8gmt_100._alignIconRight_3w8gmt_82 ._icon_3w8gmt_570 {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  margin-left: 8px;
  margin-right: -2px;
}

._sizeL_3w8gmt_107._alignIconRight_3w8gmt_82 ._icon_3w8gmt_570 {
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  margin-left: 16px;
  margin-right: -2px;
}

._withoutText_3w8gmt_139 ._icon_3w8gmt_570, ._withoutText_3w8gmt_139._alignIconRight_3w8gmt_82 ._icon_3w8gmt_570 {
  margin: 0;
}

._sizeXs_3w8gmt_86 ._icon_3w8gmt_570 {
  margin-top: 2px;
}

._sizeXs_3w8gmt_86._withoutText_3w8gmt_139 ._icon_3w8gmt_570 {
  margin-top: 0;
}

.rs-font-smoothing {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-super-hero {
  letter-spacing: -.02em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 142px;
  font-size: var(--rs-super-hero-font-size, 142px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 142px;
  line-height: var(--rs-super-hero-line-height, 142px);
}

@media screen and (width <= 1000px) {
  .rs-super-hero {
    --rs-super-hero-font-size: var(--rs-hero-font-size, 72px);
    --rs-super-hero-line-height: var(--rs-hero-line-height, 72px);
    letter-spacing: -.005em;
  }
}

@media screen and (width <= 640px) {
  .rs-super-hero {
    --rs-super-hero-font-size: var(--rs-hero-font-size-mobile, 42px);
    --rs-super-hero-line-height: var(--rs-hero-line-height-mobile, 50px);
    letter-spacing: -.001em;
  }
}

.rs-reset-letter-spacing .rs-super-hero {
  letter-spacing: normal;
}

.rs-super-hero {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-super-hero_theme_dark, [class*="_theme_dark"] .rs-super-hero_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-digits-1 {
  letter-spacing: -.005em;
  font-variant-numeric: tabular-nums;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 69px;
  font-size: var(--rs-digits-1-font-size, 69px);
  font-weight: 320;
  font-weight: var(--rs-font-weight-light, 320);
  line-height: 71px;
  line-height: var(--rs-digits-1-line-height, 71px);
}

@media screen and (width <= 640px) {
  .rs-digits-1 {
    --rs-digits-1-font-size: var(--rs-digits-2-font-size, 40px);
    --rs-digits-1-line-height: var(--rs-digits-2-line-height, 44px);
  }
}

.rs-reset-letter-spacing .rs-digits-1 {
  letter-spacing: normal;
}

.rs-digits-1 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-digits-1_theme_dark, [class*="_theme_dark"] .rs-digits-1_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-digits-2 {
  letter-spacing: -.005em;
  font-variant-numeric: tabular-nums;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 40px;
  font-size: var(--rs-digits-2-font-size, 40px);
  font-weight: 320;
  font-weight: var(--rs-font-weight-light, 320);
  line-height: 44px;
  line-height: var(--rs-digits-2-line-height, 44px);
}

.rs-reset-letter-spacing .rs-digits-2 {
  letter-spacing: normal;
}

.rs-digits-2 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-digits-2_theme_dark, [class*="_theme_dark"] .rs-digits-2_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-hero {
  letter-spacing: -.005em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 72px;
  font-size: var(--rs-hero-font-size, 72px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 72px;
  line-height: var(--rs-hero-line-height, 72px);
}

@media screen and (width <= 640px) {
  .rs-hero {
    --rs-hero-font-size: var(--rs-hero-font-size-mobile, 42px);
    --rs-hero-line-height: var(--rs-hero-font-size-mobile, 50px);
    letter-spacing: -.001em;
  }
}

.rs-reset-letter-spacing .rs-hero {
  letter-spacing: normal;
}

.rs-hero {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-hero_theme_dark, [class*="_theme_dark"] .rs-hero_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-subtitle-1 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 39px;
  font-size: var(--rs-subtitle-1-font-size, 39px);
  font-weight: 320;
  font-weight: var(--rs-font-weight-light, 320);
  line-height: 49px;
  line-height: var(--rs-subtitle-1-line-height, 49px);
}

@media screen and (width <= 640px) {
  .rs-subtitle-1 {
    --rs-subtitle-1-font-size: var(--rs-subtitle-2-font-size-mobile, 23px);
    --rs-subtitle-1-line-height: var(--rs-subtitle-2-line-height-mobile, 30px);
  }
}

.rs-subtitle-1 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-subtitle-1_theme_dark, [class*="_theme_dark"] .rs-subtitle-1_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-h1 {
  letter-spacing: -.001em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 43px;
  font-size: var(--rs-h1-font-size, 43px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 49px;
  line-height: var(--rs-h1-line-height, 49px);
}

@media screen and (width <= 640px) {
  .rs-h1 {
    --rs-h1-font-size: var(--rs-h1-font-size-mobile, 35px);
    --rs-h1-line-height: var(--rs-h1-line-height-mobile, 39px);
    letter-spacing: normal;
  }
}

.rs-h1 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-h1_theme_dark, [class*="_theme_dark"] .rs-h1_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-subtitle-2 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 29px;
  font-size: var(--rs-subtitle-2-font-size, 29px);
  font-weight: 320;
  font-weight: var(--rs-font-weight-light, 320);
  line-height: 39px;
  line-height: var(--rs-subtitle-2-line-height, 39px);
}

@media screen and (width <= 640px) {
  .rs-subtitle-2 {
    --rs-subtitle-2-font-size: var(--rs-subtitle-2-font-size-mobile, 23px);
    --rs-subtitle-2-line-height: var(--rs-subtitle-2-line-height-mobile, 30px);
  }
}

.rs-subtitle-2 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-subtitle-2_theme_dark, [class*="_theme_dark"] .rs-subtitle-2_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-h2 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-headers, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 35px;
  font-size: var(--rs-h2-font-size, 35px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 42px;
  line-height: var(--rs-h2-line-height, 42px);
}

@media screen and (width <= 640px) {
  .rs-h2 {
    --rs-h2-font-size: var(--rs-h2-font-size-mobile, 28px);
    --rs-h2-line-height: var(--rs-h2-line-height-mobile, 32px);
  }
}

.rs-h2 {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-h2_theme_dark, [class*="_theme_dark"] .rs-h2_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-h3 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-h3-font-size, 20px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 28px;
  line-height: var(--rs-h3-line-height, 28px);
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-h3_theme_dark, [class*="_theme_dark"] .rs-h3_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-1 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-1_paragraph-offset-auto > p:not(:first-of-type) {
  margin-top: 32px;
  margin-top: calc(var(--rs-flow-unit, 8px) * 4);
}

.rs-text-1_theme_dark, [class*="_theme_dark"] .rs-text-1_theme_auto {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-1_hardness_hard {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-text-1_hardness_average {
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-1_hardness_pale {
  color: var(--rs-typography-color-pale, #19191c80);
}

.rs-text-1_theme_dark.rs-text-1_hardness_hard, [class*="_theme_dark"] .rs-text-1_theme_auto.rs-text-1_hardness_hard {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-1_theme_dark.rs-text-1_hardness_average, [class*="_theme_dark"] .rs-text-1_theme_auto.rs-text-1_hardness_average {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-1_theme_dark.rs-text-1_hardness_pale, [class*="_theme_dark"] .rs-text-1_theme_auto.rs-text-1_hardness_pale {
  color: var(--rs-typography-color-pale, #ffffff80);
}

.rs-h4 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-h4-font-size, 16px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 24px;
  line-height: var(--rs-h4-line-height, 24px);
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-h4_theme_dark, [class*="_theme_dark"] .rs-h4_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-2 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

.rs-reset-letter-spacing .rs-text-2 {
  letter-spacing: normal;
}

.rs-text-2 {
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-2_paragraph-offset-auto > p:not(:first-of-type) {
  margin-top: 16px;
  margin-top: calc(var(--rs-flow-unit, 8px) * 2);
}

.rs-text-2_theme_dark, [class*="_theme_dark"] .rs-text-2_theme_auto {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-2_hardness_hard {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-text-2_hardness_average {
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-2_hardness_pale {
  color: var(--rs-typography-color-pale, #19191c80);
}

.rs-text-2_theme_dark.rs-text-2_hardness_hard, [class*="_theme_dark"] .rs-text-2_theme_auto.rs-text-2_hardness_hard {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-2_theme_dark.rs-text-2_hardness_average, [class*="_theme_dark"] .rs-text-2_theme_auto.rs-text-2_hardness_average {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-2_theme_dark.rs-text-2_hardness_pale, [class*="_theme_dark"] .rs-text-2_theme_auto.rs-text-2_hardness_pale {
  color: var(--rs-typography-color-pale, #ffffff80);
}

.rs-h5 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-h5-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 20px;
  line-height: var(--rs-h5-line-height, 20px);
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-h5_theme_dark, [class*="_theme_dark"] .rs-h5_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-overline {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--rs-typography-color-hard, #19191c);
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-overline-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 18px;
  line-height: var(--rs-overline-line-height, 18px);
}

.rs-overline_theme_dark, [class*="_theme_dark"] .rs-overline_theme_auto {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-3 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

.rs-reset-letter-spacing .rs-text-3 {
  letter-spacing: normal;
}

.rs-text-3 {
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-3_paragraph-offset-auto > p:not(:first-of-type) {
  margin-top: 8px;
  margin-top: var(--rs-flow-unit, 8px);
}

.rs-text-3_theme_dark, [class*="_theme_dark"] .rs-text-3_theme_auto {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-3_hardness_hard {
  color: var(--rs-typography-color-hard, #19191c);
}

.rs-text-3_hardness_average {
  color: var(--rs-typography-color-average, #19191cb3);
}

.rs-text-3_hardness_pale {
  color: var(--rs-typography-color-pale, #19191c80);
}

.rs-text-3_theme_dark.rs-text-3_hardness_hard, [class*="_theme_dark"] .rs-text-3_theme_auto.rs-text-3_hardness_hard {
  color: var(--rs-typography-color-hard, #fff);
}

.rs-text-3_theme_dark.rs-text-3_hardness_average, [class*="_theme_dark"] .rs-text-3_theme_auto.rs-text-3_hardness_average {
  color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-text-3_theme_dark.rs-text-3_hardness_pale, [class*="_theme_dark"] .rs-text-3_theme_auto.rs-text-3_hardness_pale {
  color: var(--rs-typography-color-pale, #ffffff80);
}

.rs-ul {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  padding-left: 0;
  list-style-type: none;
}

.rs-ul > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ul > li {
    margin-top: 12px;
  }
}

.rs-ul > li {
  padding-left: 22px;
  list-style-type: none;
  position: relative;
}

.rs-ul > li:before {
  line-height: inherit;
  color: inherit;
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.rs-ul_offset-items_8 > li {
  margin-top: 8px;
}

@media screen and (width <= 640px) {
  .rs-ul_offset-items_8 > li {
    margin-top: 8px;
  }
}

.rs-ul_offset-items_12 > li {
  margin-top: 12px;
}

@media screen and (width <= 640px) {
  .rs-ul_offset-items_12 > li {
    margin-top: 12px;
  }
}

.rs-ul_offset-items_16 > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ul_offset-items_16 > li {
    margin-top: 12px;
  }
}

.rs-ul_offset-items_24 > li {
  margin-top: 24px;
}

@media screen and (width <= 640px) {
  .rs-ul_offset-items_24 > li {
    margin-top: 16px;
  }
}

.rs-ul:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ul:not([class*="_offset-items_"]) > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ul:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ul:not([class*="_offset-items_"]) > li {
    margin-top: 12px;
  }
}

.rs-ul:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ul:not([class*="_offset-items_"]) > li {
  margin-top: 24px;
}

@media screen and (width <= 640px) {
  .rs-ul:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ul:not([class*="_offset-items_"]) > li {
    margin-top: 16px;
  }
}

.rs-ul:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ul:not([class*="_offset-items_"]) > li {
  margin-top: 8px;
}

@media screen and (width <= 640px) {
  .rs-ul:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ul:not([class*="_offset-items_"]) > li {
    margin-top: 8px;
  }
}

.rs-ul.rs-text-1 > li, .rs-text-1 .rs-ul > li {
  padding-left: 26px;
}

.rs-ul.rs-text-3 > li, .rs-text-3 .rs-ul > li {
  padding-left: 28px;
}

.rs-ul > li:before {
  content: "•";
}

.rs-ol {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  padding-left: 0;
  list-style-type: none;
}

.rs-ol > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ol > li {
    margin-top: 12px;
  }
}

.rs-ol > li {
  padding-left: 22px;
  list-style-type: none;
  position: relative;
}

.rs-ol > li:before {
  line-height: inherit;
  color: inherit;
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.rs-ol_offset-items_8 > li {
  margin-top: 8px;
}

@media screen and (width <= 640px) {
  .rs-ol_offset-items_8 > li {
    margin-top: 8px;
  }
}

.rs-ol_offset-items_12 > li {
  margin-top: 12px;
}

@media screen and (width <= 640px) {
  .rs-ol_offset-items_12 > li {
    margin-top: 12px;
  }
}

.rs-ol_offset-items_16 > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ol_offset-items_16 > li {
    margin-top: 12px;
  }
}

.rs-ol_offset-items_24 > li {
  margin-top: 24px;
}

@media screen and (width <= 640px) {
  .rs-ol_offset-items_24 > li {
    margin-top: 16px;
  }
}

.rs-ol:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ol:not([class*="_offset-items_"]) > li {
  margin-top: 16px;
}

@media screen and (width <= 640px) {
  .rs-ol:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ol:not([class*="_offset-items_"]) > li {
    margin-top: 12px;
  }
}

.rs-ol:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ol:not([class*="_offset-items_"]) > li {
  margin-top: 24px;
}

@media screen and (width <= 640px) {
  .rs-ol:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ol:not([class*="_offset-items_"]) > li {
    margin-top: 16px;
  }
}

.rs-ol:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ol:not([class*="_offset-items_"]) > li {
  margin-top: 8px;
}

@media screen and (width <= 640px) {
  .rs-ol:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ol:not([class*="_offset-items_"]) > li {
    margin-top: 8px;
  }
}

.rs-ol {
  counter-reset: ol-counter;
}

.rs-ol.rs-text-2 > li, .rs-text-2 .rs-ol > li {
  padding-left: 26px;
}

.rs-ol.rs-text-1 > li, .rs-text-1 .rs-ol > li {
  padding-left: 32px;
}

.rs-ol.rs-text-3 > li, .rs-text-3 .rs-ol > li {
  padding-left: 21px;
}

.rs-ol > li:before {
  content: counter(ol-counter) ".";
  counter-increment: ol-counter;
}

.rs-ol_alpha_lower > li:before {
  content: counter(ol-counter, lower-alpha) ".";
}

.rs-ol_alpha_upper > li:before {
  content: counter(ol-counter, upper-alpha) ".";
}

.rs-pre {
  box-sizing: border-box;
  word-wrap: normal;
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-family: var(--rs-font-family-mono, "JetBrains Mono", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace);
  color: var(--rs-typography-pre-color, #19191c);
  background-color: #19191c1a;
  background-color: var(--rs-typography-pre-bg-color, #19191c1a);
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  display: block;
  overflow: auto;
}

.rs-pre > code, .rs-pre > .rs-code {
  white-space: pre;
  word-break: normal;
  border: 0;
}

.rs-pre code, .rs-pre .rs-code {
  word-wrap: normal;
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-family: var(--rs-font-family-mono, "JetBrains Mono", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace);
  line-height: inherit;
  max-width: none;
  margin: 0;
  display: inline;
  overflow: visible;
  color: inherit !important;
  background: none !important;
  padding: 0 !important;
}

.rs-pre_theme_dark, [class*="_theme_dark"] .rs-pre_theme_auto {
  color: var(--rs-typography-pre-color, #ffffffb3);
  background-color: #ffffff1a;
  background-color: var(--rs-typography-pre-bg-color, #ffffff1a);
}

.rs-code {
  box-sizing: border-box;
  color: var(--rs-typography-pre-color, #19191c);
  background-color: #19191c1a;
  background-color: var(--rs-typography-pre-bg-color, #19191c1a);
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-family: var(--rs-font-family-mono, "JetBrains Mono", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace);
  font-size: 90%;
  line-height: inherit;
  border-radius: 4px;
  padding: .11111em .44444em;
}

.rs-code_theme_dark, [class*="_theme_dark"] .rs-code_theme_auto {
  color: var(--rs-typography-pre-color, #ffffffb3);
  background-color: #ffffff1a;
  background-color: var(--rs-typography-pre-bg-color, #ffffff1a);
}

[class*="rs-text"] code:not([class*="rs-code"]) {
  box-sizing: border-box;
  color: var(--rs-typography-pre-color, #19191c);
  background-color: #19191c1a;
  background-color: var(--rs-typography-pre-bg-color, #19191c1a);
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-family: var(--rs-font-family-mono, "JetBrains Mono", SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace);
  font-size: 90%;
  line-height: inherit;
  border-radius: 4px;
  padding: .11111em .44444em;
}

[class*="_theme_dark"] code:not([class*="rs-code"]) {
  color: var(--rs-typography-pre-color, #ffffffb3);
  background-color: #ffffff1a;
  background-color: var(--rs-typography-pre-bg-color, #ffffff1a);
}

.rs-link {
  --rs-link-color: var(--rs-typography-color-hard, #19191c);
  color: var(--rs-link-color);
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  outline: none;
  padding-bottom: 1px;
  text-decoration: none;
}

.rs-link:active, .rs-link:focus {
  color: var(--rs-link-color);
}

.rs-link:hover {
  text-decoration: none;
}

.rs-link:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #8979ff;
  -webkit-box-shadow: var(--rs-color-primary-dim-light-theme, #8979ff) 0 0 0 4px;
  box-shadow: 0 0 0 4px #8979ff;
  box-shadow: var(--rs-color-primary-dim-light-theme, #8979ff) 0 0 0 4px;
}

[class*="rs-super-hero"] .rs-link {
  border-bottom-width: 4px;
}

@media screen and (width <= 1000px) {
  [class*="rs-super-hero"] .rs-link {
    border-bottom-width: 3px;
  }
}

@media screen and (width <= 640px) {
  [class*="rs-super-hero"] .rs-link {
    border-bottom-width: 2px;
  }
}

[class*="rs-hero"] .rs-link {
  border-bottom-width: 3px;
}

@media screen and (width <= 640px) {
  [class*="rs-hero"] .rs-link {
    border-bottom-width: 2px;
  }
}

[class*="rs-h1"] .rs-link, [class*="rs-h2"] .rs-link, [class*="rs-h3"] .rs-link, [class*="rs-h4"] .rs-link, [class*="rs-h5"] .rs-link, [class*="rs-overline"] .rs-link, [class*="rs-subtitle-1"] .rs-link, [class*="rs-subtitle-2"] .rs-link {
  border-bottom-width: 2px;
}

.rs-link_external:after {
  content: "↗";
  margin-left: 0;
  margin-right: 0;
  display: inline;
}

.rs-link_mode_classic {
  --rs-link-color-hover: var(--rs-typography-color-hard, #19191c);
  --rs-underline-color: var(--rs-typography-classic-link-underline-color, #19191c66);
  --rs-underline-color-hover: var(--rs-typography-color-hard, #19191c);
  border-bottom-color: #19191c66;
  border-bottom-color: var(--rs-underline-color);
}

.rs-link_mode_classic:hover {
  color: var(--rs-link-color-hover);
  border-bottom-color: #19191c;
  border-bottom-color: var(--rs-underline-color-hover);
  border-bottom-width: 2px;
  padding-bottom: 0;
}

[class*="rs-super-hero"] .rs-link_mode_classic:hover {
  border-bottom-width: 5px;
}

@media screen and (width <= 1000px) {
  [class*="rs-super-hero"] .rs-link_mode_classic:hover {
    border-bottom-width: 4px;
  }
}

@media screen and (width <= 640px) {
  [class*="rs-super-hero"] .rs-link_mode_classic:hover {
    border-bottom-width: 3px;
  }
}

[class*="rs-hero"] .rs-link_mode_classic:hover {
  border-bottom-width: 4px;
}

@media screen and (width <= 640px) {
  [class*="rs-hero"] .rs-link_mode_classic:hover {
    border-bottom-width: 3px;
  }
}

[class*="rs-h1"] .rs-link_mode_classic:hover, [class*="rs-h2"] .rs-link_mode_classic:hover, [class*="rs-h3"] .rs-link_mode_classic:hover, [class*="rs-h4"] .rs-link_mode_classic:hover, [class*="rs-h5"] .rs-link_mode_classic:hover, [class*="rs-overline"] .rs-link_mode_classic:hover, [class*="rs-subtitle-1"] .rs-link_mode_classic:hover, [class*="rs-subtitle-2"] .rs-link_mode_classic:hover {
  border-bottom-width: 3px;
}

.rs-link_mode_rock {
  --rs-link-color-hover: var(--rs-typography-rock-link-hover-color, #fff);
  --rs-underline-color: var(--rs-typography-color-pale, #19191c);
  border-bottom-color: #19191c;
  border-bottom-color: var(--rs-underline-color);
  background-image: linear-gradient(#19191c 100%, #0000 0);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, var(--rs-underline-color)), color-stop(0, transparent));
  background-image: linear-gradient(to bottom, var(--rs-underline-color) 100%, transparent 0);
  background-position: 0 calc(100% + 4px);
  background-repeat: repeat-x;
  background-size: auto 0;
  transition: background-size .3s, color .3s;
}

@media (-ms-high-contrast: none) {
  .rs-link_mode_rock {
    transition: none;
  }
}

.rs-link_mode_rock:hover {
  color: var(--rs-link-color-hover);
  background-size: auto calc(100% + 4px);
}

.rs-link_mode_standalone {
  --rs-link-standalone-border-offset: 1.15em;
  --rs-link-color-hover: var(--rs-typography-color-hard, #19191c);
  --rs-underline-color: transparent;
  --rs-underline-color-hover: var(--rs-typography-color-hard, #19191c);
  border-bottom-color: #0000;
  border-bottom-color: var(--rs-underline-color);
}

.rs-link_mode_standalone:after {
  content: "→";
  margin-left: .15em;
  margin-right: .15em;
  transition: margin-right .3s, margin-left .3s;
  display: inline;
}

.rs-link_mode_standalone:hover {
  color: var(--rs-link-color-hover);
  border-bottom-color: #19191c;
  border-bottom-color: var(--rs-underline-color-hover);
  -o-border-image: linear-gradient(to right, #19191c calc(100% - 1.1em), #0000 1.1em) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(#19191c), color-stop(1.1em, transparent)) 1;
  border-image: linear-gradient(to right, #19191c calc(100% - 1.1em), #0000 1.1em) 1;
  -o-border-image: linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(var(--rs-underline-color-hover)), to(transparent)) 1;
  border-image: linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
}

.rs-link_mode_standalone:hover:after {
  margin-left: .3em;
  margin-right: 0;
}

[class*="rs-super-hero"] .rs-link_mode_standalone, [class*="rs-hero"] .rs-link_mode_standalone, [class*="rs-h1"] .rs-link_mode_standalone, [class*="rs-h2"] .rs-link_mode_standalone {
  --rs-link-standalone-border-offset: 1.12em;
}

[class*="rs-overline"] .rs-link_mode_standalone {
  --rs-link-standalone-border-offset: 1.3em;
}

.rs-link_mode_standalone.rs-link_external {
  --rs-link-standalone-border-offset: 1.02em;
}

.rs-link_mode_standalone.rs-link_external:after {
  content: "↗";
}

[class*="rs-super-hero"] .rs-link_mode_standalone.rs-link_external, [class*="rs-hero"] .rs-link_mode_standalone.rs-link_external, [class*="rs-h1"] .rs-link_mode_standalone.rs-link_external, [class*="rs-h2"] .rs-link_mode_standalone.rs-link_external {
  --rs-link-standalone-border-offset: 1em;
}

[class*="rs-overline"] .rs-link_mode_standalone.rs-link_external {
  --rs-link-standalone-border-offset: 1.15em;
}

.rs-link_mode_clear {
  --rs-link-color: var(--rs-typography-color-average, #19191cb3);
  --rs-link-color-hover: var(--rs-typography-color-hard, #19191c);
  --rs-underline-color: transparent;
  --rs-underline-color-hover: var(--rs-typography-color-hard, #19191c);
  border-bottom-color: #0000;
  border-bottom-color: var(--rs-underline-color);
  color: var(--rs-link-color);
}

.rs-link_mode_clear:hover {
  border-bottom-color: #19191c;
  border-bottom-color: var(--rs-underline-color-hover);
  color: var(--rs-link-color-hover);
}

.rs-link_hardness_hard {
  --rs-link-color: var(--rs-typography-color-hard, #19191c);
}

.rs-link_hardness_average {
  --rs-link-color: var(--rs-typography-color-average, #19191cb3);
}

.rs-link_hardness_pale {
  --rs-link-color: var(--rs-typography-color-pale, #19191c80);
}

.rs-link_theme_dark {
  --rs-link-color: var(--rs-typography-color-hard, #fff);
  color: var(--rs-link-color);
}

.rs-link_theme_dark:active, .rs-link_theme_dark:focus {
  color: var(--rs-link-color);
}

.rs-link_theme_dark:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #6f61d2;
  -webkit-box-shadow: var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6f61d2;
  box-shadow: var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
}

[class*="_theme_dark"] .rs-link_theme_auto {
  --rs-link-color: var(--rs-typography-color-hard, #fff);
  color: var(--rs-link-color);
}

[class*="_theme_dark"] .rs-link_theme_auto:active, [class*="_theme_dark"] .rs-link_theme_auto:focus {
  color: var(--rs-link-color);
}

[class*="_theme_dark"] .rs-link_theme_auto:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #6f61d2;
  -webkit-box-shadow: var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6f61d2;
  box-shadow: var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
}

.rs-link_theme_dark.rs-link_mode_classic, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_classic {
  --rs-link-color-hover: var(--rs-typography-color-hard, #fff);
  --rs-underline-color: var(--rs-typography-classic-link-underline-color, #fff6);
  --rs-underline-color-hover: var(--rs-typography-color-hard, #fff);
  border-bottom-color: #fff6;
  border-bottom-color: var(--rs-underline-color);
}

.rs-link_theme_dark.rs-link_mode_classic:hover, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_classic:hover {
  color: var(--rs-link-color-hover);
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-underline-color-hover);
}

.rs-link_theme_dark.rs-link_mode_rock, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_rock {
  --rs-link-color-hover: var(--rs-typography-rock-link-hover-color, #19191c);
  --rs-underline-color: var(--rs-typography-color-hard, #fff);
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-underline-color);
  background-image: linear-gradient(#fff 100%, #0000 0);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, var(--rs-underline-color)), color-stop(0, transparent));
  background-image: linear-gradient(to bottom, var(--rs-underline-color) 100%, transparent 0);
}

.rs-link_theme_dark.rs-link_mode_rock:hover, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_rock:hover {
  color: var(--rs-link-color-hover);
}

.rs-link_theme_dark.rs-link_mode_standalone, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_standalone {
  --rs-link-color-hover: var(--rs-typography-color-hard, #fff);
  --rs-underline-color-hover: var(--rs-typography-color-hard, #fff);
  background-image: none;
  border-bottom-color: #0000;
}

.rs-link_theme_dark.rs-link_mode_standalone:hover, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_standalone:hover {
  color: var(--rs-link-color-hover);
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-underline-color-hover);
  -o-border-image: linear-gradient(to right, #fff calc(100% - 1.3em), #0000 1.3em) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(1.3em, transparent)) 1;
  border-image: linear-gradient(to right, #fff calc(100% - 1.3em), #0000 1.3em) 1;
  -o-border-image: linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(var(--rs-underline-color-hover)), to(transparent)) 1;
  border-image: linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
}

.rs-link_theme_dark.rs-link_mode_clear, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_clear {
  --rs-link-color: var(--rs-typography-color-average, #ffffffb3);
  --rs-link-color-hover: var(--rs-typography-color-hard, #fff);
  --rs-underline-color-hover: var(--rs-typography-color-hard, #fff);
  color: var(--rs-link-color);
}

.rs-link_theme_dark.rs-link_mode_clear:hover, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_mode_clear:hover {
  color: var(--rs-link-color-hover);
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-underline-color-hover);
}

.rs-link_theme_dark.rs-link_hardness_hard, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_hardness_hard {
  --rs-link-color: var(--rs-typography-color-hard, #fff);
}

.rs-link_theme_dark.rs-link_hardness_average, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_hardness_average {
  --rs-link-color: var(--rs-typography-color-average, #ffffffb3);
}

.rs-link_theme_dark.rs-link_hardness_pale, [class*="_theme_dark"] .rs-link_theme_auto.rs-link_hardness_pale {
  --rs-link-color: var(--rs-typography-color-pale, #ffffff80);
}

._container_1rym8kv_10 {
  display: block;
}

._wrapper_1rym8kv_14 {
  cursor: text;
  border: 1px solid;
  display: flex;
}

._light_1rym8kv_21 ._wrapper_1rym8kv_14 {
  background-color: #fff;
  border-color: #19191c33;
}

._light_1rym8kv_21._enabled_1rym8kv_27._filled_1rym8kv_27 ._wrapper_1rym8kv_14 {
  border-color: #19191c4d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._light_1rym8kv_21._enabled_1rym8kv_27:hover ._wrapper_1rym8kv_14 {
    background-color: #fff;
    border-color: #19191c80;
  }
}

._light_1rym8kv_21._enabled_1rym8kv_27._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  border-width: 2px;
  border-color: #19191c;
}

._light_1rym8kv_21._enabled_1rym8kv_27._classic_1rym8kv_44._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  background-color: #fff;
}

._light_1rym8kv_21._enabled_1rym8kv_27._rock_1rym8kv_48._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  background-color: #19191c;
}

._dark_1rym8kv_52 ._wrapper_1rym8kv_14 {
  background-color: #303033;
  border-color: #fff3;
}

._dark_1rym8kv_52._enabled_1rym8kv_27._filled_1rym8kv_27 ._wrapper_1rym8kv_14 {
  border-color: #ffffff4d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._dark_1rym8kv_52._enabled_1rym8kv_27:hover ._wrapper_1rym8kv_14 {
    background-color: #474749;
    border-color: #ffffff80;
  }
}

._dark_1rym8kv_52._enabled_1rym8kv_27._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  border-width: 2px;
  border-color: #fff;
}

._dark_1rym8kv_52._enabled_1rym8kv_27._classic_1rym8kv_44._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  background-color: #474749;
}

._dark_1rym8kv_52._enabled_1rym8kv_27._rock_1rym8kv_48._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  background-color: #fff;
}

._error_1rym8kv_83 ._wrapper_1rym8kv_14 {
  border-width: 2px;
  border-color: #f45c4a;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._error_1rym8kv_83._enabled_1rym8kv_27:hover ._wrapper_1rym8kv_14 {
    border-color: #f45c4a;
  }
}

._error_1rym8kv_83._enabled_1rym8kv_27._focused_1rym8kv_39 ._wrapper_1rym8kv_14, ._error_1rym8kv_83._enabled_1rym8kv_27._filled_1rym8kv_27 ._wrapper_1rym8kv_14 {
  border-color: #f45c4a;
}

._error_1rym8kv_83._enabled_1rym8kv_27._rock_1rym8kv_48._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  background: #f45c4a;
}

._disabled_1rym8kv_103 ._wrapper_1rym8kv_14 {
  cursor: auto;
}

._disabled_1rym8kv_103._light_1rym8kv_21 ._wrapper_1rym8kv_14 {
  background-color: #f4f4f4;
}

._disabled_1rym8kv_103._dark_1rym8kv_52 ._wrapper_1rym8kv_14 {
  background-color: #19191c;
}

._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._wrapper_1rym8kv_14 {
  border-width: 0;
}

._field_1rym8kv_161 {
  flex: auto;
  min-width: 0;
  display: flex;
}

._sizeL_1rym8kv_121 ._field_1rym8kv_161 {
  padding: 11px 15px;
}

._sizeM_1rym8kv_125 ._field_1rym8kv_161 {
  padding: 7px;
}

._sizeS_1rym8kv_129 ._field_1rym8kv_161 {
  padding: 5px 7px;
}

._sizeL_1rym8kv_121._focused_1rym8kv_39 ._field_1rym8kv_161, ._sizeL_1rym8kv_121._error_1rym8kv_83 ._field_1rym8kv_161 {
  padding: 10px 14px;
}

._sizeM_1rym8kv_125._focused_1rym8kv_39 ._field_1rym8kv_161, ._sizeM_1rym8kv_125._error_1rym8kv_83 ._field_1rym8kv_161 {
  padding: 6px;
}

._sizeS_1rym8kv_129._focused_1rym8kv_39 ._field_1rym8kv_161, ._sizeS_1rym8kv_129._error_1rym8kv_83 ._field_1rym8kv_161 {
  padding: 4px 6px;
}

._sizeL_1rym8kv_121._rock_1rym8kv_48._focused_1rym8kv_39 ._field_1rym8kv_161 {
  padding: 12px 16px;
}

._sizeM_1rym8kv_125._rock_1rym8kv_48._focused_1rym8kv_39 ._field_1rym8kv_161 {
  padding: 8px;
}

._sizeS_1rym8kv_129._rock_1rym8kv_48._focused_1rym8kv_39 ._field_1rym8kv_161 {
  padding: 6px 8px;
}

._inner_1rym8kv_171 {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  flex: 1 1 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  display: block;
}

._inner_1rym8kv_171:focus {
  outline: none;
}

._inner_1rym8kv_171::-webkit-input-placeholder {
  opacity: 1;
}

._inner_1rym8kv_171::placeholder {
  opacity: 1;
}

._inner_1rym8kv_171:-ms-input-placeholder {
  opacity: 1;
}

._inner_1rym8kv_171::placeholder {
  opacity: 1;
}

._inner_1rym8kv_171::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

._inner_1rym8kv_171:-moz-ui-invalid {
  box-shadow: none;
}

._inner_1rym8kv_171:-moz-ui-invalid:-moz-focusring {
  box-shadow: none;
}

._inner_1rym8kv_171:-webkit-autofill {
  transition-delay: 9999s;
}

._inner_1rym8kv_171:-webkit-autofill:hover {
  transition-delay: 9999s;
}

._inner_1rym8kv_171:-webkit-autofill:active {
  transition-delay: 9999s;
}

._inner_1rym8kv_171:-webkit-autofill:focus {
  transition-delay: 9999s;
}

._light_1rym8kv_21 ._inner_1rym8kv_171:-webkit-autofill {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._light_1rym8kv_21 ._inner_1rym8kv_171:-webkit-autofill:hover {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._light_1rym8kv_21 ._inner_1rym8kv_171:-webkit-autofill:active {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._light_1rym8kv_21 ._inner_1rym8kv_171:-webkit-autofill:focus {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:hover {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:active {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:focus {
  -webkit-text-fill-color: #19191c;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1000px #19191c;
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171 {
  height: var(--rs-text-1-line-height, 28px);
  height: 28px;
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171 {
  height: var(--rs-text-2-line-height, 24px);
  height: 24px;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171 {
  height: var(--rs-text-3-line-height, 20px);
  height: 20px;
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171::-webkit-input-placeholder {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171::placeholder {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171:-ms-input-placeholder {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeL_1rym8kv_121 ._inner_1rym8kv_171::placeholder {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._inner_1rym8kv_171 {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171::-webkit-input-placeholder {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._inner_1rym8kv_171::-webkit-input-placeholder {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171:-ms-input-placeholder {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._inner_1rym8kv_171:-ms-input-placeholder {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171::-moz-placeholder {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._inner_1rym8kv_171::-moz-placeholder {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._inner_1rym8kv_171::placeholder {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._inner_1rym8kv_171::placeholder {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._inner_1rym8kv_171 {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171::-webkit-input-placeholder {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._inner_1rym8kv_171::-webkit-input-placeholder {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171:-ms-input-placeholder {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._inner_1rym8kv_171:-ms-input-placeholder {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171::-moz-placeholder {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._inner_1rym8kv_171::-moz-placeholder {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._inner_1rym8kv_171::placeholder {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._inner_1rym8kv_171::placeholder {
  letter-spacing: normal;
}

._light_1rym8kv_21 ._inner_1rym8kv_171 {
  color: #19191c;
}

._light_1rym8kv_21 ._inner_1rym8kv_171::-webkit-input-placeholder {
  color: #19191c80;
}

._light_1rym8kv_21 ._inner_1rym8kv_171::placeholder {
  color: #19191c80;
}

._light_1rym8kv_21 ._inner_1rym8kv_171:-ms-input-placeholder {
  color: #19191c80;
}

._light_1rym8kv_21 ._inner_1rym8kv_171::placeholder {
  color: #19191c80;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171 {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  color: #fff;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171::-webkit-input-placeholder {
  color: #ffffff80;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171::placeholder {
  color: #ffffff80;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171:-ms-input-placeholder {
  color: #ffffff80;
}

._dark_1rym8kv_52 ._inner_1rym8kv_171::placeholder {
  color: #ffffff80;
}

._light_1rym8kv_21._disabled_1rym8kv_103 ._inner_1rym8kv_171, ._light_1rym8kv_21 ._inner_1rym8kv_171:disabled {
  color: #19191cb3;
}

._dark_1rym8kv_52._disabled_1rym8kv_103 ._inner_1rym8kv_171, ._dark_1rym8kv_52 ._inner_1rym8kv_171:disabled {
  color: #ffffffb3;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171 {
  color: #19191c;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::-webkit-input-placeholder {
  color: #19191cb3;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::placeholder {
  color: #19191cb3;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-ms-input-placeholder {
  color: #19191cb3;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::placeholder {
  color: #19191cb3;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171, ._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171 {
  color: #fff;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::-webkit-input-placeholder {
  color: #ffffffb3;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::-webkit-input-placeholder {
  color: #ffffffb3;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-ms-input-placeholder {
  color: #ffffffb3;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171:-ms-input-placeholder {
  color: #ffffffb3;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::-moz-placeholder {
  color: #ffffffb3;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::-moz-placeholder {
  color: #ffffffb3;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::placeholder, ._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._inner_1rym8kv_171::placeholder {
  color: #ffffffb3;
}

._offSystemMicroelements_1rym8kv_345 ._inner_1rym8kv_171 {
  -moz-appearance: textfield;
}

._offSystemMicroelements_1rym8kv_345 ._inner_1rym8kv_171::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

._offSystemMicroelements_1rym8kv_345 ._inner_1rym8kv_171::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

._icon_1rym8kv_356 {
  flex: none;
  display: inline-flex;
}

._icon_1rym8kv_356._action_1rym8kv_360 {
  cursor: pointer;
  position: relative;
}

._icon_1rym8kv_356._action_1rym8kv_360:focus {
  outline: none;
}

._icon_1rym8kv_356._action_1rym8kv_360:before {
  border: 4px solid #6b57ffcc;
  border: 4px solid var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
  content: "";
  pointer-events: none;
  border-radius: 4px;
  display: none;
  position: absolute;
  inset: -4px;
}

._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"]:before {
  display: block;
}

._light_1rym8kv_21 ._icon_1rym8kv_356._action_1rym8kv_360:before {
  border-color: #6b57ffcc;
  border-color: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc);
}

._dark_1rym8kv_52 ._icon_1rym8kv_356._action_1rym8kv_360:before {
  border-color: #8473ffcc;
  border-color: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc);
}

._disabled_1rym8kv_103 ._icon_1rym8kv_356._action_1rym8kv_360 {
  pointer-events: none;
}

._sizeL_1rym8kv_121 ._icon_1rym8kv_356 {
  margin-top: -11px;
  margin-bottom: -11px;
  padding-top: 11px;
  padding-bottom: 11px;
}

._sizeM_1rym8kv_125 ._icon_1rym8kv_356 {
  margin-top: -7px;
  margin-bottom: -7px;
  padding-top: 7px;
  padding-bottom: 7px;
}

._sizeS_1rym8kv_129 ._icon_1rym8kv_356 {
  margin-top: -5px;
  margin-bottom: -5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

._sizeL_1rym8kv_121._focused_1rym8kv_39 ._icon_1rym8kv_356, ._sizeL_1rym8kv_121._error_1rym8kv_83 ._icon_1rym8kv_356 {
  margin-top: -10px;
  margin-bottom: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

._sizeM_1rym8kv_125._focused_1rym8kv_39 ._icon_1rym8kv_356, ._sizeM_1rym8kv_125._error_1rym8kv_83 ._icon_1rym8kv_356 {
  margin-top: -6px;
  margin-bottom: -6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

._sizeS_1rym8kv_129._focused_1rym8kv_39 ._icon_1rym8kv_356, ._sizeS_1rym8kv_129._error_1rym8kv_83 ._icon_1rym8kv_356 {
  margin-top: -4px;
  margin-bottom: -4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

._rock_1rym8kv_48._sizeL_1rym8kv_121._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

._rock_1rym8kv_48._sizeM_1rym8kv_125._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

._rock_1rym8kv_48._sizeS_1rym8kv_129._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  margin-top: -6px;
  margin-bottom: -6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

._sizeL_1rym8kv_121 ._icon_1rym8kv_356._right_1rym8kv_441 {
  margin-left: 16px;
}

._sizeM_1rym8kv_125 ._icon_1rym8kv_356._right_1rym8kv_441, ._sizeS_1rym8kv_129 ._icon_1rym8kv_356._right_1rym8kv_441 {
  margin-left: 8px;
}

._sizeL_1rym8kv_121 ._icon_1rym8kv_356._left_1rym8kv_453 {
  margin-right: 16px;
}

._sizeM_1rym8kv_125 ._icon_1rym8kv_356._left_1rym8kv_453, ._sizeS_1rym8kv_129 ._icon_1rym8kv_356._left_1rym8kv_453 {
  margin-right: 8px;
}

._dark_1rym8kv_52 ._icon_1rym8kv_356, ._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  color: #ffffffb3;
}

._light_1rym8kv_21 ._icon_1rym8kv_356, ._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  color: #19191cb3;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._icon_1rym8kv_356 {
  color: #ffffffb3;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._dark_1rym8kv_52._enabled_1rym8kv_27 ._icon_1rym8kv_356._action_1rym8kv_360:hover, ._light_1rym8kv_21._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:hover {
    color: #fff;
  }
}

._dark_1rym8kv_52._enabled_1rym8kv_27 ._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"], ._light_1rym8kv_21._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"] {
  color: #fff;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._light_1rym8kv_21._enabled_1rym8kv_27 ._icon_1rym8kv_356._action_1rym8kv_360:hover, ._dark_1rym8kv_52._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:hover {
    color: #19191c;
  }
}

._light_1rym8kv_21._enabled_1rym8kv_27 ._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"], ._dark_1rym8kv_52._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"] {
  color: #19191c;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) {
  ._error_1rym8kv_83._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:hover {
    color: #fff;
  }
}

._error_1rym8kv_83._rock_1rym8kv_48._enabled_1rym8kv_27._focused_1rym8kv_39 ._icon_1rym8kv_356._action_1rym8kv_360:focus[data-focus-method="key"] {
  color: #fff;
}

._disabled_1rym8kv_103._light_1rym8kv_21 ._icon_1rym8kv_356 {
  color: #19191c80;
}

._disabled_1rym8kv_103._dark_1rym8kv_52 ._icon_1rym8kv_356 {
  color: #ffffff80;
}

._divider_1rym8kv_523 {
  width: 1px;
  margin-left: 1px;
  margin-right: 1px;
  display: block;
}

._focused_1rym8kv_39 ._divider_1rym8kv_523, ._error_1rym8kv_83 ._divider_1rym8kv_523 {
  margin-left: 2px;
  margin-right: 2px;
}

._rock_1rym8kv_48._focused_1rym8kv_39 ._divider_1rym8kv_523 {
  margin-left: 0;
  margin-right: 0;
}

._light_1rym8kv_21 ._divider_1rym8kv_523 {
  background: #19191c33;
}

._dark_1rym8kv_52 ._divider_1rym8kv_523 {
  background: #fff3;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._divider_1rym8kv_523 {
  background: #fff;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._divider_1rym8kv_523 {
  background: #19191c;
}

._suffix_1rym8kv_558 {
  flex: none;
}

._sizeL_1rym8kv_121 ._suffix_1rym8kv_558 {
  padding: 11px 15px;
}

._sizeM_1rym8kv_125 ._suffix_1rym8kv_558 {
  padding: 7px;
}

._sizeS_1rym8kv_129 ._suffix_1rym8kv_558 {
  padding: 5px 7px;
}

._sizeL_1rym8kv_121._focused_1rym8kv_39 ._suffix_1rym8kv_558, ._sizeL_1rym8kv_121._error_1rym8kv_83 ._suffix_1rym8kv_558 {
  padding: 10px 14px;
}

._sizeM_1rym8kv_125._focused_1rym8kv_39 ._suffix_1rym8kv_558, ._sizeM_1rym8kv_125._error_1rym8kv_83 ._suffix_1rym8kv_558 {
  padding: 6px;
}

._sizeS_1rym8kv_129._focused_1rym8kv_39 ._suffix_1rym8kv_558, ._sizeS_1rym8kv_129._error_1rym8kv_83 ._suffix_1rym8kv_558 {
  padding: 4px 6px;
}

._sizeL_1rym8kv_121._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  padding: 12px 16px;
}

._sizeM_1rym8kv_125._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  padding: 8px;
}

._sizeS_1rym8kv_129._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  padding: 6px 8px;
}

._sizeL_1rym8kv_121 ._suffix_1rym8kv_558 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeM_1rym8kv_125 ._suffix_1rym8kv_558 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._suffix_1rym8kv_558 {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129 ._suffix_1rym8kv_558 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._suffix_1rym8kv_558 {
  letter-spacing: normal;
}

._light_1rym8kv_21 ._suffix_1rym8kv_558 {
  color: #19191c80;
}

._dark_1rym8kv_52 ._suffix_1rym8kv_558 {
  color: #ffffff80;
}

._light_1rym8kv_21._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  color: #ffffffb3;
}

._dark_1rym8kv_52._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  color: #19191cb3;
}

._error_1rym8kv_83._rock_1rym8kv_48._focused_1rym8kv_39 ._suffix_1rym8kv_558 {
  color: #ffffffb3;
}

._errorMessage_1rym8kv_596 {
  margin-top: 8px;
}

._sizeS_1rym8kv_129 ._errorMessage_1rym8kv_596 {
  margin-top: 4px;
}

._sizeL_1rym8kv_121 ._errorMessage_1rym8kv_596 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeL_1rym8kv_121 ._errorMessage_1rym8kv_596 {
  letter-spacing: normal;
}

._sizeL_1rym8kv_121 ._errorMessage_1rym8kv_596 {
  color: #f45c4a;
}

._sizeM_1rym8kv_125 ._errorMessage_1rym8kv_596, ._sizeS_1rym8kv_129 ._errorMessage_1rym8kv_596 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._errorMessage_1rym8kv_596, ._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._errorMessage_1rym8kv_596 {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._errorMessage_1rym8kv_596, ._sizeS_1rym8kv_129 ._errorMessage_1rym8kv_596 {
  color: #f45c4a;
}

._note_1rym8kv_614 {
  margin-top: 8px;
}

._sizeS_1rym8kv_129 ._note_1rym8kv_614 {
  margin-top: 4px;
}

._sizeL_1rym8kv_121 ._note_1rym8kv_614 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeL_1rym8kv_121 ._note_1rym8kv_614 {
  letter-spacing: normal;
}

._sizeM_1rym8kv_125 ._note_1rym8kv_614, ._sizeS_1rym8kv_129 ._note_1rym8kv_614 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._note_1rym8kv_614, ._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._note_1rym8kv_614 {
  letter-spacing: normal;
}

._light_1rym8kv_21 ._note_1rym8kv_614 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._dark_1rym8kv_52 ._note_1rym8kv_614 {
  color: var(--rs-typography-color-average, #ffffffb3);
}

._light_1rym8kv_21._disabled_1rym8kv_103 ._note_1rym8kv_614 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._dark_1rym8kv_52._disabled_1rym8kv_103 ._note_1rym8kv_614 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._label_1rym8kv_646 {
  margin-bottom: 8px;
}

._sizeS_1rym8kv_129 ._label_1rym8kv_646 {
  margin-bottom: 4px;
}

._sizeL_1rym8kv_121 ._label_1rym8kv_646 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeM_1rym8kv_125 ._label_1rym8kv_646 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeM_1rym8kv_125 ._label_1rym8kv_646 {
  letter-spacing: normal;
}

._sizeS_1rym8kv_129._boldLabel_1rym8kv_661 ._label_1rym8kv_646 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-h5-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 20px;
  line-height: var(--rs-h5-line-height, 20px);
}

._sizeL_1rym8kv_121._boldLabel_1rym8kv_661 ._label_1rym8kv_646 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-h3-font-size, 20px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 28px;
  line-height: var(--rs-h3-line-height, 28px);
}

._sizeM_1rym8kv_125._boldLabel_1rym8kv_661 ._label_1rym8kv_646 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-h4-font-size, 16px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 24px;
  line-height: var(--rs-h4-line-height, 24px);
}

._sizeS_1rym8kv_129 ._label_1rym8kv_646 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1rym8kv_1 ._sizeS_1rym8kv_129 ._label_1rym8kv_646 {
  letter-spacing: normal;
}

._light_1rym8kv_21 ._label_1rym8kv_646 {
  color: var(--rs-typography-color-hard, #19191c);
}

._dark_1rym8kv_52 ._label_1rym8kv_646 {
  color: var(--rs-typography-color-hard, #fff);
}

._light_1rym8kv_21._disabled_1rym8kv_103 ._label_1rym8kv_646 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._dark_1rym8kv_52._disabled_1rym8kv_103 ._label_1rym8kv_646 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._tooltip_1qwmbuc_4 {
  position: relative;
}

._closing_1qwmbuc_8 {
  pointer-events: none;
}

._content_1qwmbuc_12 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1qwmbuc_1 ._content_1qwmbuc_12 {
  letter-spacing: normal;
}

._content_1qwmbuc_12 {
  color: #fff;
  background-color: #303033;
  border-radius: 4px;
  max-width: 283px;
  padding: 8px;
  transition: opacity .3s, transform .3s, -webkit-transform .3s;
  transform: translate(0);
}

[data-real-placement="top-end"] ._content_1qwmbuc_12, [data-real-placement="bottom-end"] ._content_1qwmbuc_12 {
  margin-right: 0;
}

[data-real-placement="top-start"] ._content_1qwmbuc_12, [data-real-placement="bottom-start"] ._content_1qwmbuc_12 {
  margin-left: 0;
}

[data-real-placement="left-end"] ._content_1qwmbuc_12, [data-real-placement="right-end"] ._content_1qwmbuc_12 {
  margin-bottom: 0;
}

[data-real-placement="left-start"] ._content_1qwmbuc_12, [data-real-placement="right-start"] ._content_1qwmbuc_12 {
  margin-top: 0;
}

._initial_1qwmbuc_47 ._content_1qwmbuc_12 {
  transition: none;
}

._closing_1qwmbuc_8 ._content_1qwmbuc_12 {
  transition-duration: .2s, .2s;
}

._initial_1qwmbuc_47 ._content_1qwmbuc_12, ._closing_1qwmbuc_8 ._content_1qwmbuc_12 {
  opacity: 0;
}

._initial_1qwmbuc_47[data-real-placement^="top"] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^="top"] ._content_1qwmbuc_12 {
  transform: translate(0, 10px);
}

._initial_1qwmbuc_47[data-real-placement^="right"] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^="right"] ._content_1qwmbuc_12 {
  transform: translate(-10px);
}

._initial_1qwmbuc_47[data-real-placement^="bottom"] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^="bottom"] ._content_1qwmbuc_12 {
  transform: translate(0, -10px);
}

._initial_1qwmbuc_47[data-real-placement^="left"] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^="left"] ._content_1qwmbuc_12 {
  transform: translate(10px);
}

._trigger_1qwmbuc_81:focus[data-focus-method="key"], ._trigger_1qwmbuc_81:focus-visible {
  -webkit-box-shadow: 0 0 0 4px #6b57ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6b57ffcc;
  box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
  outline: none;
}

._main_9e8ws0_24 {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  display: flex;
  position: relative;
}

._main_9e8ws0_24:focus, ._main_9e8ws0_24._open_9e8ws0_36 {
  outline: none;
}

._enabled_9e8ws0_41 {
  cursor: pointer;
}

._disabled_9e8ws0_45 {
  pointer-events: none;
}

._focused_9e8ws0_49, ._searchFocused_9e8ws0_50, ._open_9e8ws0_36 {
  border-width: 2px;
}

._sizeL_9e8ws0_56 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  color: var(--rs-typography-color-average, #19191cb3);
  height: 52px;
}

._sizeM_9e8ws0_59 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_9e8ws0_1 ._sizeM_9e8ws0_59 {
  letter-spacing: normal;
}

._sizeM_9e8ws0_59 {
  color: var(--rs-typography-color-average, #19191cb3);
  height: 40px;
}

._sizeS_9e8ws0_62 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_9e8ws0_1 ._sizeS_9e8ws0_62 {
  letter-spacing: normal;
}

._sizeS_9e8ws0_62 {
  color: var(--rs-typography-color-average, #19191cb3);
  height: 32px;
}

._isMulti_9e8ws0_136 {
  height: auto;
}

._error_9e8ws0_69 {
  padding: 0;
}

._notSelected_9e8ws0_144 {
  -webkit-user-select: none;
  user-select: none;
}

._themeLight_9e8ws0_148 {
  background-color: #fff;
  border-color: #19191c33;
}

._themeLight_9e8ws0_148[data-focus-method="key"], ._themeLight_9e8ws0_148._searchKeyFocused_9e8ws0_154 {
  -webkit-box-shadow: 0 0 0 4px #8979ff;
  -webkit-box-shadow: 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
  box-shadow: 0 0 0 4px #8979ff;
  box-shadow: 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover {
  border-color: #19191c80;
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36, ._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50, ._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 {
  border-color: #19191c;
}

._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 {
  background-color: #19191c;
}

._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._labelWrapper_9e8ws0_177, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._labelWrapper_9e8ws0_177, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._labelWrapper_9e8ws0_177 {
  color: var(--rs-typography-color-hard, #fff);
}

._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._notSelected_9e8ws0_144, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._notSelected_9e8ws0_144, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._notSelected_9e8ws0_144 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover > ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148 ._labelWrapper_9e8ws0_177 {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeLight_9e8ws0_148 ._notSelected_9e8ws0_144 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._themeDark_9e8ws0_199 {
  background-color: #303033;
  border-color: #fff3;
}

._themeDark_9e8ws0_199[data-focus-method="key"], ._themeDark_9e8ws0_199._searchKeyFocused_9e8ws0_154 {
  -webkit-box-shadow: 0 0 0 4px #6f61d2;
  -webkit-box-shadow: 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  box-shadow: 0 0 0 4px #6f61d2;
  box-shadow: 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover {
  background-color: #474749;
  border-color: #ffffff80;
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36, ._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50, ._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 {
  border-color: #fff;
}

._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 {
  background-color: #fff;
}

._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._labelWrapper_9e8ws0_177, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._labelWrapper_9e8ws0_177, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._labelWrapper_9e8ws0_177 {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._notSelected_9e8ws0_144, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._notSelected_9e8ws0_144, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._notSelected_9e8ws0_144 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover > ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199 ._labelWrapper_9e8ws0_177 {
  color: var(--rs-typography-color-hard, #fff);
}

._themeDark_9e8ws0_199 ._notSelected_9e8ws0_144 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._error_9e8ws0_69, ._error_9e8ws0_69._error_9e8ws0_69:hover, ._error_9e8ws0_69._error_9e8ws0_69._searchFocused_9e8ws0_50, ._error_9e8ws0_69._error_9e8ws0_69._open_9e8ws0_36, ._error_9e8ws0_69._error_9e8ws0_69._focused_9e8ws0_49, ._error_9e8ws0_69._error_9e8ws0_69._disabled_9e8ws0_45, ._error_9e8ws0_69._error_9e8ws0_69._disabled_9e8ws0_45:hover {
  border-width: 2px;
  border-color: #f45c4a;
}

._themeLight_9e8ws0_148._disabled_9e8ws0_45 {
  color: var(--rs-typography-color-pale, #19191c80);
  background-color: #f4f4f4;
  border-color: #19191c33;
}

._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._label_9e8ws0_177 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._themeDark_9e8ws0_199._disabled_9e8ws0_45 {
  color: var(--rs-typography-color-pale, #ffffff80);
  background-color: #19191c;
  border-color: #fff3;
}

._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._label_9e8ws0_177 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._sizeL_9e8ws0_56 ._iconsWrapper_9e8ws0_285 {
  padding: 11px 15px;
}

._sizeM_9e8ws0_59 ._iconsWrapper_9e8ws0_285 {
  padding: 7px;
}

._sizeS_9e8ws0_62 ._iconsWrapper_9e8ws0_285 {
  padding: 5px 7px;
}

._sizeL_9e8ws0_56._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285, ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285, ._sizeL_9e8ws0_56._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285, ._sizeL_9e8ws0_56._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285 {
  padding: 10px 14px;
}

._sizeM_9e8ws0_59._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285, ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285, ._sizeM_9e8ws0_59._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285, ._sizeM_9e8ws0_59._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285 {
  padding: 6px;
}

._sizeS_9e8ws0_62._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285, ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285, ._sizeS_9e8ws0_62._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285, ._sizeS_9e8ws0_62._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285 {
  padding: 4px 6px;
}

._iconsWrapper_9e8ws0_285 {
  box-sizing: content-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  padding-left: 0 !important;
}

._sizeS_9e8ws0_62 ._iconsWrapper_9e8ws0_285 {
  height: 20px;
}

._sizeM_9e8ws0_59 ._iconsWrapper_9e8ws0_285 {
  height: 24px;
}

._sizeL_9e8ws0_56 ._iconsWrapper_9e8ws0_285 {
  height: 28px;
}

._labelWrapper_9e8ws0_177 {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  position: relative;
}

._sizeL_9e8ws0_56 ._label_9e8ws0_177 {
  padding: 11px 15px;
}

._sizeM_9e8ws0_59 ._label_9e8ws0_177 {
  padding: 7px;
}

._sizeS_9e8ws0_62 ._label_9e8ws0_177 {
  padding: 5px 7px;
}

._sizeL_9e8ws0_56._focused_9e8ws0_49 ._label_9e8ws0_177, ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._label_9e8ws0_177, ._sizeL_9e8ws0_56._open_9e8ws0_36 ._label_9e8ws0_177, ._sizeL_9e8ws0_56._error_9e8ws0_69 ._label_9e8ws0_177 {
  padding: 10px 14px;
}

._sizeM_9e8ws0_59._focused_9e8ws0_49 ._label_9e8ws0_177, ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._label_9e8ws0_177, ._sizeM_9e8ws0_59._open_9e8ws0_36 ._label_9e8ws0_177, ._sizeM_9e8ws0_59._error_9e8ws0_69 ._label_9e8ws0_177 {
  padding: 6px;
}

._sizeS_9e8ws0_62._focused_9e8ws0_49 ._label_9e8ws0_177, ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._label_9e8ws0_177, ._sizeS_9e8ws0_62._open_9e8ws0_36 ._label_9e8ws0_177, ._sizeS_9e8ws0_62._error_9e8ws0_69 ._label_9e8ws0_177 {
  padding: 4px 6px;
}

._label_9e8ws0_177 {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  padding-right: 0 !important;
}

._label_9e8ws0_177:focus {
  outline: none;
}

._sizeL_9e8ws0_56 ._searchInput_9e8ws0_340 {
  padding: 11px 15px;
}

._sizeM_9e8ws0_59 ._searchInput_9e8ws0_340 {
  padding: 7px;
}

._sizeS_9e8ws0_62 ._searchInput_9e8ws0_340 {
  padding: 5px 7px;
}

._sizeL_9e8ws0_56._focused_9e8ws0_49 ._searchInput_9e8ws0_340, ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340, ._sizeL_9e8ws0_56._open_9e8ws0_36 ._searchInput_9e8ws0_340, ._sizeL_9e8ws0_56._error_9e8ws0_69 ._searchInput_9e8ws0_340 {
  padding: 10px 14px;
}

._sizeM_9e8ws0_59._focused_9e8ws0_49 ._searchInput_9e8ws0_340, ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340, ._sizeM_9e8ws0_59._open_9e8ws0_36 ._searchInput_9e8ws0_340, ._sizeM_9e8ws0_59._error_9e8ws0_69 ._searchInput_9e8ws0_340 {
  padding: 6px;
}

._sizeS_9e8ws0_62._focused_9e8ws0_49 ._searchInput_9e8ws0_340, ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340, ._sizeS_9e8ws0_62._open_9e8ws0_36 ._searchInput_9e8ws0_340, ._sizeS_9e8ws0_62._error_9e8ws0_69 ._searchInput_9e8ws0_340 {
  padding: 4px 6px;
}

._searchInput_9e8ws0_340 {
  opacity: 1;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  background: none;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 0 !important;
}

._sizeL_9e8ws0_56 ._multi_9e8ws0_366 {
  padding: 7px 15px;
}

._sizeM_9e8ws0_59 ._multi_9e8ws0_366 {
  padding: 5px 7px;
}

._sizeS_9e8ws0_62 ._multi_9e8ws0_366 {
  padding: 3px 7px;
}

._sizeL_9e8ws0_56._focused_9e8ws0_49 ._multi_9e8ws0_366, ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._multi_9e8ws0_366, ._sizeL_9e8ws0_56._open_9e8ws0_36 ._multi_9e8ws0_366, ._sizeL_9e8ws0_56._error_9e8ws0_69 ._multi_9e8ws0_366 {
  padding: 6px 14px;
}

._sizeM_9e8ws0_59._focused_9e8ws0_49 ._multi_9e8ws0_366, ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._multi_9e8ws0_366, ._sizeM_9e8ws0_59._open_9e8ws0_36 ._multi_9e8ws0_366, ._sizeM_9e8ws0_59._error_9e8ws0_69 ._multi_9e8ws0_366 {
  padding: 4px 6px;
}

._sizeS_9e8ws0_62._focused_9e8ws0_49 ._multi_9e8ws0_366, ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._multi_9e8ws0_366, ._sizeS_9e8ws0_62._open_9e8ws0_36 ._multi_9e8ws0_366, ._sizeS_9e8ws0_62._error_9e8ws0_69 ._multi_9e8ws0_366 {
  padding: 2px 6px;
}

._multi_9e8ws0_366 {
  flex: 0 100%;
  position: relative;
  padding-right: 0 !important;
}

._tagsContainer_9e8ws0_375 {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

._sizeL_9e8ws0_56 ._tagsContainer_9e8ws0_375 {
  margin-top: -8px;
}

._sizeM_9e8ws0_59 ._tagsContainer_9e8ws0_375 {
  margin-top: -6px;
}

._sizeS_9e8ws0_62 ._tagsContainer_9e8ws0_375 {
  margin-top: -4px;
}

._sizeL_9e8ws0_56 ._tag_9e8ws0_375 {
  margin-top: 8px;
  margin-right: 8px;
}

._sizeM_9e8ws0_59 ._tag_9e8ws0_375 {
  margin-top: 6px;
  margin-right: 4px;
}

._sizeS_9e8ws0_62 ._tag_9e8ws0_375 {
  margin-top: 4px;
  margin-right: 4px;
}

._sizeL_9e8ws0_56 ._tag_9e8ws0_375:last-child, ._sizeM_9e8ws0_59 ._tag_9e8ws0_375:last-child, ._sizeS_9e8ws0_62 ._tag_9e8ws0_375:last-child {
  margin-right: 0;
}

._toggle_9e8ws0_173, ._icon_9e8ws0_285 {
  fill: currentColor;
  flex: none;
  display: inline-block;
}

._enabled_9e8ws0_41 ._toggle_9e8ws0_173, ._enabled_9e8ws0_41 ._icon_9e8ws0_285 {
  cursor: pointer;
}

._themeLight_9e8ws0_148 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._icon_9e8ws0_285 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._themeDark_9e8ws0_199 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._icon_9e8ws0_285 {
  color: var(--rs-typography-color-average, #ffffffb3);
}

._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._focused_9e8ws0_49 ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._open_9e8ws0_36 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._focused_9e8ws0_49 ._icon_9e8ws0_285 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._focused_9e8ws0_49 ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._open_9e8ws0_36 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._icon_9e8ws0_285, ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._focused_9e8ws0_49 ._icon_9e8ws0_285 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._sizeL_9e8ws0_56 ._toggle_9e8ws0_173, ._sizeL_9e8ws0_56 ._icon_9e8ws0_285 {
  margin-left: 16px;
}

._sizeM_9e8ws0_59 ._toggle_9e8ws0_173, ._sizeM_9e8ws0_59 ._icon_9e8ws0_285, ._sizeS_9e8ws0_62 ._toggle_9e8ws0_173, ._sizeS_9e8ws0_62 ._icon_9e8ws0_285 {
  margin-left: 8px;
}

._toggle_9e8ws0_173 {
  transition: transform .3s, -webkit-transform .3s;
}

._open_9e8ws0_36 ._toggle_9e8ws0_173 {
  transform: rotate(180deg);
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173 {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173 {
  color: var(--rs-typography-color-hard, #fff);
}

._clearIcon_9e8ws0_490 {
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: none;
  border: none;
  width: auto;
  margin: 0;
  padding: 0;
  line-height: normal;
  display: flex;
  overflow: visible;
}

._clearIcon_9e8ws0_490::-moz-focus-inner {
  border: 0;
  padding: 0;
}

._clearIcon_9e8ws0_490:focus, ._clearIcon_9e8ws0_490:active {
  outline: none;
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 3px #6b57ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 3px;
  box-shadow: 0 0 0 3px #6b57ffcc;
  box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 3px;
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"], ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 3px #8473ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 3px;
  box-shadow: 0 0 0 3px #8473ffcc;
  box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 3px;
}

._themeLight_9e8ws0_148._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:hover {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_9e8ws0_199._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:hover, ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:hover {
  color: var(--rs-typography-color-hard, #fff);
}

._tag_9e8ws0_375 {
  overflow: hidden;
}

._tag_9e8ws0_375 > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

._errorMessage_14zyrwg_4 {
  color: #f45c4a;
}

._sizeL_14zyrwg_8 ._label_14zyrwg_9 {
  margin-bottom: 8px;
}

._sizeL_14zyrwg_8 ._note_14zyrwg_12, ._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4 {
  margin-top: 8px;
}

._sizeM_14zyrwg_17 ._label_14zyrwg_9 {
  margin-bottom: 8px;
}

._sizeM_14zyrwg_17 ._note_14zyrwg_12, ._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4 {
  margin-top: 8px;
}

._sizeS_14zyrwg_26 ._label_14zyrwg_9 {
  margin-bottom: 4px;
}

._sizeS_14zyrwg_26 ._note_14zyrwg_12, ._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4 {
  margin-top: 4px;
}

._themeLight_14zyrwg_36 ._note_14zyrwg_12 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._themeDark_14zyrwg_40 ._note_14zyrwg_12 {
  color: var(--rs-typography-color-average, #ffffffb3);
}

._sizeS_14zyrwg_26 ._note_14zyrwg_12, ._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4, ._sizeM_14zyrwg_17 ._note_14zyrwg_12, ._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._note_14zyrwg_12, ._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4, ._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._note_14zyrwg_12, ._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4 {
  letter-spacing: normal;
}

._sizeL_14zyrwg_8 ._note_14zyrwg_12, ._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_14zyrwg_1 ._sizeL_14zyrwg_8 ._note_14zyrwg_12, ._rs-reset-letter-spacing_14zyrwg_1 ._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4 {
  letter-spacing: normal;
}

._themeLight_14zyrwg_36 ._label_14zyrwg_9 {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_14zyrwg_40 ._label_14zyrwg_9 {
  color: var(--rs-typography-color-hard, #fff);
}

._sizeS_14zyrwg_26 ._label_14zyrwg_9 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._label_14zyrwg_9 {
  letter-spacing: normal;
}

._sizeM_14zyrwg_17 ._label_14zyrwg_9 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._label_14zyrwg_9 {
  letter-spacing: normal;
}

._sizeL_14zyrwg_8 ._label_14zyrwg_9 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeS_14zyrwg_26 ._label_14zyrwg_9._bold_14zyrwg_77 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-h5-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 20px;
  line-height: var(--rs-h5-line-height, 20px);
}

._sizeM_14zyrwg_17 ._label_14zyrwg_9._bold_14zyrwg_77 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-h4-font-size, 16px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 24px;
  line-height: var(--rs-h4-line-height, 24px);
}

._sizeL_14zyrwg_8 ._label_14zyrwg_9._bold_14zyrwg_77 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-h3-font-size, 20px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 28px;
  line-height: var(--rs-h3-line-height, 28px);
}

._menu_h0qydt_1 {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  list-style: none;
}

._main_1v4wzkb_3 {
  position: relative;
}

._select_1v4wzkb_7 {
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

._sizeL_1v4wzkb_19 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1v4wzkb_1 ._sizeL_1v4wzkb_19 {
  letter-spacing: normal;
}

._sizeL_1v4wzkb_19 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._sizeM_1v4wzkb_23 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1v4wzkb_1 ._sizeM_1v4wzkb_23 {
  letter-spacing: normal;
}

._sizeM_1v4wzkb_23 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._sizeS_1v4wzkb_27 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1v4wzkb_1 ._sizeS_1v4wzkb_27 {
  letter-spacing: normal;
}

._sizeS_1v4wzkb_27 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._main_1uoszgd_25 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1uoszgd_1 ._main_1uoszgd_25 {
  letter-spacing: normal;
}

._main_1uoszgd_25 {
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  background: none;
  border: 1px solid #0000;
  outline: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

._alignIconLeft_1uoszgd_46 {
  flex-direction: row;
}

._alignIconRight_1uoszgd_50 {
  flex-direction: row-reverse;
}

._sizeXs_1uoszgd_54 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1uoszgd_1 ._sizeXs_1uoszgd_54 {
  letter-spacing: normal;
}

._sizeXs_1uoszgd_54 {
  padding: 1px 7px;
}

._sizeS_1uoszgd_61 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1uoszgd_1 ._sizeS_1uoszgd_61 {
  letter-spacing: normal;
}

._sizeS_1uoszgd_61 {
  padding: 3px 7px;
}

._sizeM_1uoszgd_68 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1uoszgd_1 ._sizeM_1uoszgd_68 {
  letter-spacing: normal;
}

._sizeM_1uoszgd_68 {
  padding: 5px 11px;
}

._sizeL_1uoszgd_75 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
  padding: 7px 15px;
}

._icon_1uoszgd_82 {
  fill: currentColor;
}

._sizeXs_1uoszgd_54 ._icon_1uoszgd_82, ._sizeS_1uoszgd_61 ._icon_1uoszgd_82 {
  width: 20px;
  height: 20px;
}

._sizeM_1uoszgd_68 ._icon_1uoszgd_82 {
  width: 24px;
  height: 24px;
}

._sizeL_1uoszgd_75 ._icon_1uoszgd_82 {
  width: 28px;
  height: 28px;
}

._alignIconLeft_1uoszgd_46 ._icon_1uoszgd_82 {
  margin-left: 0;
  margin-right: 8px;
}

._alignIconRight_1uoszgd_50 ._icon_1uoszgd_82 {
  margin-left: 8px;
  margin-right: 0;
}

._uppercase_1uoszgd_116 {
  text-transform: uppercase;
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
}

._uppercase_1uoszgd_116._sizeS_1uoszgd_61 {
  padding-left: 9px;
  padding-right: 9px;
}

._sizeXs_qp3fbt_9, ._sizeS_qp3fbt_13 {
  border-radius: 4px;
}

._sizeM_qp3fbt_17 {
  border-radius: 6px;
}

._sizeL_qp3fbt_21 {
  border-radius: 8px;
}

._wrapper_4e3oje_36 {
  white-space: nowrap;
  display: inline-flex;
  position: relative;
}

._wrapper_4e3oje_36 > * {
  vertical-align: top;
}

._sizeXs_4e3oje_48, ._sizeS_4e3oje_49 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_4e3oje_1 ._sizeXs_4e3oje_48, ._rs-reset-letter-spacing_4e3oje_1 ._sizeS_4e3oje_49 {
  letter-spacing: normal;
}

._sizeM_4e3oje_53 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_4e3oje_1 ._sizeM_4e3oje_53 {
  letter-spacing: normal;
}

._sizeL_4e3oje_57 {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._themeLight_4e3oje_61 {
  color: var(--rs-typography-color-average, #19191cb3);
}

._themeLight_4e3oje_61._disabled_4e3oje_65 {
  color: var(--rs-typography-color-pale, #19191c80);
}

._themeDark_4e3oje_69 {
  color: var(--rs-typography-color-average, #ffffffb3);
}

._themeDark_4e3oje_69._disabled_4e3oje_65 {
  color: var(--rs-typography-color-pale, #ffffff80);
}

._disabled_4e3oje_65 {
  pointer-events: none;
}

._sizeXs_4e3oje_48, ._sizeS_4e3oje_49 {
  border-radius: 4px;
  padding-left: 8px;
}

._sizeM_4e3oje_53 {
  border-radius: 6px;
  padding-left: 12px;
}

._sizeL_4e3oje_57 {
  border-radius: 8px;
  padding-left: 16px;
}

._themeLight_4e3oje_61 {
  background-color: #19191c1a;
}

._themeDark_4e3oje_69 {
  background-color: #ffffff1a;
}

._main_4e3oje_110 {
  white-space: nowrap;
  background: none;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

._wrap_4e3oje_36 ._main_4e3oje_110 {
  white-space: normal;
}

._sizeXs_4e3oje_48 ._main_4e3oje_110 {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}

._sizeS_4e3oje_49 ._main_4e3oje_110 {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 2px;
}

._sizeM_4e3oje_53 ._main_4e3oje_110 {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 2px;
}

._sizeL_4e3oje_57 ._main_4e3oje_110 {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 4px;
}

._close_4e3oje_146 {
  cursor: pointer;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  background: none;
  border: none;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;
  line-height: normal;
  display: inline-flex;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: visible;
}

._close_4e3oje_146::-moz-focus-inner {
  border: 0;
  padding: 0;
}

._close_4e3oje_146:active, ._close_4e3oje_146:hover, ._close_4e3oje_146:focus {
  outline: none;
  text-decoration: none;
}

._sizeXs_4e3oje_48 ._close_4e3oje_146, ._sizeS_4e3oje_49 ._close_4e3oje_146 {
  border-radius: 4px;
  width: 28px;
}

._sizeM_4e3oje_53 ._close_4e3oje_146 {
  border-radius: 6px;
  width: 36px;
}

._sizeL_4e3oje_57 ._close_4e3oje_146 {
  border-radius: 8px;
  width: 44px;
}

._disabled_4e3oje_65 ._close_4e3oje_146 {
  pointer-events: none;
}

._themeLight_4e3oje_61 ._close_4e3oje_146:hover {
  color: #19191c;
  background-color: #19191c1a;
}

._themeDark_4e3oje_69 ._close_4e3oje_146:hover {
  color: #fff;
  background-color: #ffffff1a;
}

._themeLight_4e3oje_61 ._close_4e3oje_146:active {
  color: #19191c;
  background-color: #19191c33;
}

._themeDark_4e3oje_69 ._close_4e3oje_146:active {
  color: #fff;
  background-color: #fff3;
}

._themeLight_4e3oje_61 ._close_4e3oje_146:focus[data-focus-method="key"] {
  -webkit-box-shadow: inset 0 0 0 4px #8979ff;
  -webkit-box-shadow: inset 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
  box-shadow: inset 0 0 0 4px #8979ff;
  box-shadow: inset 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
}

._themeDark_4e3oje_69 ._close_4e3oje_146:focus[data-focus-method="key"] {
  -webkit-box-shadow: inset 0 0 0 4px #6f61d2;
  -webkit-box-shadow: inset 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  box-shadow: inset 0 0 0 4px #6f61d2;
  box-shadow: inset 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
}

._icon_4e3oje_243 {
  vertical-align: top;
  fill: currentColor;
  flex: 1 0 auto;
  margin-right: 8px;
}

._sizeXs_4e3oje_48 ._icon_4e3oje_243 {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
}

._sizeS_4e3oje_49 ._icon_4e3oje_243 {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
}

._sizeM_4e3oje_53 ._icon_4e3oje_243 {
  width: 24px;
  height: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
}

._sizeL_4e3oje_57 ._icon_4e3oje_243 {
  width: 28px;
  height: 28px;
  margin-top: 8px;
  margin-bottom: 8px;
}

._wrapper_srqts_10 {
  position: relative;
}

._tag_srqts_14 {
  position: absolute;
}

._alignTop_srqts_20._sizeXs_srqts_21, ._alignTop_srqts_20._sizeS_srqts_24 {
  border-radius: 4px 4px 0 0;
}

._alignTop_srqts_20._sizeM_srqts_27 {
  border-radius: 6px 6px 0 0;
}

._alignTop_srqts_20._sizeL_srqts_30 {
  border-radius: 8px 8px 0 0;
}

._alignMiddle_srqts_35._sizeXs_srqts_21, ._alignMiddle_srqts_35._sizeS_srqts_24 {
  border-radius: 4px;
}

._alignMiddle_srqts_35._sizeM_srqts_27 {
  border-radius: 6px;
}

._alignMiddle_srqts_35._sizeL_srqts_30 {
  border-radius: 8px;
}

._top_srqts_52 {
  top: 0;
}

._top_srqts_52._start_srqts_56._offset16_srqts_57 {
  left: 16px;
}

._top_srqts_52._start_srqts_56._offset24_srqts_60 {
  left: 24px;
}

._top_srqts_52._start_srqts_56._offset32_srqts_63 {
  left: 32px;
}

._top_srqts_52._end_srqts_68._offset16_srqts_57 {
  right: 16px;
}

._top_srqts_52._end_srqts_68._offset24_srqts_60 {
  right: 24px;
}

._top_srqts_52._end_srqts_68._offset32_srqts_63 {
  right: 32px;
}

._top_srqts_52._alignTop_srqts_20 {
  transform: translate(0, -100%);
}

._top_srqts_52._alignMiddle_srqts_35 {
  transform: translate(0, -50%);
}

._left_srqts_90 {
  transform-origin: 100% 0;
  right: 100%;
}

._left_srqts_90._start_srqts_56._offset16_srqts_57 {
  top: 16px;
}

._left_srqts_90._start_srqts_56._offset24_srqts_60 {
  top: 24px;
}

._left_srqts_90._start_srqts_56._offset32_srqts_63 {
  top: 32px;
}

._left_srqts_90._end_srqts_68._offset16_srqts_57 {
  top: calc(100% - 16px);
}

._left_srqts_90._end_srqts_68._offset24_srqts_60 {
  top: calc(100% - 24px);
}

._left_srqts_90._end_srqts_68._offset32_srqts_63 {
  top: calc(100% - 32px);
}

._left_srqts_90._start_srqts_56._alignTop_srqts_20 {
  transform: rotate(270deg)translate(0, -100%);
}

._left_srqts_90._end_srqts_68._alignTop_srqts_20 {
  transform: rotate(270deg)translate(100%, -100%);
}

._left_srqts_90._start_srqts_56._alignMiddle_srqts_35 {
  transform: rotate(270deg)translate(0, -50%);
}

._left_srqts_90._end_srqts_68._alignMiddle_srqts_35 {
  transform: rotate(270deg)translate(100%, -50%);
}

.tablet_page {
  background: #27282c;
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.tablet_header {
  display: flex;
}

.tablet_date {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}

.fitness_list {
  -webkit-overflow-scrolling: touch;
  text-align: left;
  height: 100%;
  margin: 0;
  overflow-y: scroll;
}

.fitness_list li {
  list-style-type: none;
}

.tablet_help_message {
  flex-shrink: 0;
}

.content {
  background: #27282c;
  flex-direction: row;
  padding: 10px;
  display: flex;
}

.search {
  flex-grow: 1;
}

.employee_entry {
  padding: 5px;
  display: inline-block;
}

.focused {
  border: 3px dashed ivory;
  border-radius: 4px;
}

.marked {
  color: #000;
  background-color: #006400;
}

.focused.marked {
  border: 3px dashed #000;
}

.ineligible {
  opacity: .6;
}

.ineligible.marked {
  opacity: 1;
  color: #0003;
}

._hasBorderBottom_1ycflw8_14 {
  border-bottom: 1px solid;
}

._main_1ycflw8_18 {
  position: relative;
}

._main_1ycflw8_18._themeLight_1ycflw8_21 {
  color: var(--rs-typography-color-hard, #19191c);
  border-color: #19191c33;
}

._main_1ycflw8_18._themeDark_1ycflw8_27 {
  color: var(--rs-typography-color-hard, #fff);
  border-color: #fff3;
}

._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._opening_1ycflw8_33, ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._opened_1ycflw8_34, ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33._closing_1ycflw8_35 {
  border-bottom: none;
  padding-bottom: 1px;
}

@media (hover: hover) {
  ._main_1ycflw8_18._hasBorderBottom_1ycflw8_14._rock_1ycflw8_33:hover {
    border-bottom: none;
    padding-bottom: 1px;
  }
}

._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._opening_1ycflw8_33, ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._opened_1ycflw8_34, ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49._closing_1ycflw8_35 {
  background: #19191c0d;
}

@media (hover: hover) {
  ._main_1ycflw8_18._themeLight_1ycflw8_21._classic_1ycflw8_49:hover {
    background: #19191c0d;
  }
}

._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._opening_1ycflw8_33, ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._opened_1ycflw8_34, ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49._closing_1ycflw8_35 {
  background: #ffffff0d;
}

@media (hover: hover) {
  ._main_1ycflw8_18._themeDark_1ycflw8_27._classic_1ycflw8_49:hover {
    background: #ffffff0d;
  }
}

._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._opening_1ycflw8_33, ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._opened_1ycflw8_34, ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33._closing_1ycflw8_35 {
  --rs-typography-color-hard: #fff;
  --rs-typography-color-average: #ffffffb3;
  --rs-typography-color-pale: #ffffff80;
  --rs-typography-pre-color: #ffffffb3;
  --rs-typography-pre-bg-color: #ffffff1a;
  --rs-typography-classic-link-underline-color: #fff6;
  --rs-typography-rock-link-hover-color: #19191c;
  background: #19191c;
}

@media (hover: hover) {
  ._main_1ycflw8_18._themeLight_1ycflw8_21._rock_1ycflw8_33:hover {
    --rs-typography-color-hard: #fff;
    --rs-typography-color-average: #ffffffb3;
    --rs-typography-color-pale: #ffffff80;
    --rs-typography-pre-color: #ffffffb3;
    --rs-typography-pre-bg-color: #ffffff1a;
    --rs-typography-classic-link-underline-color: #fff6;
    --rs-typography-rock-link-hover-color: #19191c;
    background: #19191c;
  }
}

._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._opening_1ycflw8_33, ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._opened_1ycflw8_34, ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33._closing_1ycflw8_35 {
  --rs-typography-color-hard: #19191c;
  --rs-typography-color-average: #19191cb3;
  --rs-typography-color-pale: #19191c80;
  --rs-typography-pre-color: #19191c;
  --rs-typography-pre-bg-color: #19191c1a;
  --rs-typography-classic-link-underline-color: #19191c66;
  --rs-typography-rock-link-hover-color: #fff;
  background: #fff;
}

@media (hover: hover) {
  ._main_1ycflw8_18._themeDark_1ycflw8_27._rock_1ycflw8_33:hover {
    --rs-typography-color-hard: #19191c;
    --rs-typography-color-average: #19191cb3;
    --rs-typography-color-pale: #19191c80;
    --rs-typography-pre-color: #19191c;
    --rs-typography-pre-bg-color: #19191c1a;
    --rs-typography-classic-link-underline-color: #19191c66;
    --rs-typography-rock-link-hover-color: #fff;
    background: #fff;
  }
}

._title_1ycflw8_106 {
  color: inherit;
  flex: auto;
}

._sizeS_1ycflw8_111 > ._button_1ycflw8_111 > ._title_1ycflw8_106 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-h5-font-size, 13px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 20px;
  line-height: var(--rs-h5-line-height, 20px);
}

._sizeM_1ycflw8_114 > ._button_1ycflw8_111 > ._title_1ycflw8_106 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-h4-font-size, 16px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 24px;
  line-height: var(--rs-h4-line-height, 24px);
}

._sizeL_1ycflw8_117 > ._button_1ycflw8_111 > ._title_1ycflw8_106 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-h3-font-size, 20px);
  font-weight: 530;
  font-weight: var(--rs-font-weight-semi-bold, 530);
  line-height: 28px;
  line-height: var(--rs-h3-line-height, 28px);
}

._content_1ycflw8_122 {
  will-change: height;
  color: inherit;
  height: 0;
}

._closed_1ycflw8_129 > ._content_1ycflw8_122 {
  display: none;
}

._opened_1ycflw8_34 > ._content_1ycflw8_122 {
  height: initial;
}

._opening_1ycflw8_33 > ._content_1ycflw8_122, ._closingTransition_1ycflw8_138 > ._content_1ycflw8_122 {
  transition: height .3s;
  overflow: hidden;
}

._sizeS_1ycflw8_111 > ._content_1ycflw8_122 {
  letter-spacing: .0045em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 13px;
  font-size: var(--rs-text-3-font-size, 13px);
  line-height: 20px;
  line-height: var(--rs-text-3-line-height, 20px);
}

._rs-reset-letter-spacing_1ycflw8_1 ._sizeS_1ycflw8_111 > ._content_1ycflw8_122 {
  letter-spacing: normal;
}

._sizeM_1ycflw8_114 > ._content_1ycflw8_122 {
  letter-spacing: .0015em;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 16px;
  font-size: var(--rs-text-2-font-size, 16px);
  font-weight: 400;
  line-height: 24px;
  line-height: var(--rs-text-2-line-height, 24px);
}

._rs-reset-letter-spacing_1ycflw8_1 ._sizeM_1ycflw8_114 > ._content_1ycflw8_122 {
  letter-spacing: normal;
}

._sizeL_1ycflw8_117 > ._content_1ycflw8_122 {
  letter-spacing: normal;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  font-family: var(--rs-font-family-ui, var(--rs-font-family-jb-sans, "JetBrains Sans", Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Droid Sans", "Helvetica Neue", Arial, sans-serif));
  -webkit-font-feature-settings: "kern", "liga", "calt";
  font-feature-settings: "kern", "liga", "calt";
  font-size: 20px;
  font-size: var(--rs-text-1-font-size, 20px);
  font-weight: normal;
  line-height: 28px;
  line-height: var(--rs-text-1-line-height, 28px);
}

._sizeS_1ycflw8_111._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 8px 8px 36px;
}

._sizeS_1ycflw8_111._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155, ._sizeS_1ycflw8_111._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 8px 8px;
}

._sizeM_1ycflw8_114._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 8px 8px 40px;
}

._sizeM_1ycflw8_114._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155, ._sizeM_1ycflw8_114._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 8px 8px;
}

._sizeL_1ycflw8_117._leftIcon_1ycflw8_156 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 16px 16px 60px;
}

._sizeL_1ycflw8_117._rightIcon_1ycflw8_160 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155, ._sizeL_1ycflw8_117._disableLeftPadding_1ycflw8_161 > ._content_1ycflw8_122 > ._innerWrapper_1ycflw8_155 {
  padding: 0 16px 16px;
}

._button_1ycflw8_111 {
  cursor: pointer;
  text-align: left;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: none;
  border: none;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  line-height: normal;
  display: flex;
  position: relative;
  overflow: visible;
}

._button_1ycflw8_111::-moz-focus-inner {
  border: 0;
  padding: 0;
}

._sizeS_1ycflw8_111 > ._button_1ycflw8_111 {
  padding: 6px 8px;
}

._sizeM_1ycflw8_114 > ._button_1ycflw8_111 {
  padding: 8px;
}

._sizeL_1ycflw8_117 > ._button_1ycflw8_111 {
  padding: 12px 16px;
}

._button_1ycflw8_111:focus {
  outline: none;
}

._clear_1ycflw8_233 > ._button_1ycflw8_111 {
  transition: color .1s;
}

._classic_1ycflw8_49 > ._button_1ycflw8_111, ._rock_1ycflw8_33 > ._button_1ycflw8_111 {
  transition: background-color .1s;
}

._themeLight_1ycflw8_21 ._button_1ycflw8_111 {
  color: var(--rs-typography-color-hard, #19191c);
}

._themeDark_1ycflw8_27 ._button_1ycflw8_111 {
  color: var(--rs-typography-color-hard, #fff);
}

._themeLight_1ycflw8_21 > ._button_1ycflw8_111:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #6b57ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #6b57ffcc;
  box-shadow: var(--rs-color-primary-t-dim-light-theme, #6b57ffcc) 0 0 0 4px;
}

._themeDark_1ycflw8_27 > ._button_1ycflw8_111:focus[data-focus-method="key"] {
  -webkit-box-shadow: 0 0 0 4px #8473ffcc;
  -webkit-box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
  box-shadow: 0 0 0 4px #8473ffcc;
  box-shadow: var(--rs-color-primary-t-dim-dark-theme, #8473ffcc) 0 0 0 4px;
}

@media (hover: hover) {
  ._themeLight_1ycflw8_21._classic_1ycflw8_49 > ._button_1ycflw8_111:hover {
    background: #19191c0d;
  }

  ._themeDark_1ycflw8_27._classic_1ycflw8_49 > ._button_1ycflw8_111:hover {
    background: #ffffff0d;
  }

  ._themeLight_1ycflw8_21._rock_1ycflw8_33 > ._button_1ycflw8_111:hover {
    --rs-typography-color-hard: #fff;
    --rs-typography-color-average: #ffffffb3;
    --rs-typography-color-pale: #ffffff80;
    --rs-typography-pre-color: #ffffffb3;
    --rs-typography-pre-bg-color: #ffffff1a;
    --rs-typography-classic-link-underline-color: #fff6;
    --rs-typography-rock-link-hover-color: #19191c;
    background: #ffffff1a;
  }

  ._themeDark_1ycflw8_27._rock_1ycflw8_33 > ._button_1ycflw8_111:hover {
    --rs-typography-color-hard: #19191c;
    --rs-typography-color-average: #19191cb3;
    --rs-typography-color-pale: #19191c80;
    --rs-typography-pre-color: #19191c;
    --rs-typography-pre-bg-color: #19191c1a;
    --rs-typography-classic-link-underline-color: #19191c66;
    --rs-typography-rock-link-hover-color: #fff;
    background: #19191c1a;
  }

  ._themeLight_1ycflw8_21._clear_1ycflw8_233 > ._button_1ycflw8_111:hover {
    color: var(--rs-typography-color-average, #19191cb3);
  }

  ._themeDark_1ycflw8_27._clear_1ycflw8_233 > ._button_1ycflw8_111:hover {
    color: var(--rs-typography-color-average, #ffffffb3);
  }
}

._icon_1ycflw8_281 {
  will-change: transform;
  transform-origin: center;
  flex: none;
  transition: transform .3s, -webkit-transform .3s;
  position: relative;
  top: 0;
  left: 0;
}

._sizeS_1ycflw8_111 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  width: 20px;
  height: 20px;
}

._sizeM_1ycflw8_114 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  width: 24px;
  height: 24px;
}

._sizeL_1ycflw8_117 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  width: 28px;
  height: 28px;
}

._sizeS_1ycflw8_111._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-right: 8px;
}

._sizeS_1ycflw8_111._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-left: 8px;
}

._sizeM_1ycflw8_114._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-right: 8px;
}

._sizeM_1ycflw8_114._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-left: 8px;
}

._sizeL_1ycflw8_117._leftIcon_1ycflw8_156 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-right: 16px;
}

._sizeL_1ycflw8_117._rightIcon_1ycflw8_160 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  margin-left: 16px;
}

._opening_1ycflw8_33._iconPlus_1ycflw8_327 > ._button_1ycflw8_111 > ._icon_1ycflw8_281, ._opened_1ycflw8_34._iconPlus_1ycflw8_327 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  transform: rotate(45deg);
}

._opening_1ycflw8_33._iconArrow_1ycflw8_332 > ._button_1ycflw8_111 > ._icon_1ycflw8_281, ._opened_1ycflw8_34._iconArrow_1ycflw8_332 > ._button_1ycflw8_111 > ._icon_1ycflw8_281 {
  transform: rotate(90deg);
}

._accordion_1ycflw8_338 {
  flex-direction: column;
  display: flex;
}

._accordionGap0_1ycflw8_342 {
  row-gap: 0;
}

._accordionGap8_1ycflw8_345 {
  row-gap: 8px;
}

._accordionGap12_1ycflw8_348 {
  row-gap: 12px;
}

._accordionGap16_1ycflw8_351 {
  row-gap: 16px;
}

._accordionGap24_1ycflw8_354 {
  row-gap: 24px;
}

._accordionGap32_1ycflw8_357 {
  row-gap: 32px;
}

._icon_1hrrzc1_3 {
  fill: currentColor;
}

._sizeXS_1hrrzc1_7 {
  width: 16px;
  height: 16px;
}

._sizeS_1hrrzc1_12 {
  width: 20px;
  height: 20px;
}

._sizeM_1hrrzc1_17 {
  width: 24px;
  height: 24px;
}

._sizeL_1hrrzc1_22 {
  width: 28px;
  height: 28px;
}

._light_1hrrzc1_27 {
  fill: #19191c;
}

._dark_1hrrzc1_31 {
  fill: #fff;
}

.timesheet_table {
  border-collapse: collapse;
}

.timesheet_table td, .timesheet_table th {
  padding-left: 10px;
  padding-right: 10px;
}

.inspection_error {
  text-align: center;
  color: red;
  font-weight: bold;
}
/*# sourceMappingURL=index.4f0ef318.css.map */
