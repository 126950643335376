._wrapper_134p28p_9{
  position:relative;
}
._tabsContainer_134p28p_13{
  position:relative;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;
}
._indicator_134p28p_19{
  position:absolute;
  z-index:3;
  bottom:0;

  width:100%;
  height:2px;

  -webkit-transition:left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  transition:left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  will-change:left, width;
}
._light_134p28p_31._classic_134p28p_31 ._indicator_134p28p_19{
    background-color:#6B57FF;
    background-color:var(--rs-color-primary-light-theme, #6B57FF);
  }
._dark_134p28p_35._classic_134p28p_31 ._indicator_134p28p_19{
    background-color:#8473FF;
    background-color:var(--rs-color-primary-dark-theme, #8473FF);
  }
._light_134p28p_31._rock_134p28p_39 ._indicator_134p28p_19{
    background-color:#19191C;
  }
._dark_134p28p_35._rock_134p28p_39 ._indicator_134p28p_19{
    background-color:#FFFFFF;
  }
._tab_134p28p_13{
  position:relative;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;
  overflow:hidden;
  -ms-flex-negative:0;
      flex-shrink:0;
  -webkit-box-flex:0;
      -ms-flex-positive:0;
          flex-grow:0;
  -ms-flex-preferred-size:initial;
      flex-basis:initial;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  margin:0;
  padding:0;

  border:none;

  outline:none;

  list-style:none;

  cursor:pointer;
  white-space:nowrap;
  text-decoration:none;

  color:inherit;
  background:transparent;
  -webkit-box-shadow:none;
          box-shadow:none;

  font:inherit;
  line-height:normal;
  -webkit-font-smoothing:inherit;
  -moz-osx-font-smoothing:inherit;
  -webkit-appearance:none;
}
._tab_134p28p_13::-moz-focus-inner{
    padding:0;

    border:none;
  }
._tab_134p28p_13:hover,
  ._tab_134p28p_13:active,
  ._tab_134p28p_13:focus{
    outline:none;

    text-decoration:none;
  }
._tab_134p28p_13:after{
    position:absolute;
    bottom:0;
    left:0;

    width:100%;
    height:0;

    content:'';

    background-color:transparent;
  }
._tab_134p28p_13:focus[data-focus-method='key']:after{
    opacity:0;
  }
._tab_134p28p_13:hover:after{
    height:1px;
  }
._sizeM_134p28p_125 ._tab_134p28p_13{
    letter-spacing:0.0015em;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:16px;
    font-size:var(--rs-text-2-font-size, 16px);
    font-weight:400;
    line-height:24px;
    line-height:var(--rs-text-2-line-height, 24px);
  }
._rs-reset-letter-spacing_134p28p_1 ._sizeM_134p28p_125 ._tab_134p28p_13{
    letter-spacing:normal;
  }
._sizeM_134p28p_125 ._tab_134p28p_13 {
    padding:8px 0;
}
._sizeL_134p28p_130 ._tab_134p28p_13{
    letter-spacing:normal;
    font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
    font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
    -webkit-font-feature-settings:"kern", "liga", "calt";
    font-feature-settings:"kern", "liga", "calt";
    font-size:20px;
    font-size:var(--rs-text-1-font-size, 20px);
    font-weight:normal;
    line-height:28px;
    line-height:var(--rs-text-1-line-height, 28px);
    padding:12px 0;
  }
._innerOffsetM_134p28p_135 ._tab_134p28p_13{
    margin-left:24px;
  }
@media screen and (max-width: 640px){
._innerOffsetM_134p28p_135 ._tab_134p28p_13{
      margin-left:16px
  }
    }
._innerOffsetL_134p28p_143 ._tab_134p28p_13{
    margin-left:32px;
  }
@media screen and (max-width: 640px){
._innerOffsetL_134p28p_143 ._tab_134p28p_13{
      margin-left:24px
  }
    }
._light_134p28p_31 ._tab_134p28p_13{
    color:rgba(25,25,28,0.7);
  }
._dark_134p28p_35 ._tab_134p28p_13{
    color:rgba(255,255,255,0.7);
  }
._light_134p28p_31 ._tab_134p28p_13._short_134p28p_159{
    -webkit-box-shadow:inset 0 -1px 0 rgba(25,25,28,0.3);
            box-shadow:inset 0 -1px 0 rgba(25,25,28,0.3);
  }
._dark_134p28p_35 ._tab_134p28p_13._short_134p28p_159{
    -webkit-box-shadow:inset 0 -1px 0 rgba(255,255,255,0.4);
            box-shadow:inset 0 -1px 0 rgba(255,255,255,0.4);
  }
._tab_134p28p_13._selected_134p28p_167{
    z-index:1;

    cursor:default;

    -webkit-transition:color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    transition:color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
._tab_134p28p_13._selected_134p28p_167:after{
      height:2px;

      -webkit-transition:background-color 0ms ease 300ms, height 0ms ease 300ms;

      transition:background-color 0ms ease 300ms, height 0ms ease 300ms;
    }
._light_134p28p_31 ._tab_134p28p_13:hover{
    color:#19191C;
  }
._light_134p28p_31 ._tab_134p28p_13:hover:after{
      background-color:#19191C;
    }
._dark_134p28p_35 ._tab_134p28p_13:hover{
    color:#FFFFFF;
  }
._dark_134p28p_35 ._tab_134p28p_13:hover:after{
      background-color:#FFFFFF;
    }
._light_134p28p_31._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167{
    color:#19191C;
  }
._light_134p28p_31._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167:after{
      background-color:#19191C;
    }
._dark_134p28p_35._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167{
    color:#FFFFFF;
  }
._dark_134p28p_35._rock_134p28p_39 ._tab_134p28p_13._selected_134p28p_167:after{
      background-color:#FFFFFF;
    }
._light_134p28p_31._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167{
    color:#6B57FF;
    color:var(--rs-color-primary-light-theme, #6B57FF);
  }
._light_134p28p_31._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167:after{
      background-color:#6B57FF;
      background-color:var(--rs-color-primary-light-theme, #6B57FF);
    }
._dark_134p28p_35._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167{
    color:#8473FF;
    color:var(--rs-color-primary-dark-theme, #8473FF);
  }
._dark_134p28p_35._classic_134p28p_31 ._tab_134p28p_13._selected_134p28p_167:after{
      background-color:#8473FF;
      background-color:var(--rs-color-primary-dark-theme, #8473FF);
    }
._light_134p28p_31 ._tab_134p28p_13:focus[data-focus-method='key']{
    -webkit-box-shadow:inset #8979ff 0 -4px 0 0, inset #8979ff 0 4px 0 0, #8979ff -4px 0 0 0, #8979ff 4px 0 0 0;
    -webkit-box-shadow:inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 -4px 0 0, inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 4px 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) -4px 0 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) 4px 0 0 0;
            box-shadow:inset #8979ff 0 -4px 0 0, inset #8979ff 0 4px 0 0, #8979ff -4px 0 0 0, #8979ff 4px 0 0 0;
            box-shadow:inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 -4px 0 0, inset var(--rs-color-primary-dim-light-theme, #8979ff) 0 4px 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) -4px 0 0 0, var(--rs-color-primary-dim-light-theme, #8979ff) 4px 0 0 0;
  }
._light_134p28p_31 ._tab_134p28p_13:focus[data-focus-method='key']:after{
      opacity:0;
    }
._dark_134p28p_35 ._tab_134p28p_13:focus[data-focus-method='key']{
    -webkit-box-shadow:inset #6f61d2 0 -4px 0 0, inset #6f61d2 0 4px 0 0, #6f61d2 -4px 0 0 0, #6f61d2 4px 0 0 0;
    -webkit-box-shadow:inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 -4px 0 0, inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 4px 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) -4px 0 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) 4px 0 0 0;
            box-shadow:inset #6f61d2 0 -4px 0 0, inset #6f61d2 0 4px 0 0, #6f61d2 -4px 0 0 0, #6f61d2 4px 0 0 0;
            box-shadow:inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 -4px 0 0, inset var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 4px 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) -4px 0 0 0, var(--rs-color-primary-dim-dark-theme, #6f61d2) 4px 0 0 0;
  }
._dark_134p28p_35 ._tab_134p28p_13:focus[data-focus-method='key']:after{
      opacity:0;
    }
._innerOffsetL_134p28p_143 ._tab_134p28p_13:first-child,
  ._innerOffsetM_134p28p_135 ._tab_134p28p_13:first-child{
    margin-left:0;
  }
@media screen and (max-width: 640px){
._innerOffsetL_134p28p_143 ._tab_134p28p_13:first-child,
  ._innerOffsetM_134p28p_135 ._tab_134p28p_13:first-child{
      margin-left:0
  }
    }
._icon_134p28p_269{
  -webkit-box-flex:0;
      -ms-flex:0 0;
          flex:0 0;
  -ms-flex-preferred-size:auto;
      flex-basis:auto;
}
._sizeM_134p28p_125 ._icon_134p28p_269{
    width:24px;
    height:24px;
  }
._sizeL_134p28p_130 ._icon_134p28p_269{
    width:28px;
    height:28px;
  }
._sizeM_134p28p_125 ._icon_134p28p_269._iconLeft_134p28p_283{
    margin-right:4px;
  }
._sizeL_134p28p_130 ._icon_134p28p_269._iconLeft_134p28p_283{
    margin-right:8px;
  }
._sizeM_134p28p_125 ._icon_134p28p_269._iconRight_134p28p_291{
    margin-left:4px;
  }
._sizeL_134p28p_130 ._icon_134p28p_269._iconRight_134p28p_291{
    margin-left:8px;
  }

._wrapper_vrgn9m_4{
  position:relative;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;
}
._scrollable_vrgn9m_10{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow-x:scroll;

  margin:-4px;
  padding:4px;
}
._scrollable_vrgn9m_10::after{
    display:block;

    height:100%;

    border-left:4px solid transparent;

    content:'';
  }
._scrollable_vrgn9m_10 {

  -ms-overflow-style:none;
  scrollbar-width:none;
}
._scrollable_vrgn9m_10::-webkit-scrollbar{
    display:none;
  }
._arrow_vrgn9m_39{
  position:absolute;
  z-index:2;
  top:50%;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;

  opacity:0;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  height:100%;

  pointer-events:none;

  background-color:transparent;

  -webkit-transition:opacity 0.15s ease-out;

  transition:opacity 0.15s ease-out;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}
._light_vrgn9m_58 ._arrow_vrgn9m_39{
    color:#FFFFFF;
  }
._dark_vrgn9m_61 ._arrow_vrgn9m_39{
    color:#19191C;
  }
._arrow_vrgn9m_39._shown_vrgn9m_65{
    opacity:1;

    pointer-events:auto;

    -webkit-transition:opacity 0.15s ease-in;

    transition:opacity 0.15s ease-in;
  }
._arrow_vrgn9m_39._arrowLeft_vrgn9m_73{
    left:-5px;
  }
._arrow_vrgn9m_39._arrowRight_vrgn9m_78{
    right:-5px;
  }
._withLeftArrow_vrgn9m_84{
  -webkit-mask-image:-webkit-gradient(
    linear,
    left top, right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    to(black)
  );
  -webkit-mask-image:linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px
  );
          mask-image:-webkit-gradient(
    linear,
    left top, right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    to(black)
  );
          mask-image:linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px
  );
}
._withRightArrow_vrgn9m_93{
  -webkit-mask-image:-webkit-gradient(
    linear,
    right top, left top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    to(black)
  );
  -webkit-mask-image:linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px
  );
          mask-image:-webkit-gradient(
    linear,
    right top, left top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    to(black)
  );
          mask-image:linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px
  );
}
._withLeftArrow_vrgn9m_84._withRightArrow_vrgn9m_93{
  -webkit-mask-image:-webkit-gradient(
    linear,
    left top, right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    color-stop(black),
    color-stop(black),
    color-stop(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
  -webkit-mask-image:linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px,
    black calc(100% - 56px),
    rgba(0, 0, 0, 0) calc(100% - 32px),
    rgba(0, 0, 0, 0) 100%
  );
          mask-image:-webkit-gradient(
    linear,
    left top, right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(32px, rgba(0, 0, 0, 0)),
    color-stop(black),
    color-stop(black),
    color-stop(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
          mask-image:linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32px,
    black 56px,
    black calc(100% - 56px),
    rgba(0, 0, 0, 0) calc(100% - 32px),
    rgba(0, 0, 0, 0) 100%
  );
}
._arrowButton_vrgn9m_114{
  cursor:pointer;
}

._separator_1dayppn_3{
  position:relative;
  z-index:2;

  width:100%;
}

  ._separator_1dayppn_3:after{
    position:absolute;
    right:0;
    bottom:0;
    left:0;

    height:1px;

    content:'';
  }
._light_1dayppn_22:after{
    background-color:rgba(25,25,28,0.3);
  }
._dark_1dayppn_28:after{
    background-color:rgba(255,255,255,0.4);
  }
