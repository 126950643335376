._main_1uoszgd_25{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
  ._rs-reset-letter-spacing_1uoszgd_1 ._main_1uoszgd_25{
    letter-spacing:normal;
  }
  ._main_1uoszgd_25 {

  position:relative;

  display:-webkit-inline-box;

  display:-ms-inline-flexbox;

  display:inline-flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  -webkit-box-sizing:border-box;

          box-sizing:border-box;

  border:1px solid transparent;
  outline:none;

  text-align:center;
  white-space:nowrap;
  text-decoration:none;

  background:transparent;
}
._alignIconLeft_1uoszgd_46{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;
}
._alignIconRight_1uoszgd_50{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:reverse;
      -ms-flex-direction:row-reverse;
          flex-direction:row-reverse;
}
._sizeXs_1uoszgd_54{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_1uoszgd_1 ._sizeXs_1uoszgd_54{
    letter-spacing:normal;
  }
._sizeXs_1uoszgd_54 {

  padding:1px 7px;
}
._sizeS_1uoszgd_61{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_1uoszgd_1 ._sizeS_1uoszgd_61{
    letter-spacing:normal;
  }
._sizeS_1uoszgd_61 {

  padding:3px 7px;
}
._sizeM_1uoszgd_68{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_1uoszgd_1 ._sizeM_1uoszgd_68{
    letter-spacing:normal;
  }
._sizeM_1uoszgd_68 {

  padding:5px 11px;
}
._sizeL_1uoszgd_75{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);

  padding:7px 15px;
}
._icon_1uoszgd_82{
  fill:currentColor;
}
._sizeXs_1uoszgd_54 ._icon_1uoszgd_82{
    width:20px;
    height:20px;
  }
._sizeS_1uoszgd_61 ._icon_1uoszgd_82{
    width:20px;
    height:20px;
  }
._sizeM_1uoszgd_68 ._icon_1uoszgd_82{
    width:24px;
    height:24px;
  }
._sizeL_1uoszgd_75 ._icon_1uoszgd_82{
    width:28px;
    height:28px;
  }
._alignIconLeft_1uoszgd_46 ._icon_1uoszgd_82{
    margin-right:8px;
    margin-left:0;
  }
._alignIconRight_1uoszgd_50 ._icon_1uoszgd_82{
    margin-right:0;
    margin-left:8px;
  }
._uppercase_1uoszgd_116{
  text-transform:uppercase;

  font-weight:530;

  font-weight:var(--rs-font-weight-semi-bold, 530);
}
._uppercase_1uoszgd_116._sizeS_1uoszgd_61{
    padding-right:9px;
    padding-left:9px;
  }

._sizeXs_qp3fbt_9{
  border-radius:4px;
}
._sizeS_qp3fbt_13{
  border-radius:4px;
}
._sizeM_qp3fbt_17{
  border-radius:6px;
}
._sizeL_qp3fbt_21{
  border-radius:8px;
}

._wrapper_4e3oje_36{
  position:relative;

  display:-webkit-inline-box;

  display:-ms-inline-flexbox;

  display:inline-flex;

  white-space:nowrap;
}

  ._wrapper_4e3oje_36 > *{
    vertical-align:top;
  }
._sizeXs_4e3oje_48,
._sizeS_4e3oje_49{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_4e3oje_1 ._sizeXs_4e3oje_48,._rs-reset-letter-spacing_4e3oje_1 ._sizeS_4e3oje_49{
    letter-spacing:normal;
  }
._sizeM_4e3oje_53{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_4e3oje_1 ._sizeM_4e3oje_53{
    letter-spacing:normal;
  }
._sizeL_4e3oje_57{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);
}
._themeLight_4e3oje_61{
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
._themeLight_4e3oje_61._disabled_4e3oje_65{
  color:rgba(25,25,28,0.5);
  color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
}
._themeDark_4e3oje_69{
  color:rgba(255,255,255,0.7);
  color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
}
._themeDark_4e3oje_69._disabled_4e3oje_65{
  color:rgba(255,255,255,0.5);
  color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
}
._disabled_4e3oje_65{
  pointer-events:none;
}
._sizeXs_4e3oje_48{
  padding-left:8px;

  border-radius:4px;
}
._sizeS_4e3oje_49{
  padding-left:8px;

  border-radius:4px;
}
._sizeM_4e3oje_53{
  padding-left:12px;

  border-radius:6px;
}
._sizeL_4e3oje_57{
  padding-left:16px;

  border-radius:8px;
}
._themeLight_4e3oje_61{
  background-color:rgba(25,25,28,0.1);
}
._themeDark_4e3oje_69{
  background-color:rgba(255,255,255,0.1);
}
._main_4e3oje_110{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  white-space:nowrap;

  background:transparent;
}
._wrap_4e3oje_36 ._main_4e3oje_110{
    white-space:normal;
  }
._sizeXs_4e3oje_48 ._main_4e3oje_110{
    padding-top:2px;
    padding-right:2px;
    padding-bottom:2px;
  }
._sizeS_4e3oje_49 ._main_4e3oje_110{
    padding-top:4px;
    padding-right:2px;
    padding-bottom:4px;
  }
._sizeM_4e3oje_53 ._main_4e3oje_110{
    padding-top:6px;
    padding-right:2px;
    padding-bottom:6px;
  }
._sizeL_4e3oje_57 ._main_4e3oje_110{
    padding-top:8px;
    padding-right:4px;
    padding-bottom:8px;
  }
._close_4e3oje_146{
  top:0;
  right:0;
  bottom:0;

  display:-webkit-inline-box;

  display:-ms-inline-flexbox;

  display:inline-flex;
  overflow:visible;
  -webkit-box-flex:1;
      -ms-flex:1 0 auto;
          flex:1 0 auto;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  width:auto;
  padding:0;

  border:none;

  cursor:pointer;

  color:inherit;
  background:transparent;

  font:inherit;
  line-height:normal;
  -webkit-font-smoothing:inherit;
  -moz-osx-font-smoothing:inherit;
}
._close_4e3oje_146::-moz-focus-inner{
    padding:0;

    border:0;
  }
._close_4e3oje_146:active,
  ._close_4e3oje_146:hover,
  ._close_4e3oje_146:focus{
    outline:none;

    text-decoration:none;
  }
._sizeXs_4e3oje_48 ._close_4e3oje_146{
    width:28px;

    border-radius:4px;
  }
._sizeS_4e3oje_49 ._close_4e3oje_146{
    width:28px;

    border-radius:4px;
  }
._sizeM_4e3oje_53 ._close_4e3oje_146{
    width:36px;

    border-radius:6px;
  }
._sizeL_4e3oje_57 ._close_4e3oje_146{
    width:44px;

    border-radius:8px;
  }
._disabled_4e3oje_65 ._close_4e3oje_146{
    pointer-events:none;
  }
._themeLight_4e3oje_61 ._close_4e3oje_146:hover{
    color:#19191C;
    background-color:rgba(25,25,28,0.1);
  }
._themeDark_4e3oje_69 ._close_4e3oje_146:hover{
    color:#FFFFFF;
    background-color:rgba(255,255,255,0.1);
  }
._themeLight_4e3oje_61 ._close_4e3oje_146:active{
    color:#19191C;
    background-color:rgba(25,25,28,0.2);
  }
._themeDark_4e3oje_69 ._close_4e3oje_146:active{
    color:#FFFFFF;
    background-color:rgba(255,255,255,0.2);
  }
._themeLight_4e3oje_61 ._close_4e3oje_146:focus[data-focus-method='key']{
    -webkit-box-shadow:inset 0 0 0 4px #8979ff;
    -webkit-box-shadow:inset 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
            box-shadow:inset 0 0 0 4px #8979ff;
            box-shadow:inset 0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
  }
._themeDark_4e3oje_69 ._close_4e3oje_146:focus[data-focus-method='key']{
    -webkit-box-shadow:inset 0 0 0 4px #6f61d2;
    -webkit-box-shadow:inset 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
            box-shadow:inset 0 0 0 4px #6f61d2;
            box-shadow:inset 0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  }
._icon_4e3oje_243{
  -webkit-box-flex:1;
      -ms-flex:1 0 auto;
          flex:1 0 auto;

  margin-right:8px;

  vertical-align:top;
  fill:currentColor;
}
._sizeXs_4e3oje_48 ._icon_4e3oje_243{
    width:20px;
    height:20px;
    margin-top:2px;
    margin-bottom:2px;
  }
._sizeS_4e3oje_49 ._icon_4e3oje_243{
    width:20px;
    height:20px;
    margin-top:4px;
    margin-bottom:4px;
  }
._sizeM_4e3oje_53 ._icon_4e3oje_243{
    width:24px;
    height:24px;
    margin-top:6px;
    margin-bottom:6px;
  }
._sizeL_4e3oje_57 ._icon_4e3oje_243{
    width:28px;
    height:28px;
    margin-top:8px;
    margin-bottom:8px;
  }

._wrapper_srqts_10{
  position:relative;
}
._tag_srqts_14{
  position:absolute;
}
._alignTop_srqts_20._sizeXs_srqts_21{
    border-radius:4px 4px 0 0;
  }
._alignTop_srqts_20._sizeS_srqts_24{
    border-radius:4px 4px 0 0;
  }
._alignTop_srqts_20._sizeM_srqts_27{
    border-radius:6px 6px 0 0;
  }
._alignTop_srqts_20._sizeL_srqts_30{
    border-radius:8px 8px 0 0;
  }
._alignMiddle_srqts_35._sizeXs_srqts_21{
    border-radius:4px;
  }
._alignMiddle_srqts_35._sizeS_srqts_24{
    border-radius:4px;
  }
._alignMiddle_srqts_35._sizeM_srqts_27{
    border-radius:6px;
  }
._alignMiddle_srqts_35._sizeL_srqts_30{
    border-radius:8px;
  }
._top_srqts_52{
  top:0;
}
._top_srqts_52._start_srqts_56._offset16_srqts_57{
    left:16px;
  }
._top_srqts_52._start_srqts_56._offset24_srqts_60{
    left:24px;
  }
._top_srqts_52._start_srqts_56._offset32_srqts_63{
    left:32px;
  }
._top_srqts_52._end_srqts_68._offset16_srqts_57{
    right:16px;
  }
._top_srqts_52._end_srqts_68._offset24_srqts_60{
    right:24px;
  }
._top_srqts_52._end_srqts_68._offset32_srqts_63{
    right:32px;
  }
._top_srqts_52._alignTop_srqts_20{
  -webkit-transform:translate(0, -100%);
          transform:translate(0, -100%);
}
._top_srqts_52._alignMiddle_srqts_35{
  -webkit-transform:translate(0, -50%);
          transform:translate(0, -50%);
}
._left_srqts_90{
  right:100%;

  -webkit-transform-origin:100% 0;

          transform-origin:100% 0;
}
._left_srqts_90._start_srqts_56._offset16_srqts_57{
    top:16px;
  }
._left_srqts_90._start_srqts_56._offset24_srqts_60{
    top:24px;
  }
._left_srqts_90._start_srqts_56._offset32_srqts_63{
    top:32px;
  }
._left_srqts_90._end_srqts_68._offset16_srqts_57{
    top:calc(100% - 16px);
  }
._left_srqts_90._end_srqts_68._offset24_srqts_60{
    top:calc(100% - 24px);
  }
._left_srqts_90._end_srqts_68._offset32_srqts_63{
    top:calc(100% - 32px);
  }
._left_srqts_90._start_srqts_56._alignTop_srqts_20{
  -webkit-transform:rotate(270deg) translate(0, -100%);
          transform:rotate(270deg) translate(0, -100%);
}
._left_srqts_90._end_srqts_68._alignTop_srqts_20{
  -webkit-transform:rotate(270deg) translate(100%, -100%);
          transform:rotate(270deg) translate(100%, -100%);
}
._left_srqts_90._start_srqts_56._alignMiddle_srqts_35{
  -webkit-transform:rotate(270deg) translate(0, -50%);
          transform:rotate(270deg) translate(0, -50%);
}
._left_srqts_90._end_srqts_68._alignMiddle_srqts_35{
  -webkit-transform:rotate(270deg) translate(100%, -50%);
          transform:rotate(270deg) translate(100%, -50%);
}
