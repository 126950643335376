@import "~@rescui/colors/src/simple-vars.p.css";

.icon {
  fill: currentcolor;
}

.sizeXS {
  width: 16px;
  height: 16px;
}

.sizeS {
  width: 20px;
  height: 20px;
}

.sizeM {
  width: 24px;
  height: 24px;
}

.sizeL {
  width: 28px;
  height: 28px;
}

.light {
  fill: $rs-color-black;
}

.dark {
  fill: $rs-color-white;
}

@keyframes :global(rs-icon-rotate) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
