._tooltip_1qwmbuc_4{
  position:relative;
}
._closing_1qwmbuc_8{
  pointer-events:none;
}
._content_1qwmbuc_12{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_1qwmbuc_1 ._content_1qwmbuc_12{
    letter-spacing:normal;
  }
._content_1qwmbuc_12 {

  max-width:283px;

  padding:8px;

  border-radius:4px;

  color:#FFFFFF;
  background-color:#303033;

  -webkit-transition:opacity 300ms, -webkit-transform 300ms;

  transition:opacity 300ms, -webkit-transform 300ms;

  transition:opacity 300ms, transform 300ms;

  transition:opacity 300ms, transform 300ms, -webkit-transform 300ms;
  -webkit-transform:translate(0, 0);
          transform:translate(0, 0);
}
[data-real-placement='top-end'] ._content_1qwmbuc_12,
  [data-real-placement='bottom-end'] ._content_1qwmbuc_12{
    margin-right:0;
  }
[data-real-placement='top-start'] ._content_1qwmbuc_12,
  [data-real-placement='bottom-start'] ._content_1qwmbuc_12{
    margin-left:0;
  }
[data-real-placement='left-end'] ._content_1qwmbuc_12,
  [data-real-placement='right-end'] ._content_1qwmbuc_12{
    margin-bottom:0;
  }
[data-real-placement='left-start'] ._content_1qwmbuc_12,
  [data-real-placement='right-start'] ._content_1qwmbuc_12{
    margin-top:0;
  }
._initial_1qwmbuc_47 ._content_1qwmbuc_12{
    -webkit-transition:none;
    transition:none;
  }
._closing_1qwmbuc_8 ._content_1qwmbuc_12{
    -webkit-transition-duration:200ms, 200ms;
            transition-duration:200ms, 200ms;
  }
._initial_1qwmbuc_47 ._content_1qwmbuc_12,
  ._closing_1qwmbuc_8 ._content_1qwmbuc_12{
    opacity:0;
  }
._initial_1qwmbuc_47[data-real-placement^='top'] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^='top'] ._content_1qwmbuc_12{
    -webkit-transform:translate(0, 10px);
            transform:translate(0, 10px);
  }
._initial_1qwmbuc_47[data-real-placement^='right'] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^='right'] ._content_1qwmbuc_12{
    -webkit-transform:translate(-10px, 0);
            transform:translate(-10px, 0);
  }
._initial_1qwmbuc_47[data-real-placement^='bottom'] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^='bottom'] ._content_1qwmbuc_12{
    -webkit-transform:translate(0, -10px);
            transform:translate(0, -10px);
  }
._initial_1qwmbuc_47[data-real-placement^='left'] ._content_1qwmbuc_12, ._closing_1qwmbuc_8[data-real-placement^='left'] ._content_1qwmbuc_12{
    -webkit-transform:translate(10px, 0);
            transform:translate(10px, 0);
  }
._trigger_1qwmbuc_81:focus-visible,
._trigger_1qwmbuc_81:focus[data-focus-method='key']{
    outline:none;
    -webkit-box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 4px;
            box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 4px;
            box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 4px;
}

