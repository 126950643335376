._wrapper_imuce_8{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}
._menuItem_imuce_12{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow:auto;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;

  width:auto;

  margin:0;

  border:none;
  border-radius:0;
  outline:none;

  cursor:pointer;
  text-align:start;
  text-decoration:none;

  background:none;
  -webkit-appearance:none;
  -moz-appearance:none;
       appearance:none;
  -webkit-font-smoothing:inherit;
  -moz-osx-font-smoothing:inherit;
}
._menuItem_imuce_12:focus{
    outline:none;
  }
._content_imuce_40{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;

  font-size:inherit;
}
._placementRight_imuce_46{
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}
._sizeS_imuce_51{
  padding: 6px 8px;
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_imuce_1 ._sizeS_imuce_51{
    letter-spacing:normal;
  }
._sizeM_imuce_56{
  padding: 8px 16px;
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_imuce_1 ._sizeM_imuce_56{
    letter-spacing:normal;
  }
._sizeL_imuce_61{
  padding: 12px 16px;
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);
}
._modeClear_imuce_66._sizeS_imuce_51,
._modeClear_imuce_66._sizeM_imuce_56,
._modeClear_imuce_66._sizeL_imuce_61{
  padding-right:0;
  padding-left:0;
}
._themeLight_imuce_73,
._themeLight_imuce_73:focus{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._themeDark_imuce_78,
._themeDark_imuce_78:focus{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}
._keyboardNavigationDisabled_imuce_83 ._themeLight_imuce_73:focus[data-focus-method='key']{
    -webkit-box-shadow:0 0 0 2px #8979ff, inset 0 0 0 2px #8979ff;
    -webkit-box-shadow:0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff), inset 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff);
            box-shadow:0 0 0 2px #8979ff, inset 0 0 0 2px #8979ff;
            box-shadow:0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff), inset 0 0 0 2px var(--rs-color-primary-dim-light-theme, #8979ff);
  }
._keyboardNavigationDisabled_imuce_83 ._themeDark_imuce_78:focus[data-focus-method='key']{
    -webkit-box-shadow:0 0 0 2px #6f61d2, inset 0 0 0 2px #6f61d2;
    -webkit-box-shadow:0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2), inset 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2);
            box-shadow:0 0 0 2px #6f61d2, inset 0 0 0 2px #6f61d2;
            box-shadow:0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2), inset 0 0 0 2px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  }
._modeClassic_imuce_99._themeLight_imuce_73:active{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
  background-color:rgba(107, 87, 255,0.2);
  background-color:var(--rs-color-primary-t-fog-light-theme, rgba(107, 87, 255,0.2));
}
._modeClassic_imuce_99._themeDark_imuce_78:active{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:rgba(132, 115, 255,0.3);
  background-color:var(--rs-color-primary-t-fog-dark-theme, rgba(132, 115, 255,0.3));
}
._modeClassic_imuce_99._themeLight_imuce_73._selected_imuce_111{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:#19191C;
}
._modeClassic_imuce_99._themeDark_imuce_78._selected_imuce_111{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
  background-color:#FFFFFF;
}
._modeRock_imuce_121._themeLight_imuce_73._selected_imuce_111{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:#19191C;
}
._modeRock_imuce_121._themeDark_imuce_78._selected_imuce_111{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
  background-color:#FFFFFF;
}
._modeClear_imuce_66._themeLight_imuce_73._selected_imuce_111{
  color:#6B57FF;
  color:var(--rs-color-primary-light-theme, #6B57FF);
}
._modeClear_imuce_66._themeDark_imuce_78._selected_imuce_111{
  color:#8473FF;
  color:var(--rs-color-primary-dark-theme, #8473FF);
}
._disableCursor_imuce_141{
  pointer-events:none;
}
._modeClassic_imuce_99._themeLight_imuce_73._highlighted_imuce_146,
._modeClassic_imuce_99._themeLight_imuce_73:not(._disableCursor_imuce_141):hover{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
  background-color:rgba(25,25,28,0.1);
}
._modeRock_imuce_121._themeLight_imuce_73._highlighted_imuce_146,
._modeRock_imuce_121._themeLight_imuce_73:not(._disableCursor_imuce_141):hover{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:#6B57FF;
  background-color:var(--rs-color-primary-light-theme, #6B57FF);
}
._modeClassic_imuce_99._themeDark_imuce_78._highlighted_imuce_146,
._modeClassic_imuce_99._themeDark_imuce_78:not(._disableCursor_imuce_141):hover{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:rgba(255,255,255,0.1);
}
._modeRock_imuce_121._themeDark_imuce_78._highlighted_imuce_146,
._modeRock_imuce_121._themeDark_imuce_78:not(._disableCursor_imuce_141):hover{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
  background-color:#8473FF;
  background-color:var(--rs-color-primary-dark-theme, #8473FF);
}
._modeClear_imuce_66._themeLight_imuce_73._highlighted_imuce_146,
._modeClear_imuce_66._themeLight_imuce_73:not(._disableCursor_imuce_141):hover{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._modeClear_imuce_66._themeDark_imuce_78._highlighted_imuce_146,
._modeClear_imuce_66._themeDark_imuce_78:not(._disableCursor_imuce_141):hover{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}
._modeClassic_imuce_99._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111,
._modeClassic_imuce_99._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:#303033;
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}
._modeRock_imuce_121._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111,
._modeRock_imuce_121._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:#303033;
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}
._modeClassic_imuce_99._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111,
._modeClassic_imuce_99._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:#E8E8E8;
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._modeRock_imuce_121._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111,
._modeRock_imuce_121._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:#E8E8E8;
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._modeClear_imuce_66._themeLight_imuce_73._highlighted_imuce_146._selected_imuce_111,
._modeClear_imuce_66._themeLight_imuce_73._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:rgba(25,25,28,0.1);
}
._modeClear_imuce_66._themeDark_imuce_78._highlighted_imuce_146._selected_imuce_111,
._modeClear_imuce_66._themeDark_imuce_78._selected_imuce_111:not(._disableCursor_imuce_141):hover{
  background-color:rgba(255,255,255,0.1);
}
._menuItem_imuce_12._themeLight_imuce_73._disabled_imuce_229{
  pointer-events:none;
  color:rgba(25,25,28,0.5);
  color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
}
._menuItem_imuce_12._themeDark_imuce_78._disabled_imuce_229{
  pointer-events:none;
  color:rgba(255,255,255,0.5);
  color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
}
._icon_imuce_240{
  -webkit-box-flex:0;
      -ms-flex:0 0;
          flex:0 0;
  -ms-flex-preferred-size:auto;
      flex-basis:auto;

}
._sizeS_imuce_51._placementLeft_imuce_244 ._icon_imuce_240{
    margin-right:8px;
  }
._sizeS_imuce_51._placementRight_imuce_46 ._icon_imuce_240{
    margin-left:8px;
  }
._sizeM_imuce_56._placementLeft_imuce_244 ._icon_imuce_240{
    margin-right:8px;
  }
._sizeM_imuce_56._placementRight_imuce_46 ._icon_imuce_240{
    margin-left:8px;
  }
._sizeL_imuce_61._placementLeft_imuce_244 ._icon_imuce_240{
    margin-right:16px;
  }
._sizeL_imuce_61._placementRight_imuce_46 ._icon_imuce_240{
    margin-left:16px;
  }
._divider_imuce_270{
  -ms-flex-negative:0;
      flex-shrink:0;

  width:100%;
  height:1px;
  margin:0;

  border:none;
}
._divider_imuce_270._themeLight_imuce_73{
    background-color:rgba(25,25,28,0.2);
  }
._divider_imuce_270._themeDark_imuce_78{
    background-color:rgba(255,255,255,0.2);
  }
._groupHeader_imuce_288{
  border:none;
}
._groupHeaderSizeL_imuce_292{
  padding: 12px 16px;
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-h3-font-size, 20px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:28px;
  line-height:var(--rs-h3-line-height, 28px);
}
._groupHeaderSizeM_imuce_297{
  padding: 8px 16px;
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-h4-font-size, 16px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:24px;
  line-height:var(--rs-h4-line-height, 24px);
}
._groupHeaderSizeS_imuce_302{
  padding: 6px 8px;
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-h5-font-size, 13px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:20px;
  line-height:var(--rs-h5-line-height, 20px);
}
._groupHeaderThemeLight_imuce_307{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._groupHeaderThemeDark_imuce_311{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}

