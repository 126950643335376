@-webkit-keyframes _fadeInTopToBottom_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateY(-10px);
            transform:translateY(-10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@keyframes _fadeInTopToBottom_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateY(-10px);
            transform:translateY(-10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@-webkit-keyframes _fadeInBottomToTop_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateY(10px);
            transform:translateY(10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@keyframes _fadeInBottomToTop_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateY(10px);
            transform:translateY(10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@-webkit-keyframes _fadeInLeftToRight_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateX(-10px);
            transform:translateX(-10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@keyframes _fadeInLeftToRight_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateX(-10px);
            transform:translateX(-10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@-webkit-keyframes _fadeInRightToLeft_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateX(10px);
            transform:translateX(10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
@keyframes _fadeInRightToLeft_1bu2l6k_1{
  0%{
    opacity:0;
    -webkit-transform:translateX(10px);
            transform:translateX(10px);
  }
  100%{
    opacity:1;
    -webkit-transform:none;
            transform:none;
  }
}
._dropdown_1bu2l6k_50{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}
._triggerWrapper_1bu2l6k_55{
  display:inline-block;
}
._contentWrapper_1bu2l6k_59{
  display:block;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  position:relative;
  overflow-y:auto;
  background-clip:padding-box;
  margin:1px;
  -webkit-transition:opacity linear 200ms, -webkit-transform linear 200ms;
  transition:opacity linear 200ms, -webkit-transform linear 200ms;
  transition:opacity linear 200ms, transform linear 200ms;
  transition:opacity linear 200ms, transform linear 200ms, -webkit-transform linear 200ms;
}
[data-popper-placement^="top" ] ._contentWrapper_1bu2l6k_59{
  -webkit-animation:_fadeInBottomToTop_1bu2l6k_1 ease 200ms;
          animation:_fadeInBottomToTop_1bu2l6k_1 ease 200ms;
}
[data-popper-placement^="bottom" ] ._contentWrapper_1bu2l6k_59{
  -webkit-animation:_fadeInTopToBottom_1bu2l6k_1 ease 200ms;
          animation:_fadeInTopToBottom_1bu2l6k_1 ease 200ms;
}
[data-popper-placement^="right"] ._contentWrapper_1bu2l6k_59{
  -webkit-animation:_fadeInLeftToRight_1bu2l6k_1 ease 200ms;
          animation:_fadeInLeftToRight_1bu2l6k_1 ease 200ms;
}
[data-popper-placement^="left"] ._contentWrapper_1bu2l6k_59{
  -webkit-animation:_fadeInRightToLeft_1bu2l6k_1 ease 200ms;
          animation:_fadeInRightToLeft_1bu2l6k_1 ease 200ms;
}
._wrapperHidden_1bu2l6k_85{
  opacity:0;
}
[data-popper-placement^="top" ] ._wrapperHidden_1bu2l6k_85{
  -webkit-transform:translateY(10px);
          transform:translateY(10px);
}
[data-popper-placement^="bottom" ] ._wrapperHidden_1bu2l6k_85{
  -webkit-transform:translateY(-10px);
          transform:translateY(-10px);
}
[data-popper-placement^="right"] ._wrapperHidden_1bu2l6k_85{
  -webkit-transform:translateX(-10px);
          transform:translateX(-10px);
}
[data-popper-placement^="left"] ._wrapperHidden_1bu2l6k_85{
  -webkit-transform:translateX(10px);
          transform:translateX(10px);
}
._themeLight_1bu2l6k_105 ._contentWrapper_1bu2l6k_59{
  background-color:#FFFFFF;
  -webkit-box-shadow:0 0 0 1px #D1D1D2;
          box-shadow:0 0 0 1px #D1D1D2;
}
._themeLight_1bu2l6k_105._modeRock_1bu2l6k_110 ._contentWrapper_1bu2l6k_59{
  background-color:#19191C;
}
._themeDark_1bu2l6k_114 ._contentWrapper_1bu2l6k_59{
  background-color:#303033;
  -webkit-box-shadow:0 0 0 1px #757577;
          box-shadow:0 0 0 1px #757577;
}
._themeDark_1bu2l6k_114._modeRock_1bu2l6k_110 ._contentWrapper_1bu2l6k_59{
  background-color:#FFFFFF;
}
._modeRock_1bu2l6k_110{
  --rs-parent-color-primary-light-theme:var(--rs-color-primary-light-theme);
  --rs-parent-color-primary-dim-light-theme:var(--rs-color-primary-dim-light-theme);
  --rs-parent-color-primary-fog-light-theme:var(--rs-color-primary-fog-light-theme);
  --rs-parent-color-primary-t-dim-light-theme:var(--rs-color-primary-t-dim-light-theme);
  --rs-parent-color-primary-t-fog-light-theme:var(--rs-color-primary-t-fog-light-theme);

  --rs-parent-color-primary-dark-theme:var(--rs-color-primary-dark-theme);
  --rs-parent-color-primary-dim-dark-theme:var(--rs-color-primary-dim-dark-theme);
  --rs-parent-color-primary-fog-dark-theme:var(--rs-color-primary-fog-dark-theme);
  --rs-parent-color-primary-t-dim-dark-theme:var(--rs-color-primary-t-dim-dark-theme);
  --rs-parent-color-primary-t-fog-dark-theme:var(--rs-color-primary-t-fog-dark-theme);
}
._modeRock_1bu2l6k_110 > *{
  --rs-color-primary-dark-theme:var(--rs-parent-color-primary-light-theme);
  --rs-color-primary-dim-dark-theme:var(--rs-parent-color-primary-dim-light-theme);
  --rs-color-primary-fog-dark-theme:var(--rs-parent-color-primary-fog-light-theme);
  --rs-color-primary-t-dim-dark-theme:var(--rs-parent-color-primary-t-dim-light-theme);
  --rs-color-primary-t-fog-dark-theme:var(--rs-parent-color-primary-t-fog-light-theme);

  --rs-color-primary-light-theme:var(--rs-parent-color-primary-dark-theme);
  --rs-color-primary-dim-light-theme:var(--rs-parent-color-primary-dim-dark-theme);
  --rs-color-primary-fog-light-theme:var(--rs-parent-color-primary-fog-dark-theme);
  --rs-color-primary-t-dim-light-theme:var(--rs-parent-color-primary-t-dim-dark-theme);
  --rs-color-primary-t-fog-light-theme:var(--rs-parent-color-primary-t-fog-dark-theme);
}
