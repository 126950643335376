.book-entry {
    display: flex;
    position: relative;
    padding: 24px 0;
}

.book-entry .cover-image {
    width: 96px;
}

.book-entry .cover {
    float: left;
    padding-right: 1em;
    padding-bottom: 1em;
}

.book-entry .copy-list {
    flex: 1;
}

.book-entry .view {
    flex: 3;
}

.book-entry .view .title {
    margin-bottom: 8px;
}

.book-entry .view .description {
    color: var(--rs-color-grey-60);
    line-height: 1.6;
}

.book-entry .view .link {
    margin-left: 12px;
}

.edit-book-details textarea {
    width: 100%;
}
.edit-book-details input {
    margin-bottom: 8px;
}
.edit-book-details .links-and-loading {
    display: flex;
}
.edit-book-details .links-input {
    flex-grow: 1;
}

.book-entry .edit-copy input {
    margin-bottom: 8px;
}

.book-entry .collapsed {
    max-height: 15em;
    overflow: hidden;
}

.book-list .jetid-logo {
    width: 72px;
    height: 72px;
}

.book-list .tab {
    margin-top: 20px;
    margin-bottom: 20px;
}

.book-list .tab2-header {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 256px;
}

.book-list .tab2-header > * {
    vertical-align: middle;
}

.book-list .section .there-is-more {
    margin: 20px;
    font-size: 150%;
    text-align: center;
}

.book-list .no-results {
    margin: 20px;
    font-size: 30px;
    text-align: center;
}

.book-list .filtering-section {
    display: flex;
}

.book-list .filtering-section > * {
    margin: 12px 0 12px 0
}
