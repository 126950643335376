._main_9e8ws0_24{
  position:relative;

  display:-webkit-box;

  display:-ms-flexbox;

  display:flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;

  -webkit-box-sizing:border-box;

          box-sizing:border-box;

  border-width:1px;
  border-style:solid;
}

  ._main_9e8ws0_24:focus,
  ._main_9e8ws0_24._open_9e8ws0_36{
    outline:none;
  }
._enabled_9e8ws0_41{
  cursor:pointer;
}
._disabled_9e8ws0_45{
  pointer-events:none;
}
._focused_9e8ws0_49,
._searchFocused_9e8ws0_50,
._open_9e8ws0_36{
  border-width:2px;
}
._sizeL_9e8ws0_56{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  height:52px;
}
._sizeM_9e8ws0_59{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_9e8ws0_1 ._sizeM_9e8ws0_59{
    letter-spacing:normal;
  }
._sizeM_9e8ws0_59 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  height:40px;
}
._sizeS_9e8ws0_62{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_9e8ws0_1 ._sizeS_9e8ws0_62{
    letter-spacing:normal;
  }
._sizeS_9e8ws0_62 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  height:32px;
}
._isMulti_9e8ws0_136{
  height:auto;
}
._error_9e8ws0_69{
  padding:0;
}
._notSelected_9e8ws0_144{
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
._themeLight_9e8ws0_148{
  border-color:rgba(25,25,28,0.2);

  background-color:#FFFFFF;
}
._themeLight_9e8ws0_148[data-focus-method="key"],
  ._themeLight_9e8ws0_148._searchKeyFocused_9e8ws0_154{
    -webkit-box-shadow:0 0 0 4px #8979ff;
    -webkit-box-shadow:0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
            box-shadow:0 0 0 4px #8979ff;
            box-shadow:0 0 0 4px var(--rs-color-primary-dim-light-theme, #8979ff);
  }
._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover{
    border-color:rgba(25,25,28,0.5);
  }
._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36,
  ._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50,
  ._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49{
    border-color:#19191C;
  }
._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36,
  ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50,
  ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49{
    background-color:#19191C;
  }
._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50:hover ._toggle_9e8ws0_173, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49:hover ._toggle_9e8ws0_173{
      color:#FFFFFF;
      color:var(--rs-typography-color-hard, #FFFFFF);
    }
._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._labelWrapper_9e8ws0_177, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._labelWrapper_9e8ws0_177, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._labelWrapper_9e8ws0_177{
      color:#FFFFFF;
      color:var(--rs-typography-color-hard, #FFFFFF);
    }
._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._notSelected_9e8ws0_144, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._notSelected_9e8ws0_144, ._themeLight_9e8ws0_148._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._notSelected_9e8ws0_144{
      color:rgba(255,255,255,0.5);
      color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
    }
._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover > ._toggle_9e8ws0_173{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
._themeLight_9e8ws0_148 ._labelWrapper_9e8ws0_177{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
._themeLight_9e8ws0_148 ._notSelected_9e8ws0_144{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
._themeDark_9e8ws0_199{
  border-color:rgba(255,255,255,0.2);

  background-color:#303033;
}
._themeDark_9e8ws0_199[data-focus-method="key"],
  ._themeDark_9e8ws0_199._searchKeyFocused_9e8ws0_154{
    -webkit-box-shadow:0 0 0 4px #6f61d2;
    -webkit-box-shadow:0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
            box-shadow:0 0 0 4px #6f61d2;
            box-shadow:0 0 0 4px var(--rs-color-primary-dim-dark-theme, #6f61d2);
  }
._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover{
    border-color:rgba(255,255,255,0.5);

    background-color:#474749;
  }
._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36,
  ._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50,
  ._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49{
    border-color:#FFFFFF;
  }
._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36,
  ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50,
  ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49{
    background-color:#FFFFFF;
  }
._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50:hover ._toggle_9e8ws0_173, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49:hover ._toggle_9e8ws0_173{
      color:#19191C;
      color:var(--rs-typography-color-hard, #19191C);
    }
._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._labelWrapper_9e8ws0_177, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._labelWrapper_9e8ws0_177, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._labelWrapper_9e8ws0_177{
      color:#19191C;
      color:var(--rs-typography-color-hard, #19191C);
    }
._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._open_9e8ws0_36 ._notSelected_9e8ws0_144, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._notSelected_9e8ws0_144, ._themeDark_9e8ws0_199._rock_9e8ws0_168._enabled_9e8ws0_41._focused_9e8ws0_49 ._notSelected_9e8ws0_144{
      color:rgba(25,25,28,0.5);
      color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
    }
._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover > ._toggle_9e8ws0_173{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._themeDark_9e8ws0_199 ._labelWrapper_9e8ws0_177{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._themeDark_9e8ws0_199 ._notSelected_9e8ws0_144{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
._error_9e8ws0_69,
._error_9e8ws0_69._error_9e8ws0_69:hover,
._error_9e8ws0_69._error_9e8ws0_69._searchFocused_9e8ws0_50,
._error_9e8ws0_69._error_9e8ws0_69._open_9e8ws0_36,
._error_9e8ws0_69._error_9e8ws0_69._focused_9e8ws0_49,
._error_9e8ws0_69._error_9e8ws0_69._disabled_9e8ws0_45,
._error_9e8ws0_69._error_9e8ws0_69._disabled_9e8ws0_45:hover{
  border-width:2px;
  border-color:#F45C4A;
}
._themeLight_9e8ws0_148._disabled_9e8ws0_45{
  border-color:rgba(25,25,28,0.2);

  color:rgba(25,25,28,0.5);

  color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  background-color:#F4F4F4;
}
._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._label_9e8ws0_177{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
._themeDark_9e8ws0_199._disabled_9e8ws0_45{
  border-color:rgba(255,255,255,0.2);

  color:rgba(255,255,255,0.5);

  color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  background-color:#19191C;
}
._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._label_9e8ws0_177{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
._sizeL_9e8ws0_56 ._iconsWrapper_9e8ws0_285{
    padding: 11px 15px;
  }
._sizeM_9e8ws0_59 ._iconsWrapper_9e8ws0_285{
    padding: 7px 7px;
  }
._sizeS_9e8ws0_62 ._iconsWrapper_9e8ws0_285{
    padding: 5px 7px;
  }
._sizeL_9e8ws0_56._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285,
  ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285,
  ._sizeL_9e8ws0_56._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285,
  ._sizeL_9e8ws0_56._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285{
    padding: 10px 14px;
  }
._sizeM_9e8ws0_59._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285,
  ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285,
  ._sizeM_9e8ws0_59._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285,
  ._sizeM_9e8ws0_59._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285{
    padding: 6px 6px;
  }
._sizeS_9e8ws0_62._focused_9e8ws0_49 ._iconsWrapper_9e8ws0_285,
  ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._iconsWrapper_9e8ws0_285,
  ._sizeS_9e8ws0_62._open_9e8ws0_36 ._iconsWrapper_9e8ws0_285,
  ._sizeS_9e8ws0_62._error_9e8ws0_69 ._iconsWrapper_9e8ws0_285{
    padding: 4px 6px;
  }
._iconsWrapper_9e8ws0_285 {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-negative:0;
      flex-shrink:0;
  -ms-flex-preferred-size:auto;
      flex-basis:auto;

  -webkit-box-sizing:content-box;

          box-sizing:content-box;

  padding-left:0 !important;

  -webkit-user-select:none;

     -moz-user-select:none;

      -ms-user-select:none;

          user-select:none;
}
._sizeS_9e8ws0_62 ._iconsWrapper_9e8ws0_285{
    height:20px;
  }
._sizeM_9e8ws0_59 ._iconsWrapper_9e8ws0_285{
    height:24px;
  }
._sizeL_9e8ws0_56 ._iconsWrapper_9e8ws0_285{
    height:28px;
  }
._labelWrapper_9e8ws0_177{
  position:relative;

  -ms-flex-negative:1;

      flex-shrink:1;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;

  width:100%;
  min-width:0;
}
._sizeL_9e8ws0_56 ._label_9e8ws0_177{
    padding: 11px 15px;
  }
._sizeM_9e8ws0_59 ._label_9e8ws0_177{
    padding: 7px 7px;
  }
._sizeS_9e8ws0_62 ._label_9e8ws0_177{
    padding: 5px 7px;
  }
._sizeL_9e8ws0_56._focused_9e8ws0_49 ._label_9e8ws0_177,
  ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._label_9e8ws0_177,
  ._sizeL_9e8ws0_56._open_9e8ws0_36 ._label_9e8ws0_177,
  ._sizeL_9e8ws0_56._error_9e8ws0_69 ._label_9e8ws0_177{
    padding: 10px 14px;
  }
._sizeM_9e8ws0_59._focused_9e8ws0_49 ._label_9e8ws0_177,
  ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._label_9e8ws0_177,
  ._sizeM_9e8ws0_59._open_9e8ws0_36 ._label_9e8ws0_177,
  ._sizeM_9e8ws0_59._error_9e8ws0_69 ._label_9e8ws0_177{
    padding: 6px 6px;
  }
._sizeS_9e8ws0_62._focused_9e8ws0_49 ._label_9e8ws0_177,
  ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._label_9e8ws0_177,
  ._sizeS_9e8ws0_62._open_9e8ws0_36 ._label_9e8ws0_177,
  ._sizeS_9e8ws0_62._error_9e8ws0_69 ._label_9e8ws0_177{
    padding: 4px 6px;
  }
._label_9e8ws0_177 {

  overflow:hidden;
  -ms-flex-negative:1;
      flex-shrink:1;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;

  -webkit-box-sizing:border-box;

          box-sizing:border-box;
  height:100%;

  padding-right:0 !important;

  white-space:nowrap;
  text-overflow:ellipsis;
}
._label_9e8ws0_177:focus{
    outline:none;
  }
._sizeL_9e8ws0_56 ._searchInput_9e8ws0_340{
    padding: 11px 15px;
  }
._sizeM_9e8ws0_59 ._searchInput_9e8ws0_340{
    padding: 7px 7px;
  }
._sizeS_9e8ws0_62 ._searchInput_9e8ws0_340{
    padding: 5px 7px;
  }
._sizeL_9e8ws0_56._focused_9e8ws0_49 ._searchInput_9e8ws0_340,
  ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340,
  ._sizeL_9e8ws0_56._open_9e8ws0_36 ._searchInput_9e8ws0_340,
  ._sizeL_9e8ws0_56._error_9e8ws0_69 ._searchInput_9e8ws0_340{
    padding: 10px 14px;
  }
._sizeM_9e8ws0_59._focused_9e8ws0_49 ._searchInput_9e8ws0_340,
  ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340,
  ._sizeM_9e8ws0_59._open_9e8ws0_36 ._searchInput_9e8ws0_340,
  ._sizeM_9e8ws0_59._error_9e8ws0_69 ._searchInput_9e8ws0_340{
    padding: 6px 6px;
  }
._sizeS_9e8ws0_62._focused_9e8ws0_49 ._searchInput_9e8ws0_340,
  ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._searchInput_9e8ws0_340,
  ._sizeS_9e8ws0_62._open_9e8ws0_36 ._searchInput_9e8ws0_340,
  ._sizeS_9e8ws0_62._error_9e8ws0_69 ._searchInput_9e8ws0_340{
    padding: 4px 6px;
  }
._searchInput_9e8ws0_340 {
  position:absolute;
  top:0;
  left:0;

  display:block;

  opacity:1;

  -webkit-box-sizing:border-box;

          box-sizing:border-box;

  width:100%;
  height:100%;
  padding-right:0 !important;

  border:0;
  outline:0;

  color:inherit;

  background:transparent;

  font-size:inherit;
}
._sizeL_9e8ws0_56 ._multi_9e8ws0_366{
    padding: 7px 15px;
  }
._sizeM_9e8ws0_59 ._multi_9e8ws0_366{
    padding: 5px 7px;
  }
._sizeS_9e8ws0_62 ._multi_9e8ws0_366{
    padding: 3px 7px;
  }
._sizeL_9e8ws0_56._focused_9e8ws0_49 ._multi_9e8ws0_366,
  ._sizeL_9e8ws0_56._searchFocused_9e8ws0_50 ._multi_9e8ws0_366,
  ._sizeL_9e8ws0_56._open_9e8ws0_36 ._multi_9e8ws0_366,
  ._sizeL_9e8ws0_56._error_9e8ws0_69 ._multi_9e8ws0_366{
    padding: 6px 14px;
  }
._sizeM_9e8ws0_59._focused_9e8ws0_49 ._multi_9e8ws0_366,
  ._sizeM_9e8ws0_59._searchFocused_9e8ws0_50 ._multi_9e8ws0_366,
  ._sizeM_9e8ws0_59._open_9e8ws0_36 ._multi_9e8ws0_366,
  ._sizeM_9e8ws0_59._error_9e8ws0_69 ._multi_9e8ws0_366{
    padding: 4px 6px;
  }
._sizeS_9e8ws0_62._focused_9e8ws0_49 ._multi_9e8ws0_366,
  ._sizeS_9e8ws0_62._searchFocused_9e8ws0_50 ._multi_9e8ws0_366,
  ._sizeS_9e8ws0_62._open_9e8ws0_36 ._multi_9e8ws0_366,
  ._sizeS_9e8ws0_62._error_9e8ws0_69 ._multi_9e8ws0_366{
    padding: 2px 6px;
  }
._multi_9e8ws0_366 {
  position:relative;

  -webkit-box-flex:0;

      -ms-flex:0 1 100%;

          flex:0 1 100%;

  padding-right:0 !important;
}
._tagsContainer_9e8ws0_375{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow:hidden;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;

  width:100%;
}
._sizeL_9e8ws0_56 ._tagsContainer_9e8ws0_375{
    margin-top:-8px;
  }
._sizeM_9e8ws0_59 ._tagsContainer_9e8ws0_375{
    margin-top:-6px;
  }
._sizeS_9e8ws0_62 ._tagsContainer_9e8ws0_375{
    margin-top:-4px;
  }
._sizeL_9e8ws0_56 ._tag_9e8ws0_375{
    margin-top:8px;
    margin-right:8px;
  }
._sizeM_9e8ws0_59 ._tag_9e8ws0_375{
    margin-top:6px;
    margin-right:4px;
  }
._sizeS_9e8ws0_62 ._tag_9e8ws0_375{
    margin-top:4px;
    margin-right:4px;
  }
._sizeL_9e8ws0_56 ._tag_9e8ws0_375:last-child,
  ._sizeM_9e8ws0_59 ._tag_9e8ws0_375:last-child,
  ._sizeS_9e8ws0_62 ._tag_9e8ws0_375:last-child{
    margin-right:0;
  }
._toggle_9e8ws0_173,
._icon_9e8ws0_285{
  display:inline-block;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  fill:currentColor;
}
._enabled_9e8ws0_41 ._toggle_9e8ws0_173, ._enabled_9e8ws0_41 ._icon_9e8ws0_285{
    cursor:pointer;
  }
._themeLight_9e8ws0_148 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._toggle_9e8ws0_173,
  ._themeLight_9e8ws0_148 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._icon_9e8ws0_285{
    color:rgba(25,25,28,0.7);
    color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  }
._themeDark_9e8ws0_199 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._toggle_9e8ws0_173,
  ._themeDark_9e8ws0_199 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._icon_9e8ws0_285{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._focused_9e8ws0_49 ._toggle_9e8ws0_173,
  ._themeLight_9e8ws0_148._disabled_9e8ws0_45 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._open_9e8ws0_36 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._disabled_9e8ws0_45._focused_9e8ws0_49 ._icon_9e8ws0_285{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._focused_9e8ws0_49 ._toggle_9e8ws0_173,
  ._themeDark_9e8ws0_199._disabled_9e8ws0_45 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._open_9e8ws0_36 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._searchFocused_9e8ws0_50 ._icon_9e8ws0_285,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._disabled_9e8ws0_45._focused_9e8ws0_49 ._icon_9e8ws0_285{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
._sizeL_9e8ws0_56 ._toggle_9e8ws0_173, ._sizeL_9e8ws0_56 ._icon_9e8ws0_285{
    margin-left:16px;
  }
._sizeM_9e8ws0_59 ._toggle_9e8ws0_173, ._sizeM_9e8ws0_59 ._icon_9e8ws0_285{
    margin-left:8px;
  }
._sizeS_9e8ws0_62 ._toggle_9e8ws0_173, ._sizeS_9e8ws0_62 ._icon_9e8ws0_285{
    margin-left:8px;
  }
._toggle_9e8ws0_173{
  -webkit-transition:-webkit-transform 300ms;
  transition:-webkit-transform 300ms;
  transition:transform 300ms;
  transition:transform 300ms, -webkit-transform 300ms;
}
._open_9e8ws0_36 ._toggle_9e8ws0_173{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg);
  }
._themeLight_9e8ws0_148._enabled_9e8ws0_41:hover ._toggle_9e8ws0_173,
  ._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
._themeDark_9e8ws0_199._enabled_9e8ws0_41:hover ._toggle_9e8ws0_173,
  ._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._toggle_9e8ws0_173{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._clearIcon_9e8ws0_490{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow:visible;

  width:auto;
  margin:0;
  padding:0;

  border:none;

  color:inherit;

  background:transparent;

  font:inherit;

  line-height:normal;

  -webkit-font-smoothing:inherit;
  -moz-osx-font-smoothing:inherit;

  -webkit-appearance:none;
}
._clearIcon_9e8ws0_490::-moz-focus-inner{
    padding:0;

    border:0;
  }
._clearIcon_9e8ws0_490:focus,
  ._clearIcon_9e8ws0_490:active{
    outline:none;
  }
._themeLight_9e8ws0_148._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:focus[data-focus-method='key']{
    -webkit-box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 3px;
    -webkit-box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 3px;
            box-shadow:rgba(107, 87, 255, 0.8) 0 0 0 3px;
            box-shadow:var(--rs-color-primary-t-dim-light-theme, rgba(107, 87, 255, 0.8)) 0 0 0 3px;
  }
._themeDark_9e8ws0_199._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:focus[data-focus-method='key'],
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:focus[data-focus-method='key']{
    -webkit-box-shadow:rgba(132, 115, 255,0.8) 0 0 0 3px;
    -webkit-box-shadow:var(--rs-color-primary-t-dim-dark-theme, rgba(132, 115, 255,0.8)) 0 0 0 3px;
            box-shadow:rgba(132, 115, 255,0.8) 0 0 0 3px;
            box-shadow:var(--rs-color-primary-t-dim-dark-theme, rgba(132, 115, 255,0.8)) 0 0 0 3px;
  }
._themeLight_9e8ws0_148._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeDark_9e8ws0_199._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:hover{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
._themeDark_9e8ws0_199._enabled_9e8ws0_41 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._open_9e8ws0_36 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._searchFocused_9e8ws0_50 ._clearIcon_9e8ws0_490:hover,
  ._rock_9e8ws0_168._themeLight_9e8ws0_148._enabled_9e8ws0_41._focused_9e8ws0_49 ._clearIcon_9e8ws0_490:hover{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
._tag_9e8ws0_375{
  overflow:hidden;
}
._tag_9e8ws0_375 > span{
    overflow:hidden;

    text-overflow:ellipsis;
  }

._errorMessage_14zyrwg_4{
  color:#F45C4A;
}
._sizeL_14zyrwg_8 ._label_14zyrwg_9{
    margin-bottom:8px;
  }
._sizeL_14zyrwg_8 ._note_14zyrwg_12,
  ._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4{
    margin-top:8px;
  }
._sizeM_14zyrwg_17 ._label_14zyrwg_9{
    margin-bottom:8px;
  }
._sizeM_14zyrwg_17 ._note_14zyrwg_12,
  ._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4{
    margin-top:8px;
  }
._sizeS_14zyrwg_26 ._label_14zyrwg_9{
    margin-bottom:4px;
  }
._sizeS_14zyrwg_26 ._note_14zyrwg_12,
  ._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4{
    margin-top:4px;
  }
._themeLight_14zyrwg_36 ._note_14zyrwg_12{
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
._themeDark_14zyrwg_40 ._note_14zyrwg_12{
  color:rgba(255,255,255,0.7);
  color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
}
._sizeS_14zyrwg_26 ._note_14zyrwg_12,
._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4,
._sizeM_14zyrwg_17 ._note_14zyrwg_12,
._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._note_14zyrwg_12,._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._errorMessage_14zyrwg_4,._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._note_14zyrwg_12,._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._errorMessage_14zyrwg_4{
    letter-spacing:normal;
  }
._sizeL_14zyrwg_8 ._note_14zyrwg_12,
._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_14zyrwg_1 ._sizeL_14zyrwg_8 ._note_14zyrwg_12,._rs-reset-letter-spacing_14zyrwg_1 ._sizeL_14zyrwg_8 ._errorMessage_14zyrwg_4{
    letter-spacing:normal;
  }
._themeLight_14zyrwg_36 ._label_14zyrwg_9{
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
._themeDark_14zyrwg_40 ._label_14zyrwg_9{
  color:#FFFFFF;
  color:var(--rs-typography-color-hard, #FFFFFF);
}
._sizeS_14zyrwg_26 ._label_14zyrwg_9{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_14zyrwg_1 ._sizeS_14zyrwg_26 ._label_14zyrwg_9{
    letter-spacing:normal;
  }
._sizeM_14zyrwg_17 ._label_14zyrwg_9{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_14zyrwg_1 ._sizeM_14zyrwg_17 ._label_14zyrwg_9{
    letter-spacing:normal;
  }
._sizeL_14zyrwg_8 ._label_14zyrwg_9{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);
}
._sizeS_14zyrwg_26 ._label_14zyrwg_9._bold_14zyrwg_77{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-h5-font-size, 13px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:20px;
  line-height:var(--rs-h5-line-height, 20px);
}
._sizeM_14zyrwg_17 ._label_14zyrwg_9._bold_14zyrwg_77{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-h4-font-size, 16px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:24px;
  line-height:var(--rs-h4-line-height, 24px);
}
._sizeL_14zyrwg_8 ._label_14zyrwg_9._bold_14zyrwg_77{
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-h3-font-size, 20px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:28px;
  line-height:var(--rs-h3-line-height, 28px);
}



._menu_h0qydt_1{
  margin:0;
  padding:0;
  border:0;
  font:inherit;
  font-size:100%;
  vertical-align:baseline;
  list-style:none;
}

._main_1v4wzkb_3{
  position:relative;
}
._select_1v4wzkb_7{
  position:absolute;
  top:0;
  left:0;

  opacity:0;

  width:100%;
  height:100%;
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
}
._sizeL_1v4wzkb_19{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_1v4wzkb_1 ._sizeL_1v4wzkb_19{
    letter-spacing:normal;
  }
._sizeL_1v4wzkb_19 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
._sizeM_1v4wzkb_23{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
._rs-reset-letter-spacing_1v4wzkb_1 ._sizeM_1v4wzkb_23{
    letter-spacing:normal;
  }
._sizeM_1v4wzkb_23 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
._sizeS_1v4wzkb_27{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
._rs-reset-letter-spacing_1v4wzkb_1 ._sizeS_1v4wzkb_27{
    letter-spacing:normal;
  }
._sizeS_1v4wzkb_27 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
