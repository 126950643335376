.rs-font-smoothing{
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
.rs-super-hero{
  letter-spacing:-0.02em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:142px;
  font-size:var(--rs-super-hero-font-size, 142px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:142px;
  line-height:var(--rs-super-hero-line-height, 142px);
}
@media screen and (max-width: 1000px){
.rs-super-hero{
    --rs-super-hero-font-size:var(--rs-hero-font-size, 72px);
    --rs-super-hero-line-height:var(--rs-hero-line-height, 72px);
    letter-spacing:-0.005em;
}
  }
@media screen and (max-width: 640px){
.rs-super-hero{
    --rs-super-hero-font-size:var(--rs-hero-font-size-mobile, 42px);
    --rs-super-hero-line-height:var(--rs-hero-line-height-mobile, 50px);
    letter-spacing:-0.001em;
}
  }
.rs-reset-letter-spacing .rs-super-hero{
    letter-spacing:normal;
  }
.rs-super-hero {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-super-hero_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-super-hero_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-digits-1{
  letter-spacing:-0.005em;
  font-variant-numeric:tabular-nums;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:69px;
  font-size:var(--rs-digits-1-font-size, 69px);
  font-weight:320;
  font-weight:var(--rs-font-weight-light, 320);
  line-height:71px;
  line-height:var(--rs-digits-1-line-height, 71px);
}
@media screen and (max-width: 640px){
.rs-digits-1{
    --rs-digits-1-font-size:var(--rs-digits-2-font-size, 40px);
    --rs-digits-1-line-height:var(--rs-digits-2-line-height, 44px);
}
  }
.rs-reset-letter-spacing .rs-digits-1{
    letter-spacing:normal;
  }
.rs-digits-1 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-digits-1_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-digits-1_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-digits-2{
  letter-spacing:-0.005em;
  font-variant-numeric:tabular-nums;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:40px;
  font-size:var(--rs-digits-2-font-size, 40px);
  font-weight:320;
  font-weight:var(--rs-font-weight-light, 320);
  line-height:44px;
  line-height:var(--rs-digits-2-line-height, 44px);
}
.rs-reset-letter-spacing .rs-digits-2{
    letter-spacing:normal;
  }
.rs-digits-2 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-digits-2_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-digits-2_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-hero{
  letter-spacing:-0.005em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:72px;
  font-size:var(--rs-hero-font-size, 72px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:72px;
  line-height:var(--rs-hero-line-height, 72px);
}
@media screen and (max-width: 640px){
.rs-hero{
    --rs-hero-font-size:var(--rs-hero-font-size-mobile, 42px);
    --rs-hero-line-height:var(--rs-hero-font-size-mobile, 50px);
    letter-spacing:-0.001em;
}
  }
.rs-reset-letter-spacing .rs-hero{
    letter-spacing:normal;
  }
.rs-hero {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-hero_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-hero_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-subtitle-1{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:39px;
  font-size:var(--rs-subtitle-1-font-size, 39px);
  font-weight:320;
  font-weight:var(--rs-font-weight-light, 320);
  line-height:49px;
  line-height:var(--rs-subtitle-1-line-height, 49px);
}
@media screen and (max-width: 640px){
.rs-subtitle-1{
    --rs-subtitle-1-font-size:var(--rs-subtitle-2-font-size-mobile, 23px);
    --rs-subtitle-1-line-height:var(--rs-subtitle-2-line-height-mobile, 30px);
}
  }
.rs-subtitle-1 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-subtitle-1_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-subtitle-1_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-h1{
  letter-spacing:-0.001em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:43px;
  font-size:var(--rs-h1-font-size, 43px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:49px;
  line-height:var(--rs-h1-line-height, 49px);
}
@media screen and (max-width: 640px){
.rs-h1{
    --rs-h1-font-size:var(--rs-h1-font-size-mobile, 35px);
    --rs-h1-line-height:var(--rs-h1-line-height-mobile, 39px);
    letter-spacing:normal;
}
  }
.rs-h1 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-h1_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-h1_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-subtitle-2{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:29px;
  font-size:var(--rs-subtitle-2-font-size, 29px);
  font-weight:320;
  font-weight:var(--rs-font-weight-light, 320);
  line-height:39px;
  line-height:var(--rs-subtitle-2-line-height, 39px);
}
@media screen and (max-width: 640px){
.rs-subtitle-2{
    --rs-subtitle-2-font-size:var(--rs-subtitle-2-font-size-mobile, 23px);
    --rs-subtitle-2-line-height:var(--rs-subtitle-2-line-height-mobile, 30px);
}
  }
.rs-subtitle-2 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-subtitle-2_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-subtitle-2_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-h2{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-headers, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:35px;
  font-size:var(--rs-h2-font-size, 35px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:42px;
  line-height:var(--rs-h2-line-height, 42px);
}
@media screen and (max-width: 640px){
.rs-h2{
    --rs-h2-font-size:var(--rs-h2-font-size-mobile, 28px);
    --rs-h2-line-height:var(--rs-h2-line-height-mobile, 32px);
}
  }
.rs-h2 {
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-h2_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-h2_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-h3{
  letter-spacing:normal;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-h3-font-size, 20px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:28px;
  line-height:var(--rs-h3-line-height, 28px);
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-h3_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-h3_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-1{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:20px;
  font-size:var(--rs-text-1-font-size, 20px);
  font-weight:normal;
  line-height:28px;
  line-height:var(--rs-text-1-line-height, 28px);
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
.rs-text-1_paragraph-offset-auto > p:not(:first-of-type){
      margin-top:32px;
      margin-top:calc(var(--rs-flow-unit, 8px)*4);
    }
.rs-text-1_theme_dark{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
[class*='_theme_dark'] .rs-text-1_theme_auto{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-1_hardness_hard{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
.rs-text-1_hardness_average{
    color:rgba(25,25,28,0.7);
    color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  }
.rs-text-1_hardness_pale{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
.rs-text-1_theme_dark.rs-text-1_hardness_hard,
  [class*='_theme_dark'] .rs-text-1_theme_auto.rs-text-1_hardness_hard{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-1_theme_dark.rs-text-1_hardness_average,
  [class*='_theme_dark'] .rs-text-1_theme_auto.rs-text-1_hardness_average{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-1_theme_dark.rs-text-1_hardness_pale,
  [class*='_theme_dark'] .rs-text-1_theme_auto.rs-text-1_hardness_pale{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
.rs-h4{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-h4-font-size, 16px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:24px;
  line-height:var(--rs-h4-line-height, 24px);
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-h4_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-h4_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-2{
  letter-spacing:0.0015em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:16px;
  font-size:var(--rs-text-2-font-size, 16px);
  font-weight:400;
  line-height:24px;
  line-height:var(--rs-text-2-line-height, 24px);
}
.rs-reset-letter-spacing .rs-text-2{
    letter-spacing:normal;
  }
.rs-text-2 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
.rs-text-2_paragraph-offset-auto > p:not(:first-of-type){
      margin-top:16px;
      margin-top:calc(var(--rs-flow-unit, 8px)*2);
    }
.rs-text-2_theme_dark{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
[class*='_theme_dark'] .rs-text-2_theme_auto{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-2_hardness_hard{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
.rs-text-2_hardness_average{
    color:rgba(25,25,28,0.7);
    color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  }
.rs-text-2_hardness_pale{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
.rs-text-2_theme_dark.rs-text-2_hardness_hard,
  [class*='_theme_dark'] .rs-text-2_theme_auto.rs-text-2_hardness_hard{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-2_theme_dark.rs-text-2_hardness_average,
  [class*='_theme_dark'] .rs-text-2_theme_auto.rs-text-2_hardness_average{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-2_theme_dark.rs-text-2_hardness_pale,
  [class*='_theme_dark'] .rs-text-2_theme_auto.rs-text-2_hardness_pale{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
.rs-h5{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-h5-font-size, 13px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:20px;
  line-height:var(--rs-h5-line-height, 20px);
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
}
.rs-h5_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-h5_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-overline{
  letter-spacing:0.1em;
  text-transform:uppercase;
  color:#19191C;
  color:var(--rs-typography-color-hard, #19191C);
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-overline-font-size, 13px);
  font-weight:530;
  font-weight:var(--rs-font-weight-semi-bold, 530);
  line-height:18px;
  line-height:var(--rs-overline-line-height, 18px);
}
.rs-overline_theme_dark{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
[class*='_theme_dark'] .rs-overline_theme_auto{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-3{
  letter-spacing:0.0045em;
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  font-size:13px;
  font-size:var(--rs-text-3-font-size, 13px);
  line-height:20px;
  line-height:var(--rs-text-3-line-height, 20px);
}
.rs-reset-letter-spacing .rs-text-3{
    letter-spacing:normal;
  }
.rs-text-3 {
  color:rgba(25,25,28,0.7);
  color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
}
.rs-text-3_paragraph-offset-auto > p:not(:first-of-type){
      margin-top:8px;
      margin-top:var(--rs-flow-unit, 8px);
    }
.rs-text-3_theme_dark{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
[class*='_theme_dark'] .rs-text-3_theme_auto{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-3_hardness_hard{
    color:#19191C;
    color:var(--rs-typography-color-hard, #19191C);
  }
.rs-text-3_hardness_average{
    color:rgba(25,25,28,0.7);
    color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  }
.rs-text-3_hardness_pale{
    color:rgba(25,25,28,0.5);
    color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
.rs-text-3_theme_dark.rs-text-3_hardness_hard,
  [class*='_theme_dark'] .rs-text-3_theme_auto.rs-text-3_hardness_hard{
    color:#FFFFFF;
    color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-text-3_theme_dark.rs-text-3_hardness_average,
  [class*='_theme_dark'] .rs-text-3_theme_auto.rs-text-3_hardness_average{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-text-3_theme_dark.rs-text-3_hardness_pale,
  [class*='_theme_dark'] .rs-text-3_theme_auto.rs-text-3_hardness_pale{
    color:rgba(255,255,255,0.5);
    color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
.rs-ul{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  list-style-type:none;
  padding-left:0;
}
.rs-ul > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ul > li{
      margin-top:12px
  }
    }
.rs-ul > li{
    padding-left:22px;
  }
.rs-ul > li{
    position:relative;

    list-style-type:none;
  }
.rs-ul > li::before{
      position:absolute;

      top:0;

      display:block;

      line-height:inherit;

      color:inherit;

      left:0;
      text-align:left;
    }
.rs-ul_offset-items_8 > li{
    margin-top:8px;
  }
@media screen and (max-width: 640px){
.rs-ul_offset-items_8 > li{
      margin-top:8px
  }
    }
.rs-ul_offset-items_12 > li{
    margin-top:12px;
  }
@media screen and (max-width: 640px){
.rs-ul_offset-items_12 > li{
      margin-top:12px
  }
    }
.rs-ul_offset-items_16 > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ul_offset-items_16 > li{
      margin-top:12px
  }
    }
.rs-ul_offset-items_24 > li{
    margin-top:24px;
  }
@media screen and (max-width: 640px){
.rs-ul_offset-items_24 > li{
      margin-top:16px
  }
    }
.rs-ul:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ul:not([class*="_offset-items_"]) > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ul:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ul:not([class*="_offset-items_"]) > li{
      margin-top:12px
  }
    }
.rs-ul:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ul:not([class*="_offset-items_"]) > li{
    margin-top:24px;
  }
@media screen and (max-width: 640px){
.rs-ul:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ul:not([class*="_offset-items_"]) > li{
      margin-top:16px
  }
    }
.rs-ul:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ul:not([class*="_offset-items_"]) > li{
    margin-top:8px;
  }
@media screen and (max-width: 640px){
.rs-ul:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ul:not([class*="_offset-items_"]) > li{
      margin-top:8px
  }
    }
.rs-ul.rs-text-1 > li, .rs-text-1 .rs-ul > li{
    padding-left:26px;
  }
.rs-ul.rs-text-3 > li, .rs-text-3 .rs-ul > li{
    padding-left:28px;
  }
.rs-ul > li::before{
    content:'\2022';
  }
.rs-ol{
  font-family:'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  font-family:var(--rs-font-family-ui, var(--rs-font-family-jb-sans, 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif));
  -webkit-font-feature-settings:"kern", "liga", "calt";
  font-feature-settings:"kern", "liga", "calt";
  list-style-type:none;
  padding-left:0;
}
.rs-ol > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ol > li{
      margin-top:12px
  }
    }
.rs-ol > li{
    padding-left:22px;
  }
.rs-ol > li{
    position:relative;

    list-style-type:none;
  }
.rs-ol > li::before{
      position:absolute;

      top:0;

      display:block;

      line-height:inherit;

      color:inherit;

      left:0;
      text-align:left;
    }
.rs-ol_offset-items_8 > li{
    margin-top:8px;
  }
@media screen and (max-width: 640px){
.rs-ol_offset-items_8 > li{
      margin-top:8px
  }
    }
.rs-ol_offset-items_12 > li{
    margin-top:12px;
  }
@media screen and (max-width: 640px){
.rs-ol_offset-items_12 > li{
      margin-top:12px
  }
    }
.rs-ol_offset-items_16 > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ol_offset-items_16 > li{
      margin-top:12px
  }
    }
.rs-ol_offset-items_24 > li{
    margin-top:24px;
  }
@media screen and (max-width: 640px){
.rs-ol_offset-items_24 > li{
      margin-top:16px
  }
    }
.rs-ol:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ol:not([class*="_offset-items_"]) > li{
    margin-top:16px;
  }
@media screen and (max-width: 640px){
.rs-ol:not([class*="_offset-items_"]).rs-text-2 > li, .rs-text-2 .rs-ol:not([class*="_offset-items_"]) > li{
      margin-top:12px
  }
    }
.rs-ol:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ol:not([class*="_offset-items_"]) > li{
    margin-top:24px;
  }
@media screen and (max-width: 640px){
.rs-ol:not([class*="_offset-items_"]).rs-text-1 > li, .rs-text-1 .rs-ol:not([class*="_offset-items_"]) > li{
      margin-top:16px
  }
    }
.rs-ol:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ol:not([class*="_offset-items_"]) > li{
    margin-top:8px;
  }
@media screen and (max-width: 640px){
.rs-ol:not([class*="_offset-items_"]).rs-text-3 > li, .rs-text-3 .rs-ol:not([class*="_offset-items_"]) > li{
      margin-top:8px
  }
    }
.rs-ol {
  counter-reset:ol-counter;
}
.rs-ol.rs-text-2 > li, .rs-text-2 .rs-ol > li{
    padding-left:26px;
  }
.rs-ol.rs-text-1 > li, .rs-text-1 .rs-ol > li{
    padding-left:32px;
  }
.rs-ol.rs-text-3 > li, .rs-text-3 .rs-ol > li{
    padding-left:21px;
  }
.rs-ol > li::before{
    content:counter(ol-counter) '.';
    counter-increment:ol-counter;
  }
.rs-ol_alpha_lower > li::before{
    content:counter(ol-counter, lower-alpha) '.';
  }
.rs-ol_alpha_upper > li::before{
    content:counter(ol-counter, upper-alpha) '.';
  }
.rs-pre{
  overflow:auto;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  max-width:100%;
  width:100%;
  padding:12px 16px;
  word-wrap:normal;
  font-family:'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-family:var(--rs-font-family-mono, 'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace);
  font-size:16px;
  line-height:24px;
  color:#19191C;
  color:var(--rs-typography-pre-color, #19191C);
  background-color:rgba(25,25,28,0.1);
  background-color:var(--rs-typography-pre-bg-color, rgba(25,25,28,0.1));
  border-radius:8px;
  display:block;
}
.rs-pre > code,
  .rs-pre > .rs-code{
    border:0;
    white-space:pre;
    word-break:normal;
  }
.rs-pre code,
  .rs-pre .rs-code{
    display:inline;
    overflow:visible;
    max-width:none;
    margin:0;
    padding:0 !important;
    word-wrap:normal;
    background:transparent !important;
    color:inherit !important;
    font-family:'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-family:var(--rs-font-family-mono, 'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace);
    line-height:inherit;
  }
.rs-pre_theme_dark{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-pre-color, rgba(255,255,255,0.7));
    background-color:rgba(255,255,255,0.1);
    background-color:var(--rs-typography-pre-bg-color, rgba(255,255,255,0.1));
  }
[class*='_theme_dark'] .rs-pre_theme_auto{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-pre-color, rgba(255,255,255,0.7));
    background-color:rgba(255,255,255,0.1);
    background-color:var(--rs-typography-pre-bg-color, rgba(255,255,255,0.1));
  }
.rs-code{
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:0.11111em 0.44444em;
  color:#19191C;
  color:var(--rs-typography-pre-color, #19191C);
  background-color:rgba(25,25,28,0.1);
  background-color:var(--rs-typography-pre-bg-color, rgba(25,25,28,0.1));
  font-family:'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-family:var(--rs-font-family-mono, 'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace);
  font-size:90%;
  line-height:inherit;
  border-radius:4px;
}
.rs-code_theme_dark{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-pre-color, rgba(255,255,255,0.7));
    background-color:rgba(255,255,255,0.1);
    background-color:var(--rs-typography-pre-bg-color, rgba(255,255,255,0.1));
  }
[class*='_theme_dark'] .rs-code_theme_auto{
    color:rgba(255,255,255,0.7);
    color:var(--rs-typography-pre-color, rgba(255,255,255,0.7));
    background-color:rgba(255,255,255,0.1);
    background-color:var(--rs-typography-pre-bg-color, rgba(255,255,255,0.1));
  }
[class*='rs-text'] code:not([class*='rs-code']){
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:0.11111em 0.44444em;
  color:#19191C;
  color:var(--rs-typography-pre-color, #19191C);
  background-color:rgba(25,25,28,0.1);
  background-color:var(--rs-typography-pre-bg-color, rgba(25,25,28,0.1));
  font-family:'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-family:var(--rs-font-family-mono, 'JetBrains Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace);
  font-size:90%;
  line-height:inherit;
  border-radius:4px;
}
[class*='_theme_dark'] code:not([class*='rs-code']){
  color:rgba(255,255,255,0.7);
  color:var(--rs-typography-pre-color, rgba(255,255,255,0.7));
  background-color:rgba(255,255,255,0.1);
  background-color:var(--rs-typography-pre-bg-color, rgba(255,255,255,0.1));
}
.rs-link{
  --rs-link-color:var(--rs-typography-color-hard, #19191C);
  color:#19191C;
  color:var(--rs-link-color);
  padding-bottom:1px;
  outline:none;
  cursor:pointer;
  text-decoration:none;
  font-size:inherit;
  line-height:inherit;
  border-bottom-style:solid;
  border-bottom-width:1px;
}
.rs-link:active,
  .rs-link:focus{
    color:#19191C;
    color:var(--rs-link-color);
  }
.rs-link:hover{
    text-decoration:none;
  }
.rs-link:focus[data-focus-method='key']{
    -webkit-box-shadow:#8979ff 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-dim-light-theme, #8979ff) 0 0 0 4px;
            box-shadow:#8979ff 0 0 0 4px;
            box-shadow:var(--rs-color-primary-dim-light-theme, #8979ff) 0 0 0 4px;
  }
[class*="rs-super-hero"] .rs-link{
    border-bottom-width:4px;
  }
@media screen and (max-width: 1000px){
[class*="rs-super-hero"] .rs-link{
      border-bottom-width:3px
  }
    }
@media screen and (max-width: 640px){
[class*="rs-super-hero"] .rs-link{
      border-bottom-width:2px
  }
    }
[class*="rs-hero"] .rs-link{
    border-bottom-width:3px;
  }
@media screen and (max-width: 640px){
[class*="rs-hero"] .rs-link{
      border-bottom-width:2px
  }
    }
[class*="rs-h1"] .rs-link,
  [class*="rs-h2"] .rs-link,
  [class*="rs-h3"] .rs-link,
  [class*="rs-h4"] .rs-link,
  [class*="rs-h5"] .rs-link,
  [class*="rs-overline"] .rs-link,
  [class*="rs-subtitle-1"] .rs-link,
  [class*="rs-subtitle-2"] .rs-link{
    border-bottom-width:2px;
  }
.rs-link_external:after{
    content:'\2197';
    display:inline;
    margin-right:0;
    margin-left:0;
  }
.rs-link_mode_classic{
    --rs-link-color-hover:var(--rs-typography-color-hard, #19191C);
    --rs-underline-color:var(--rs-typography-classic-link-underline-color, rgba(25,25,28,0.4));
    --rs-underline-color-hover:var(--rs-typography-color-hard, #19191C);
    border-bottom-color:rgba(25,25,28,0.4);
    border-bottom-color:var(--rs-underline-color);
  }
.rs-link_mode_classic:hover{
    color:#19191C;
    color:var(--rs-link-color-hover);
    border-bottom-color:#19191C;
    border-bottom-color:var(--rs-underline-color-hover);
    border-bottom-width:2px;
    padding-bottom:0;
  }
[class*="rs-super-hero"] .rs-link_mode_classic:hover{
      border-bottom-width:5px;
    }
@media screen and (max-width: 1000px){
[class*="rs-super-hero"] .rs-link_mode_classic:hover{
        border-bottom-width:4px
    }
      }
@media screen and (max-width: 640px){
[class*="rs-super-hero"] .rs-link_mode_classic:hover{
        border-bottom-width:3px
    }
      }
[class*="rs-hero"] .rs-link_mode_classic:hover{
      border-bottom-width:4px;
    }
@media screen and (max-width: 640px){
[class*="rs-hero"] .rs-link_mode_classic:hover{
        border-bottom-width:3px
    }
      }
[class*="rs-h1"] .rs-link_mode_classic:hover, [class*="rs-h2"] .rs-link_mode_classic:hover, [class*="rs-h3"] .rs-link_mode_classic:hover, [class*="rs-h4"] .rs-link_mode_classic:hover, [class*="rs-h5"] .rs-link_mode_classic:hover, [class*="rs-overline"] .rs-link_mode_classic:hover, [class*="rs-subtitle-1"] .rs-link_mode_classic:hover, [class*="rs-subtitle-2"] .rs-link_mode_classic:hover{
      border-bottom-width:3px;
    }
.rs-link_mode_rock{
    --rs-link-color-hover:var(--rs-typography-rock-link-hover-color, #FFFFFF);
    --rs-underline-color:var(--rs-typography-color-pale, #19191C);
    border-bottom-color:#19191C;
    border-bottom-color:var(--rs-underline-color);
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(100%, #19191C), color-stop(0, transparent));
    background-image:linear-gradient(to bottom, #19191C 100%, transparent 0);
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(100%, var(--rs-underline-color)), color-stop(0, transparent));
    background-image:linear-gradient(to bottom, var(--rs-underline-color) 100%, transparent 0);
    background-size:auto 0;
    background-repeat:repeat-x;
    background-position:left calc(100% + 4px);
    -webkit-transition:background-size 300ms, color 300ms;
    transition:background-size 300ms, color 300ms;
  }
@media all and (-ms-high-contrast: none){
.rs-link_mode_rock{
    -webkit-transition:none;
    transition:none;
  }
  }
.rs-link_mode_rock:hover{
    color:#FFFFFF;
    color:var(--rs-link-color-hover);
    background-size:auto calc(100% + 4px);
  }
.rs-link_mode_standalone{
    --rs-link-standalone-border-offset:1.15em;
    --rs-link-color-hover:var(--rs-typography-color-hard, #19191C);
    --rs-underline-color:transparent;
    --rs-underline-color-hover:var(--rs-typography-color-hard, #19191C);
    border-bottom-color:transparent;
    border-bottom-color:var(--rs-underline-color);
  }
.rs-link_mode_standalone:after{
    content:'\2192';
    display:inline;
    margin-right:0.15em;
    margin-left:0.15em;
    -webkit-transition:margin-right 300ms, margin-left 300ms;
    transition:margin-right 300ms, margin-left 300ms;
  }
.rs-link_mode_standalone:hover{
    color:#19191C;
    color:var(--rs-link-color-hover);
    border-bottom-color:#19191C;
    border-bottom-color:var(--rs-underline-color-hover);
    -o-border-image:linear-gradient(to right, #19191C calc(100% - 1.1em), transparent 1.1em) 1;
       border-image:-webkit-gradient(linear, left top, right top, from(#19191C), color-stop(1.1em, transparent)) 1;
       border-image:linear-gradient(to right, #19191C calc(100% - 1.1em), transparent 1.1em) 1;
    -o-border-image:linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
       border-image:-webkit-gradient(linear, left top, right top, from(var(--rs-underline-color-hover)), to(transparent)) 1;
       border-image:linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
  }
.rs-link_mode_standalone:hover:after{
    margin-right:0;
    margin-left:0.3em;
  }
[class*="rs-super-hero"] .rs-link_mode_standalone,
    [class*="rs-hero"] .rs-link_mode_standalone,
    [class*="rs-h1"] .rs-link_mode_standalone,
    [class*="rs-h2"] .rs-link_mode_standalone{
      --rs-link-standalone-border-offset:1.12em;
    }
[class*="rs-overline"] .rs-link_mode_standalone{
      --rs-link-standalone-border-offset:1.3em;
    }
.rs-link_mode_standalone.rs-link_external{
    --rs-link-standalone-border-offset:1.02em;
  }
.rs-link_mode_standalone.rs-link_external:after{
    content:'\2197';
  }
[class*="rs-super-hero"] .rs-link_mode_standalone.rs-link_external,
    [class*="rs-hero"] .rs-link_mode_standalone.rs-link_external,
    [class*="rs-h1"] .rs-link_mode_standalone.rs-link_external,
    [class*="rs-h2"] .rs-link_mode_standalone.rs-link_external{
      --rs-link-standalone-border-offset:1em;
    }
[class*="rs-overline"] .rs-link_mode_standalone.rs-link_external{
      --rs-link-standalone-border-offset:1.15em;
    }
.rs-link_mode_clear{
    --rs-link-color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
    --rs-link-color-hover:var(--rs-typography-color-hard, #19191C);
    --rs-underline-color:transparent;
    --rs-underline-color-hover:var(--rs-typography-color-hard, #19191C);
    border-bottom-color:transparent;
    border-bottom-color:var(--rs-underline-color);
    color:rgba(25,25,28,0.7);
    color:var(--rs-link-color);
  }
.rs-link_mode_clear:hover{
    border-bottom-color:#19191C;
    border-bottom-color:var(--rs-underline-color-hover);

    color:#19191C;
    color:var(--rs-link-color-hover);
  }
.rs-link_hardness_hard{
    --rs-link-color:var(--rs-typography-color-hard, #19191C);
  }
.rs-link_hardness_average{
    --rs-link-color:var(--rs-typography-color-average, rgba(25,25,28,0.7));
  }
.rs-link_hardness_pale{
    --rs-link-color:var(--rs-typography-color-pale, rgba(25,25,28,0.5));
  }
.rs-link_theme_dark{
    --rs-link-color:var(--rs-typography-color-hard, #FFFFFF);
    color:#FFFFFF;
    color:var(--rs-link-color);
  }
.rs-link_theme_dark:active,
  .rs-link_theme_dark:focus{
    color:#FFFFFF;
    color:var(--rs-link-color);
  }
.rs-link_theme_dark:focus[data-focus-method='key']{
    -webkit-box-shadow:#6f61d2 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
            box-shadow:#6f61d2 0 0 0 4px;
            box-shadow:var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
  }
[class*='_theme_dark'] .rs-link_theme_auto{
    --rs-link-color:var(--rs-typography-color-hard, #FFFFFF);
    color:#FFFFFF;
    color:var(--rs-link-color);
  }
[class*='_theme_dark'] .rs-link_theme_auto:active,
  [class*='_theme_dark'] .rs-link_theme_auto:focus{
    color:#FFFFFF;
    color:var(--rs-link-color);
  }
[class*='_theme_dark'] .rs-link_theme_auto:focus[data-focus-method='key']{
    -webkit-box-shadow:#6f61d2 0 0 0 4px;
    -webkit-box-shadow:var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
            box-shadow:#6f61d2 0 0 0 4px;
            box-shadow:var(--rs-color-primary-dim-dark-theme, #6f61d2) 0 0 0 4px;
  }
.rs-link_theme_dark.rs-link_mode_classic,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_classic{
    --rs-link-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    --rs-underline-color:var(--rs-typography-classic-link-underline-color, rgba(255,255,255,0.4));
    --rs-underline-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    border-bottom-color:rgba(255,255,255,0.4);
    border-bottom-color:var(--rs-underline-color);
  }
.rs-link_theme_dark.rs-link_mode_classic:hover, [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_classic:hover{
    color:#FFFFFF;
    color:var(--rs-link-color-hover);
    border-bottom-color:#FFFFFF;
    border-bottom-color:var(--rs-underline-color-hover);
  }
.rs-link_theme_dark.rs-link_mode_rock,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_rock{
    --rs-link-color-hover:var(--rs-typography-rock-link-hover-color, #19191C);
    --rs-underline-color:var(--rs-typography-color-hard, #FFFFFF);
    border-bottom-color:#FFFFFF;
    border-bottom-color:var(--rs-underline-color);
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(100%, #FFFFFF), color-stop(0, transparent));
    background-image:linear-gradient(to bottom, #FFFFFF 100%, transparent 0);
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(100%, var(--rs-underline-color)), color-stop(0, transparent));
    background-image:linear-gradient(to bottom, var(--rs-underline-color) 100%, transparent 0);
  }
.rs-link_theme_dark.rs-link_mode_rock:hover, [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_rock:hover{
    color:#19191C;
    color:var(--rs-link-color-hover);
  }
.rs-link_theme_dark.rs-link_mode_standalone,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_standalone{
    --rs-link-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    --rs-underline-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    background-image:none;
    border-bottom-color:transparent;
  }
.rs-link_theme_dark.rs-link_mode_standalone:hover, [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_standalone:hover{
    color:#FFFFFF;
    color:var(--rs-link-color-hover);
    border-bottom-color:#FFFFFF;
    border-bottom-color:var(--rs-underline-color-hover);
    -o-border-image:linear-gradient(to right, #FFFFFF calc(100% - 1.3em), transparent 1.3em) 1;
       border-image:-webkit-gradient(linear, left top, right top, from(#FFFFFF), color-stop(1.3em, transparent)) 1;
       border-image:linear-gradient(to right, #FFFFFF calc(100% - 1.3em), transparent 1.3em) 1;
    -o-border-image:linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
       border-image:-webkit-gradient(linear, left top, right top, from(var(--rs-underline-color-hover)), to(transparent)) 1;
       border-image:linear-gradient(to right, var(--rs-underline-color-hover) calc(100% - var(--rs-link-standalone-border-offset)), transparent var(--rs-link-standalone-border-offset)) 1;
  }
.rs-link_theme_dark.rs-link_mode_clear,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_clear{
    --rs-link-color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
    --rs-link-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    --rs-underline-color-hover:var(--rs-typography-color-hard, #FFFFFF);
    color:rgba(255,255,255,0.7);
    color:var(--rs-link-color);
  }
.rs-link_theme_dark.rs-link_mode_clear:hover, [class*='_theme_dark'] .rs-link_theme_auto.rs-link_mode_clear:hover{
    color:#FFFFFF;
    color:var(--rs-link-color-hover);
    border-bottom-color:#FFFFFF;
    border-bottom-color:var(--rs-underline-color-hover);
  }
.rs-link_theme_dark.rs-link_hardness_hard,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_hardness_hard{
    --rs-link-color:var(--rs-typography-color-hard, #FFFFFF);
  }
.rs-link_theme_dark.rs-link_hardness_average,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_hardness_average{
    --rs-link-color:var(--rs-typography-color-average, rgba(255,255,255,0.7));
  }
.rs-link_theme_dark.rs-link_hardness_pale,
  [class*='_theme_dark'] .rs-link_theme_auto.rs-link_hardness_pale{
    --rs-link-color:var(--rs-typography-color-pale, rgba(255,255,255,0.5));
  }
